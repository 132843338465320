/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import common from 'gateway-common';
import { initializeSentry } from './services/ErrorHandling';
import rootReducer from './reducers';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Login from './views/Login';
import PipelineHome from './views/PipelineHome';
import Referrals from './views/Referrals';
import UserProfile from './views/UserProfile/UserProfile';
import ReferralDetail from './views/ReferralDetail';
import ParentPage from './components/ParentPage';
import PasswordReset from './views/PasswordReset';
import PasswordResetPrompt from './views/PasswordResetPrompt';
import Admin from './views/Admin';
import {
  confirmPassword,
  updatePassword,
  login as loginWithEmail,
  isLoggedIn,
  googleSignIn,
} from './services/endpoints';
import {
  LOGIN_REDIRECT_URL,
  AUTHENTICATION_STRATEGIES,
  APP_NAME,
} from './constants';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

initializeSentry();

const App = () => {
  toast.configure();
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    {},
    composeEnhancers(
      applyMiddleware(thunk),
    ),
  );
  const {
    components: {
      IncompatibleBrowserPage,
      LoginPage,
      TempPasswordExpired,
    },
    constants: {
      loginHeaderText: { HEADER_TEXT_GP },
      appTypes: { APP_GP },
    },
  } = common;
  const isIE = window.document.documentMode;
  return (
    <div className="App gateway-platform" id="gateway-platform">
      {isIE
        ? (
          <IncompatibleBrowserPage />
        )
        : (
          <Router>
            <Provider store={store}>
              <Switch>
                <Route exact path={['/', '/password-reset', '/forgotPassword', '/password-expired', '/initial-password-reset']}>
                  <LoginPage appType={APP_GP}>
                    <Route
                      exact
                      path="/"
                      render={(props) => (
                        <Login
                          {...props}
                          headerText={HEADER_TEXT_GP}
                          passwordChangeRequired
                          updatePassword={updatePassword}
                          confirmPassword={confirmPassword}
                          loginHandler={loginWithEmail}
                          isLoggedIn={isLoggedIn}
                          onLoginRedirectTo={LOGIN_REDIRECT_URL}
                          authenticationStrategies={AUTHENTICATION_STRATEGIES}
                          googleSignInEndpoint={googleSignIn}
                          appName={APP_NAME}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/password-reset"
                      component={PasswordResetPrompt}
                    />
                    <Route exact path="/forgotPassword" component={PasswordReset} />
                    <Route exact path="/password-expired" component={TempPasswordExpired} />
                  </LoginPage>
                </Route>
                <Route
                  exact
                  path={[
                    '/home',
                    '/referrals',
                    '/profile',
                    '/patient/:patientID/referral/:referralID',
                    '/admin',
                    '/admin/pipeline',
                    '/admin/user-management',
                  ]}
                >
                  <ParentPage>
                    <Switch>
                      <PrivateRoute key="home" exact path="/home" component={PipelineHome} />
                      <PrivateRoute key="referrals" exact path="/referrals" component={Referrals} />
                      <PrivateRoute key="profile" exact path="/profile" component={UserProfile} />
                      <PrivateRoute
                        key="referral-detail"
                        exact
                        path="/patient/:patientID/referral/:referralID"
                        component={(props) => (
                          <ReferralDetail
                            key={`patient-${props.match.params.patientID}-referral-${props.match.params.referralID}`}
                          />
                        )}
                      />
                      <PrivateRoute
                        path="/admin"
                        key="admin"
                        component={Admin}
                      />
                      <PrivateRoute key="default-route" component={PipelineHome} />
                    </Switch>
                  </ParentPage>
                </Route>
              </Switch>
            </Provider>
          </Router>
        )}
    </div>
  );
};

export default App;
