/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { sendErrorWithScope } from '../../services/ErrorHandling';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventID: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    sendErrorWithScope(error, errorInfo, (eventID) => this.setState({ eventID }));
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <h1>
          Something went wrong. Our team has been notified, but feel free to message us on Slack.
        </h1>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorBoundary;
