import common from 'gateway-common';
import { sendAPIError } from './ErrorHandling';
import { APP_NAME } from '../constants';

const endpoint = process.env.REACT_APP_ENDPOINT || 'http://localhost:5000';

const { services: { getEndpoints } } = common;
const endpoints = getEndpoints(endpoint, APP_NAME, sendAPIError);
const {
  login,
  logout,
  getAllPipelineCases,
  editPatient,
  editReferrer,
  findPatient,
  getPatient,
  getPerson,
  createPatient,
  createPatientAndPerson,
  createCustomAttributes,
  updateCustomAttributes,
  deleteCustomAttributes,
  getAllPersons,
  findReferrer,
  createReferrer,
  deleteNote,
  getAllPipelines,
  getPipelineOverview,
  uploadAttachments,
  deleteAttachments,
  getAllReferrers,
  getAccount,
  createAccount,
  updateAccount,
  updatePassword,
  confirmPassword,
  createRelationship,
  updateRelationship,
  deleteRelationship,
  createPersonNote,
  updatePersonNote,
  deletePersonNote,
  uploadPersonAttachment,
  deletePersonAttachment,
  updatePerson,
  undo,
  editPerson,
  getCaseTypes,
  findPerson,
  getAdvisors,
  assignAdvisorsToReferral,
  unassignAdvisorsFromReferral,
  archiveReferral,
  createAbeAccount,
  getMediResults,
  isLoggedIn,
  getAccountID,
  findAdvisor,
  getSomeAdvisors,
  removeSelfFromCase,
  getAllStatuses,
  updateNeedStatus,
  getAppData,
  getAllReferrals,
  updatePriorityNeedID,
  updatePatientHeadOfCase,
  createPatientHeadOfCase,
  getReferral,
  getNeedTimeline,
  updateReferralSubStatusAndMessage,
  getNotes,
  addNote,
  editNote,
  updatePasswordFromForgotPasswordFlow,
  sendForgotPasswordLinkToEmail,
  googleSignIn,
  grantAccessToReferral,
  removeAccessToReferral,
  createNeeds,
  updateNeed,
  createPipeline,
  updatePipeline,
  createSite,
  updateSite,
  getAllAccounts,
  deleteSubStatusMessage,
  searchAdmin,
} = endpoints;

export {
  login,
  logout,
  getAllPipelineCases,
  editPatient,
  getPatient,
  editReferrer,
  findPatient,
  getPerson,
  createPatient,
  createPatientAndPerson,
  createCustomAttributes,
  updateCustomAttributes,
  deleteCustomAttributes,
  getAllPersons,
  findReferrer,
  createReferrer,
  deleteNote,
  getAllPipelines,
  getPipelineOverview,
  uploadAttachments,
  deleteAttachments,
  getAllReferrers,
  getAccount,
  createAccount,
  updateAccount,
  updatePassword,
  confirmPassword,
  createRelationship,
  updateRelationship,
  deleteRelationship,
  createPersonNote,
  updatePersonNote,
  deletePersonNote,
  uploadPersonAttachment,
  deletePersonAttachment,
  updatePerson,
  undo,
  editPerson,
  getCaseTypes,
  findPerson,
  getAdvisors,
  assignAdvisorsToReferral,
  unassignAdvisorsFromReferral,
  archiveReferral,
  createAbeAccount,
  getMediResults,
  isLoggedIn,
  getAccountID,
  findAdvisor,
  getSomeAdvisors,
  removeSelfFromCase,
  getAllStatuses,
  updateNeedStatus,
  getAppData,
  getAllReferrals,
  updatePriorityNeedID,
  updatePatientHeadOfCase,
  createPatientHeadOfCase,
  getReferral,
  getNeedTimeline,
  updateReferralSubStatusAndMessage,
  getNotes,
  addNote,
  editNote,
  updatePasswordFromForgotPasswordFlow,
  sendForgotPasswordLinkToEmail,
  googleSignIn,
  grantAccessToReferral,
  removeAccessToReferral,
  createNeeds,
  updateNeed,
  createPipeline,
  updatePipeline,
  createSite,
  updateSite,
  getAllAccounts,
  deleteSubStatusMessage,
  searchAdmin,
};
