import React from 'react';
import common from 'gateway-common';
import { sendForgotPasswordLinkToEmail } from '../../services/endpoints';

const { components: { PasswordResetPrompt } } = common;

const PasswordResetPromptContainer = () => (
  <PasswordResetPrompt sendForgotPasswordLinkToEmail={sendForgotPasswordLinkToEmail} />
);

export default PasswordResetPromptContainer;
