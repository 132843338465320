// constants
export const SET_PERSON_INFO = 'SET_PERSON_INFO';
export const EDIT_PERSON_INFO = 'EDIT_PERSON_INFO';
export const UNDO_PERSON_CHANGE = 'UNDO_PERSON_CHANGE';
export const UPDATE_PERSON_INFO = 'UPDATE_PERSON_INFO';

// actions
export const setPersonInfo = (personInfo) => ({
  type: SET_PERSON_INFO,
  personInfo,
});

export const editPersonInfo = (key, val) => ({
  type: EDIT_PERSON_INFO,
  key,
  val,
});

export const updatePersonInfo = (updateAttributes) => ({
  type: UPDATE_PERSON_INFO,
  updateAttributes,
});

// action handlers
const ACTION_HANDLERS = {
  [SET_PERSON_INFO]: (state, action) => ({
    ...state,
    personInfo: action.personInfo,
  }),
  [EDIT_PERSON_INFO]: (state, action) => ({
    ...state,
    personInfo: {
      ...state.personInfo,
      [action.key]: action.val,
    },
  }),
  [UNDO_PERSON_CHANGE]: (state, action) => ({
    ...state,
    personInfo: {
      ...state.personInfo,
      ...action.newValues,
    },
  }),
  [UPDATE_PERSON_INFO]: (state, action) => ({
    ...state,
    personInfo: {
      ...state.personInfo,
      ...action.updateAttributes,
    },
  }),
};

const initialState = {
  personInfo: {},
};

export default function personReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
