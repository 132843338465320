import { connect } from 'react-redux';
import Tabs from './Tabs';
import { openAddNeedLightBox } from '../../reducers/lightbox';

const mapDispatchToProps = (dispatch) => ({
  openAddNeedLightBox: () => dispatch(openAddNeedLightBox()),
});

const TabsContainer = connect(
  null,
  mapDispatchToProps,
)(Tabs);

export default TabsContainer;
