/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import {
  getAccount, updateAccount, updatePassword, confirmPassword,
} from '../../services/endpoints';
import ProfileIcon from '../../assets/user_profile_icon.png';
import CheckMark from '../../assets/green_check_mark.png';
import RedEx from '../../assets/red_x.png';
import { notifyError, notifySuccess } from '../../services/Notifications';
import ErrorBoundary from '../../components/ErrorBoundary';
import './UserProfile.scss';
import 'react-phone-input-2/lib/style.css';

export class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      phone: '',
      newPassword: '',
      doesMeetRequirements: false,
      passwordConfirm: '',
      passwordIsConfirmed: false,
      oldPassword: '',
      oldPasswordIsValid: false,
      passwordReset: false,
      errorMsg: '',
    };
  }

  componentDidMount = async () => {
    const account = await getAccount();
    if (account === null) {
      notifyError('Connection Error: Could not retrieve your profile.');
      return;
    } const {
      // eslint-disable-next-line camelcase
      first_name, middle_name, last_name, email, phone,
    } = account;
    this.setState({
      firstName: first_name,
      lastName: last_name,
      middleName: middle_name,
      email,
      phone,
    });
  }

  changeHandler = async (event) => {
    event.preventDefault();
    // eslint-disable-next-line prefer-destructuring
    const id = event.target.id;
    this.setState({ [event.target.id]: event.target.value }, () => {
      if (id === 'passwordConfirm') this.matchPasswords();
      if (id === 'newPassword') {
        this.meetsRequirements();
        this.matchPasswords();
      }
    });
  }

  initiatePasswordReset = (event) => {
    event.preventDefault();
    this.setState({ passwordReset: true });
  }

  cancelPasswordReset = (event) => {
    event.preventDefault();
    this.setState({
      passwordReset: false,
      oldPassword: '',
      newPassword: '',
      passwordConfirm: '',
      oldPasswordIsValid: false,
      passwordIsConfirmed: false,
      doesMeetRequirements: false,
    });
  }

  updatePassword = async (event) => {
    event.preventDefault();
    const {
      newPassword, passwordConfirm, oldPasswordIsValid, oldPassword, doesMeetRequirements,
      passwordIsConfirmed,
    } = this.state;
    if (!(oldPassword && newPassword && passwordConfirm)) {
      this.setState({ errorMsg: 'All fields are required' });
      return;
    }
    if (!oldPasswordIsValid) {
      this.setState({ errorMsg: 'Your old password is not correct' });
      return;
    }
    if (!doesMeetRequirements) {
      this.setState({ errorMsg: 'Your new password does not meet the requirements of 8 or more characters.' });
      return;
    }
    if (!passwordIsConfirmed) {
      this.setState({ errorMsg: 'These passwords do not match' });
      return;
    }
    const success = await updatePassword(newPassword);
    if (!success) {
      notifyError('Network Error: Your password could not be updated.');
    } notifySuccess('Success! You\'ve updated your password!');
    this.setState({
      oldPassword: '',
      newPassword: '',
      passwordConfirm: '',
      passwordReset: false,
      oldPasswordIsValid: false,
      passwordIsConfirmed: false,
      doesMeetRequirements: false,
      errorMsg: '',
    });
  }

  updateProfile = async (event) => {
    event.preventDefault();
    const {
      firstName, middleName, lastName, email, phone,
    } = this.state;
    if (!(firstName && lastName && email && phone)) {
      this.setState({ errorMsg: 'Some required fields were left blank' });
      return;
    }
    const success = await updateAccount(firstName, lastName, middleName, email, phone);
    if (!success) {
      notifyError('Network Error: You\'re profile could not be updated.');
    } notifySuccess('Success! You\'ve updated your profile.');
    this.setState({ errorMsg: '' });
  }

  confirmPassword = async (event) => {
    event.preventDefault();
    const { oldPassword } = this.state;
    const isValid = await confirmPassword(oldPassword);
    this.setState({ oldPasswordIsValid: isValid });
  }

  matchPasswords = () => {
    const { passwordConfirm, newPassword } = this.state;
    this.setState({ passwordIsConfirmed: passwordConfirm === newPassword });
  }

  meetsRequirements = () => {
    const { newPassword } = this.state;
    const requirements = /\w{8}/;
    const pattern = new RegExp(requirements);
    this.setState({ doesMeetRequirements: pattern.test(newPassword) });
  }

  render() {
    const {
      firstName, lastName, middleName, email, phone, passwordReset, doesMeetRequirements,
      oldPassword, newPassword, passwordConfirm, oldPasswordIsValid, passwordIsConfirmed, errorMsg,
    } = this.state;
    const passwordForm = (
      <ul>
        <li>
          <label htmlFor="oldPassword">
            <p>Old Password</p>
            <input
              type="password"
              id="oldPassword"
              value={oldPassword}
              placeholder="Old Password"
              onBlur={this.confirmPassword}
              onChange={(event) => this.changeHandler(event)}
            />
            <div className="profile-valid-input-symbol">
              <img src={oldPasswordIsValid ? CheckMark : RedEx} alt="Red Ex" />
            </div>
          </label>
        </li>
        <li>
          <label htmlFor="newPassword">
            <p>New Password</p>
            <input type="password" id="newPassword" value={newPassword} placeholder="New Password" onChange={(event) => this.changeHandler(event)} />
            <div className="profile-valid-input-symbol">
              <img src={doesMeetRequirements ? CheckMark : RedEx} alt="Red Ex" />
            </div>
          </label>
        </li>
        <li>
          <label htmlFor="passwordConfirm">
            <p>Confirm Password</p>
            <input type="password" id="passwordConfirm" value={passwordConfirm} placeholder="Confirm Password" onChange={(event) => this.changeHandler(event)} />
            <div className="profile-valid-input-symbol">
              <img src={passwordIsConfirmed && doesMeetRequirements ? CheckMark : RedEx} alt="Red Ex" />
            </div>
          </label>
        </li>
      </ul>
    );
    const profileForm = (
      <ul>
        <li>
          <label htmlFor="firstName">
            <p>First Name</p>
            <input type="text" id="firstName" value={firstName} onChange={(event) => this.changeHandler(event)} />
          </label>
        </li>
        <li>
          <label htmlFor="middleName">
            <p>Middle Name</p>
            <input type="text" id="middleName" value={middleName} onChange={(event) => this.changeHandler(event)} />
          </label>
        </li>
        <li>
          <label htmlFor="lastName">
            <p>Last Name</p>
            <input type="text" id="lastName" value={lastName} onChange={(event) => this.changeHandler(event)} />
          </label>
        </li>
        <li>
          <label htmlFor="email">
            <p>Email</p>
            <input type="email" id="email" value={email} onChange={(event) => this.changeHandler(event)} />
          </label>
        </li>
        <li>
          <label htmlFor="phone">
            <p>Phone</p>
            <PhoneInput
              country="us"
              disableCountryCode
              onlyCountries={['us']}
              disableDropdown
              autoformat={false}
              value={phone}
              id="phone"
              containerClass=""
              inputClass=""
              placeholder="Phone"
              onChange={(p) => this.setState({ phone: p })}
            />
          </label>
        </li>
        <li>
          <label htmlFor="password">
            <p>Password</p>
            <div className="profile-change-password">
              <input id="password" type="button" value="Change Password" onClick={this.initiatePasswordReset} onChange={(event) => this.changeHandler(event)} />
            </div>
          </label>
        </li>
      </ul>
    );
    const cancelButton = (
      <button type="button" onClick={this.cancelPasswordReset}>
        Cancel
      </button>
    );
    return (
      <div className="user-profile-main-container">
        <div className="profile-container">
          <div className="profile-title-bar">
            <img src={ProfileIcon} alt="Profile Icon" />
            <p>My Profile</p>
          </div>
          <div className="profile-content">
            <form>
              {passwordReset ? passwordForm : profileForm}
              <div className="profile-btn-container">
                <button type="submit" onClick={passwordReset ? this.updatePassword : this.updateProfile}>
                  Update
                </button>
                {passwordReset ? cancelButton : null }
              </div>
            </form>
            <div className="profile-error-msg-container">
              {errorMsg}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const wrapWithErrorBoundary = (props) => (
  <ErrorBoundary>
    <UserProfile {...props} />
  </ErrorBoundary>
);

export default wrapWithErrorBoundary;
