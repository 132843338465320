/*
  eslint-disable
    jsx-a11y/click-events-have-key-events
    , react-hooks/exhaustive-deps
*/
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import common, { async } from "gateway-common";
import _ from "lodash";
import ReferralRow from "../../components/ReferralRow";
import LoadingSpinner from "../../components/LoadingSpinner";
import { getIconFromPipelineType } from "../../utils";
import {
  sortBySelectOptions,
  REFERRALS_DEFAULT_OFFSET,
  REFERRALS_DEFAULT_CLOSED_PER_PAGE_LIMIT,
} from "../../constants";
import AppData from "../../services/AppData";
import filter from "../../assets/filter-icon.png";
import filterclose from "../../assets/filter-close.png";
import "./Referrals.scss";

const {
  components: {
    Select,
    ButtonCircleBig,
    Input,
    Button,
    DropdownDrilldown,
    MultiSelect,
  },
  svgs: {
    icons: { getChevronDown, getChevronRight },
  },
  constants: { WHITE },
} = common;

const Referrals = ({
  resetAllReferrals,
  pipelineOptions,
  careAdvisorOptions,
  urgencyOptions,
  needStatusOptions,
  referralStatusOptions,
  referrerOptions,
  pipelineFilter,
  careAdvisorFilter,
  statusFilter,
  sortBy,
  dateStart,
  dateEnd,
  urgencyFilter,
  needStatusFilter,
  referrerFilter,
  setFilters,
  sexTypes,
  unassigned,
  ongoing,
  pending,
  closed,
  followup,
  fetchReferrals,
  totalClosedReferralsPages,
  fetchingReferrals,
  governmentCategories,
  applicationTypes,
  sdohCategories,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [selectedHumanResources, setSelectedHumanResources] = useState([]);
  const [governmentBenefit, setGovernmentBenifit] = useState([]);
  const [communityResources, setCommunityResources] = useState([]);
  const [hideDropdown, setHideDropdown] = useState(false);
  const [currentPageOffset, setCurrentPageOffset] = useState(
    REFERRALS_DEFAULT_OFFSET
  );
  const [display, setDisplay] = useState({
    unassigned: true,
    ongoing: true,
    pending: true,
    closed: true,
    followup: true,
  });
  const [hasUnappliedFilters, setHasUnappliedFilters] = useState(false);
  // const [selectedDetails,setSelectedDetails]=useState([])
  // build query string dynamically to perform search & filtering via backend
  const getQueryString = () => {
    const isValidYear = (dateStr) => Number(dateStr.slice(0, 4)) >= 1000;
    const isValidDateStartInput = dateStart && isValidYear(dateStart);
    const isValidDateEndInput = dateEnd && isValidYear(dateEnd);

    let queryString = {
      closedReferralsPerPageLimit:
        REFERRALS_DEFAULT_CLOSED_PER_PAGE_LIMIT &&
        REFERRALS_DEFAULT_CLOSED_PER_PAGE_LIMIT,
      timezoneOffset: new Date().getTimezoneOffset(),
      patientSearch: searchInput && searchInput,
      pipelineID: pipelineFilter && pipelineFilter,
      careAdvisorID: careAdvisorFilter && careAdvisorFilter,
      needStatusID: needStatusFilter && needStatusFilter,
      urgencyTypeID: urgencyFilter && urgencyFilter,
      referrerID: referrerFilter && referrerFilter,
      sortBy: sortBy && sortBy,
      governmentCategory:
        governmentBenefit.length > 0 ? governmentBenefit : null,
      communityCategory:
        communityResources.length > 0 ? communityResources : null,
      dateStart: dateStart && isValidDateStartInput ? dateStart : null,
      dateEnd: dateEnd && isValidDateEndInput ? dateEnd : null,
    };
    Object.keys(queryString).forEach((key) => {
      if (queryString[key] === null || queryString[key] === "") {
        delete queryString[key];
      }
    });
    return queryString;
  };

  let queryString = getQueryString();

  useEffect(() => {
    const getReferrals = async () => fetchReferrals(queryString);
    getReferrals();
  }, [currentPageOffset]);

  useEffect(() => {
    if (isMounted.current) {
      setHasUnappliedFilters(true);
    }
  }, [
    pipelineFilter,
    careAdvisorFilter,
    sortBy,
    dateStart,
    dateEnd,
    urgencyFilter,
    needStatusFilter,
    referrerFilter,
    searchInput,
    statusFilter,
    governmentBenefit,
    communityResources,
  ]);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    const updatedDisplay = _.mapValues(display, () => false);
    const status = referralStatusOptions.find(
      (option) => option.val === statusFilter
    );
    if (statusFilter === null) {
      setDisplay({
        unassigned: true,
        ongoing: true,
        pending: true,
        closed: true,
        followup: true,
      });
    } else if (status) {
      setDisplay({
        ...updatedDisplay,
        [status.type]: true,
      });
    }
  }, [statusFilter]);

  const searchChangeHandler = ({ target: { value } }) => {
    setSearchInput(value);
  };

  const selectFilterOption = (filter, value) => {
    const updatedFilters = { [filter]: value };
    setFilters(updatedFilters);
  };

  const selectSortOption = (value) => {
    setFilters({ sortBy: value });
  };

  const selectDateStartOption = (value) => {
    setFilters({ dateStart: value });
  };

  const selectDateEndOption = (value) => {
    setFilters({ dateEnd: value });
  };

  const toggleReferralSection = (referralSection) => {
    setFilters({ referralStatusID: null });
    setDisplay({
      ...display,
      [referralSection]: !display[referralSection],
    });
  };

  const clearFiltersClickHandler = async () => {
    resetAllReferrals();
    setSearchInput("");
    setFilters({
      careAdvisorID: null,
      referralStatusID: null,
      pipelineID: null,
      needStatusID: null,
      urgencyID: null,
      referrerID: null,
    });
    selectSortOption(null);
    selectDateStartOption("");
    selectDateEndOption("");
    setCurrentPageOffset(0);
    setSelectedDetails([]);
    setSelectedHumanResources([]);
    setGovernmentBenifit([]);
    setCommunityResources([]);
    setHasUnappliedFilters(false);
    await fetchReferrals({
      closedReferralsPerPageLimit: 50,
      timezoneOffset: -330,
    });
  };

  const mapReferralsToReferralRows = (referrals, referralStatus) =>
    referrals.map((referral) => {
      const {
        referralID,
        patient,
        needs,
        pipelineType,
        assignedToAccountIDs,
        priorityNeedID,
        urgencyTypeID,
        referrerIDs,
        createdAt,
        lastUpdated,
      } = referral;

      const fullName = `${patient.firstName} ${patient.lastName}`;

      const fullAddress = [
        patient.address,
        patient.addressLine2,
        patient.city,
        patient.state,
        patient.zip,
      ]
        .filter((item) => !!item)
        .join(", ");

      const sex = AppData.getSexTypeLabel(patient.sexID, sexTypes);

      const icon = getIconFromPipelineType(pipelineType);

      const urgency = urgencyOptions?.find(
        (urgencyType) => urgencyType.val === urgencyTypeID
      )?.label;

      const linkToReferralDetail = `/patient/${patient.patientID}/referral/${referralID}`;

      const referrers = referrerIDs.map((referrerID) => {
        const name = referrerOptions?.find(
          ({ val }) => val === referrerID
        )?.label;
        return { referrerID, name };
      });
      return (
        <ReferralRow
          dataTestID={`referral-row-${referralID}`}
          referralID={referralID}
          key={referralID}
          fullName={fullName}
          dob={patient.dob}
          email={patient.email}
          phone={patient.phone}
          fullAddress={fullAddress}
          county={patient.county}
          sex={sex}
          assignedToAccountIDs={assignedToAccountIDs}
          needs={needs}
          priorityNeedID={priorityNeedID}
          careAdvisorOptions={careAdvisorOptions}
          icon={icon}
          urgency={urgency}
          referralStatus={referralStatus}
          createdAt={createdAt}
          lastUpdated={lastUpdated}
          linkToReferralDetail={linkToReferralDetail}
          referrers={referrers}
        />
      );
    });

  const isOnFirstPage = currentPageOffset === 0;
  const isOnLastPage = currentPageOffset + 1 === totalClosedReferralsPages;

  const classNamePrevButton = `button-circle-big-primary${
    isOnFirstPage ? "-disabled" : ""
  }`;
  const classNameNextButton = `button-circle-big-primary${
    isOnLastPage ? "-disabled" : ""
  }`;

  const handlePrevPage = () => {
    if (!isOnFirstPage) {
      setCurrentPageOffset((currentOffset) => currentOffset - 1);
    }
  };

  const handleNextPage = () => {
    if (!isOnLastPage) {
      setCurrentPageOffset((currentOffset) => currentOffset + 1);
    }
  };

  const applyFiltersClickHandler = async () => {
    setHasUnappliedFilters(false);
    await fetchReferrals(queryString);
  };

  const pagesOptions = _.range(1, totalClosedReferralsPages + 1).map((i) => ({
    val: i,
    label: i,
  }));

  const hasFilterValues =
    pipelineFilter ||
    careAdvisorFilter ||
    statusFilter ||
    sortBy ||
    dateStart ||
    dateEnd ||
    urgencyFilter ||
    needStatusFilter ||
    referrerFilter ||
    searchInput;

  /**
   * To map application types as a children in government benefits.
   */
  const governmentBenefits = governmentCategories?.map((category) => ({
    ...category,
    type: category.categoryType,
    children: [...applicationTypes],
  }));

  useEffect(() => {
    selectedDetails.filter((item, index) => {
      var setGovernmentBenifitData = item?.firstSelected?.label;
      var setGovernmentBenifitData1 = item?.secondSelected?.label;
      var setGovernmentBenifitId = item?.firstSelected?.id;
      var setGovernmentBenifitId1 = item?.secondSelected?.id;
      var obj = {
        categoryLabel: setGovernmentBenifitData,
        applicationType: setGovernmentBenifitData1,
        categoryLabelID: setGovernmentBenifitId,
        applicationTypeID: setGovernmentBenifitId1,
      };
      setGovernmentBenifit([...governmentBenefit, obj]);
    });
  }, [selectedDetails]);
  useEffect(() => {
    selectedHumanResources.filter((item, index) => {
      var setCommunityResourcesData = item?.label;
      setCommunityResources([...communityResources, setCommunityResourcesData]);
    });
  }, [selectedHumanResources]);

  const addGovernmentCategory = (categories) => {
    setSelectedDetails(categories);
    setHasUnappliedFilters(true);
  };

  /**
   * Function to remove selected option when click on Goverment Resources selected checkbox.
   */
  const removeGovernmentGPCategory = (categoryID) => {
    const remainingSelectedCategories = selectedDetails.filter(
      (category) => category?.firstSelected?.id !== categoryID
    );
    setSelectedDetails(remainingSelectedCategories);
    setHasUnappliedFilters(true);
    const removeData = governmentBenefit.filter(
      (value) => value.categoryLabelID !== categoryID
    );
    setGovernmentBenifit(removeData);
  };

  const addCategory = (category) => {
    setSelectedHumanResources([...selectedHumanResources, category]);
    setHasUnappliedFilters(true);
  };

  /**
   * Function to remove selected option when click on Community Resources selected checkbox.
   */
  const removeGPCategory = (id, label) => {
    const remainingSelectedCategory = selectedHumanResources.filter(
      (category) => category?.id !== id
    );
    setSelectedHumanResources(remainingSelectedCategory);
    setHasUnappliedFilters(true);
    const removeData = communityResources.filter((value) => value === label);
    setCommunityResources(removeData);
  };

  const setFilterHide = () => {
    setHideDropdown(true);
  };
  const setFilterUnHide = () => {
    setHideDropdown(false);
  };

  /**
   * Get classname for apply button based on required fields
   */
  const getClassName = () => {
    if (!hasUnappliedFilters) {
      return "inactive";
    } else {
      return "";
    }
  };
  return (
    <div className="referrals">
      <div className="top-section">
        <div className="utilities-container">
          <div className="referral-filters-top">
            <div className="patient-search-container">
              <input
                className="searchbar-1"
                value={searchInput}
                onChange={searchChangeHandler}
                placeholder="Search a patient by name or phone or email or address"
                data-testid="patient-search-input"
              />
            </div>
            <div className="filter_part">
              <div className="apply-btn-container">
                {hideDropdown ? null : (
                  <Button
                    dataTestID="apply-btn"
                    className={`button-primary-small ${getClassName()}`}
                    buttonText="Apply"
                    buttonTextClassName="body-1-bold"
                    onClickHandler={applyFiltersClickHandler}
                  />
                )}
                {hasFilterValues && hideDropdown === false && (
                  <div
                    data-testid="reset-btn"
                    className="text-link reset-btn"
                    role="button"
                    tabIndex="0"
                    onClick={clearFiltersClickHandler}
                  >
                    Reset All
                  </div>
                )}
              </div>
              {hideDropdown ? (
                <img
                  src={filterclose}
                  alt=""
                  onClick={() => setFilterUnHide()}
                />
              ) : (
                <img
                  src={filter}
                  alt=""
                  onClick={() => setFilterHide()}
                  data-testid="dropdown-filter"
                />
              )}
            </div>
          </div>
          {hideDropdown ? (
            <div className="referral-filters">
              <div
                className={`referral-select${
                  pipelineFilter ? " selected" : ""
                }`}
              >
                <h3 className="footnote">Pipelines</h3>
                <Select
                  dataTestID="pipeline-filter"
                  options={pipelineOptions}
                  value={pipelineFilter}
                  selectFilterOption={selectFilterOption}
                  filterType="pipelineID"
                  isClear
                  id="pipeline-filter"
                  placeholder="Select a pipeline"
                  onChange={(option) =>
                    selectFilterOption("pipelineID", option)
                  }
                  selectContainerClassName="select-container"
                  selectValueContainerClassName="select-value-container"
                  menuClassName="select-menu"
                  selectedOptionClassName="selected-option"
                />
              </div>
              <div
                className={`referral-select${
                  careAdvisorFilter ? " selected" : ""
                }`}
              >
                <h3 className="footnote">CareAdvisors</h3>
                <Select
                  dataTestID="care-advisor-filter"
                  options={careAdvisorOptions}
                  value={careAdvisorFilter}
                  id="care-advisor-filter"
                  selectFilterOption={selectFilterOption}
                  filterType="careAdvisorID"
                  isClear
                  placeholder="Select a care advisor"
                  onChange={(option) =>
                    selectFilterOption("careAdvisorID", option)
                  }
                  selectContainerClassName="select-container"
                  selectValueContainerClassName="select-value-container"
                  menuClassName="select-menu"
                  selectedOptionClassName="selected-option"
                />
              </div>
              <div
                className={`referral-select${statusFilter ? " selected" : ""}`}
              >
                <h3 className="footnote">Referral Status</h3>
                <Select
                  dataTestID="status-filter"
                  options={referralStatusOptions}
                  value={statusFilter}
                  selectFilterOption={selectFilterOption}
                  filterType="referralStatusID"
                  isClear
                  id="status-filter"
                  placeholder="All Statuses"
                  onChange={(option) =>
                    selectFilterOption("referralStatusID", option)
                  }
                  selectContainerClassName="select-container"
                  selectValueContainerClassName="select-value-container"
                  menuClassName="select-menu"
                  selectedOptionClassName="selected-option"
                />
              </div>
              <div
                className={`referral-select${
                  needStatusFilter ? " selected" : ""
                }`}
              >
                <h3 className="footnote">Need Status</h3>
                <Select
                  dataTestID="need-status-filter"
                  options={needStatusOptions}
                  value={needStatusFilter}
                  selectFilterOption={selectFilterOption}
                  filterType="needStatusID"
                  isClear
                  id="need-status-filter"
                  placeholder="Select a need status"
                  onChange={(option) =>
                    selectFilterOption("needStatusID", option)
                  }
                  selectContainerClassName="select-container"
                  selectValueContainerClassName="select-value-container"
                  menuClassName="select-menu"
                  selectedOptionClassName="selected-option"
                />
              </div>
              <div
                className={`referral-select${urgencyFilter ? " selected" : ""}`}
              >
                <h3 className="footnote">Urgency</h3>
                <Select
                  dataTestID="urgency-filter"
                  options={urgencyOptions}
                  value={urgencyFilter}
                  selectFilterOption={selectFilterOption}
                  filterType="urgencyID"
                  isClear
                  id="urgency-filter"
                  placeholder="Select an urgency"
                  onChange={(option) => selectFilterOption("urgencyID", option)}
                  selectContainerClassName="select-container"
                  selectValueContainerClassName="select-value-container"
                  menuClassName="select-menu"
                  selectedOptionClassName="selected-option"
                />
              </div>
              <div
                className={`referral-select${
                  referrerFilter ? " selected" : ""
                }`}
              >
                <h3 className="footnote">Referrer</h3>
                <Select
                  dataTestID="referrer-filter"
                  options={referrerOptions}
                  value={referrerFilter}
                  selectFilterOption={selectFilterOption}
                  filterType="referrerID"
                  isClear
                  id="referrer-filter"
                  placeholder="Select a referrer"
                  onChange={(option) =>
                    selectFilterOption("referrerID", option)
                  }
                  selectContainerClassName="select-container"
                  selectValueContainerClassName="select-value-container"
                  menuClassName="select-menu"
                  selectedOptionClassName="selected-option"
                />
              </div>
              <div className={`referral-select${sortBy ? " selected" : ""}`}>
                <h3 className="footnote">Sort By</h3>
                <Select
                  dataTestID="sort-by-select"
                  options={sortBySelectOptions}
                  value={sortBy}
                  selectFilterOption={selectSortOption}
                  isClear
                  id="sort-by-select"
                  placeholder="Sort By"
                  onChange={(option) => selectSortOption(option)}
                  selectContainerClassName="select-container sort-by-select"
                  selectValueContainerClassName="select-value-container"
                  menuClassName="select-menu"
                  selectedOptionClassName="selected-option"
                />
              </div>
              <div className="referral-close-date">
                <div
                  className={`referral-close-date-select referral-select${
                    dateStart ? " selected" : " unselected"
                  }`}
                >
                  <h3 className="footnote">Need(s) Status Start </h3>
                  <Input
                    id="dateStart"
                    dataTestID="dateStart"
                    className="text-link"
                    label=""
                    type="date"
                    // isDisabled={!needStatusFilter}
                    handleOnChange={({ target: { value } }) =>
                      selectDateStartOption(value)
                    }
                    value={dateStart}
                  />
                </div>
                <div
                  className={`referral-close-date-select referral-select${
                    dateEnd ? " selected" : " unselected"
                  }`}
                >
                  <h3 className="footnote">Need(s) Status End </h3>
                  <Input
                    id="dateEnd"
                    dataTestID="dateEnd"
                    className="text-link"
                    label=""
                    type="date"
                    // isDisabled={!needStatusFilter}
                    handleOnChange={({ target: { value } }) =>
                      selectDateEndOption(value)
                    }
                    value={dateEnd}
                  />
                </div>
              </div>
              <div className="patient-search-container">
                <h3 className="footnote">Government Resources</h3>
                <DropdownDrilldown
                  menuItems={governmentBenefits}
                  selectedItems={selectedDetails}
                  addItem={addGovernmentCategory}
                  removeItem={removeGovernmentGPCategory}
                  placeholder="Select government resources"
                  rootNodeID="root"
                  isSingleSelect
                />
              </div>
              <div className="patient-search-container">
                <h3 className="footnote">Community Resources</h3>
                <MultiSelect
                  name="sdoh-categories"
                  options={sdohCategories}
                  selected={selectedHumanResources}
                  removeOption={removeGPCategory}
                  // onChange={(i)=>console.log("wjhbf",i)}
                  addOption={addCategory}
                  label="Select community resources"
                  rootNodeID="root"
                />
              </div>

              <div className="apply-btn-container">
                <Button
                  dataTestID="apply-btn"
                  className={`button-primary-small ${getClassName()}`}
                  buttonText="Apply"
                  buttonTextClassName="body-1-bold"
                  onClickHandler={applyFiltersClickHandler}
                />
                {hasFilterValues ||
                selectedDetails.length > 0 ||
                selectedHumanResources.length > 0 ? (
                  <div
                    data-testid="reset-btn"
                    className="text-link reset-btn"
                    role="button"
                    tabIndex="0"
                    onClick={clearFiltersClickHandler}
                  >
                    Reset All
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="referrals-container">
        {!fetchingReferrals ? (
          <>
            <div className="referral-total-count">
              Total Results :{" "}
              {unassigned?.length +
                ongoing?.length +
                pending?.length +
                closed?.length +
                followup?.length}
            </div>
            <div className="referrals-section unassigned-section">
              <div
                className={
                  hideDropdown ? "section-heading" : "section-heading_dropdown"
                }
                onClick={() => toggleReferralSection("unassigned")}
                role="button"
                tabIndex="0"
              >
                <h1 className="title-2">
                  Unassigned Referrals
                  <span className="num-referrals">{` (${unassigned.length})`}</span>
                </h1>
                <div className="chevron-container">
                  {display.unassigned
                    ? getChevronDown(WHITE)
                    : getChevronRight(WHITE)}
                </div>
              </div>
              {display.unassigned && (
                <div className="referral-rows-container">
                  {mapReferralsToReferralRows(unassigned, "unassigned")}
                </div>
              )}
            </div>
            <div className="referrals-section ongoing-section">
              <div
                className={
                  hideDropdown ? "section-heading" : "section-heading_dropdown"
                }
                onClick={() => toggleReferralSection("ongoing")}
                role="button"
                tabIndex="0"
              >
                <h1 className="title-2">
                  Ongoing Referrals
                  <span className="num-referrals">{` (${ongoing.length})`}</span>
                </h1>
                <div className="chevron-container">
                  {display.ongoing
                    ? getChevronDown(WHITE)
                    : getChevronRight(WHITE)}
                </div>
              </div>
              {display.ongoing && (
                <div className="referral-rows-container">
                  {mapReferralsToReferralRows(ongoing, "ongoing")}
                </div>
              )}
            </div>
            <div className="referrals-section pending-section">
              <div
                className={
                  hideDropdown ? "section-heading" : "section-heading_dropdown"
                }
                onClick={() => toggleReferralSection("pending")}
                role="button"
                tabIndex="0"
              >
                <h1 className="title-2">
                  Requires Update
                  <span className="num-referrals">{` (${pending.length})`}</span>
                </h1>
                <div className="chevron-container">
                  {display.pending
                    ? getChevronDown(WHITE)
                    : getChevronRight(WHITE)}
                </div>
              </div>
              {display.pending && (
                <div className="referral-rows-container">
                  {mapReferralsToReferralRows(pending, "pending")}
                </div>
              )}
            </div>
            <div className="referrals-section followup-section">
              <div
                className={
                  hideDropdown ? "section-heading" : "section-heading_dropdown"
                }
                onClick={() => toggleReferralSection("followup")}
                role="button"
                tabIndex="0"
              >
                <h1 className="title-2">
                  Follow Up Referrals
                  <span className="num-referrals">{` (${followup.length})`}</span>
                </h1>
                <div className="chevron-container">
                  {display.followup
                    ? getChevronDown(WHITE)
                    : getChevronRight(WHITE)}
                </div>
              </div>
              {display.followup && (
                <div className="referral-rows-container">
                  {mapReferralsToReferralRows(followup, "followup")}
                </div>
              )}
            </div>
            <div className="referrals-section closed-section">
              <div
                className={
                  hideDropdown
                    ? "closed-section-heading-container"
                    : "closed-section-heading-container_dropdown"
                }
              >
                <div
                  className="section-heading"
                  onClick={() => toggleReferralSection("closed")}
                  role="button"
                  tabIndex="0"
                >
                  <h1 className="title-2">
                    Closed Referrals
                    <span className="num-referrals">{` (${closed.length})`}</span>
                  </h1>
                  <div className="chevron-container">
                    {display.closed
                      ? getChevronDown(WHITE)
                      : getChevronRight(WHITE)}
                  </div>
                </div>
                {totalClosedReferralsPages > 1 && closed.length && (
                  <div
                    className="closed-section-pagination"
                    data-testid="closed-section-pagination"
                  >
                    <ButtonCircleBig
                      id="page-prev"
                      dataTestID="page-prev"
                      className={classNamePrevButton}
                      onClickHandler={() => handlePrevPage()}
                      isDirectedToRight={false}
                      isDisabled={isOnFirstPage}
                    />
                    <Select
                      dataTestID="closed-referrals-pagination-selection"
                      options={pagesOptions}
                      value={currentPageOffset + 1}
                      id="closed-referrals-pagination-selection"
                      placeholder=""
                      onChange={(option) => setCurrentPageOffset(option - 1)}
                      selectContainerClassName="select-container"
                      selectValueContainerClassName="select-value-container"
                      menuClassName="select-menu"
                      selectedOptionClassName="selected-option"
                    />
                    <ButtonCircleBig
                      id="page-next"
                      dataTestID="page-next"
                      className={classNameNextButton}
                      onClickHandler={() => handleNextPage()}
                      isDisabled={isOnLastPage}
                    />
                  </div>
                )}
              </div>
              {display.closed && (
                <div className="referral-rows-container">
                  {mapReferralsToReferralRows(closed, "closed")}
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="loading-container">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

Referrals.propTypes = {
  pipelineOptions: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  careAdvisorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  urgencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  needStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  referralStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  sexTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  referrerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  pipelineFilter: PropTypes.number,
  careAdvisorFilter: PropTypes.number,
  statusFilter: PropTypes.number,
  referrerFilter: PropTypes.number,
  sortBy: PropTypes.string,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  urgencyFilter: PropTypes.number,
  needStatusFilter: PropTypes.number,
  setFilters: PropTypes.func.isRequired,
  unassigned: PropTypes.arrayOf(
    PropTypes.shape({
      referralID: PropTypes.number.isRequired,
      patient: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        address: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        county: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        sexID: PropTypes.number,
      }).isRequired,
      pipelineID: PropTypes.number.isRequired,
      assignedToAccountIDs: PropTypes.arrayOf(PropTypes.number),
      priorityNeedID: PropTypes.number,
      urgencyTypeID: PropTypes.number,
      createdAt: PropTypes.string,
      lastUpdated: PropTypes.string,
    })
  ),
  ongoing: PropTypes.arrayOf(
    PropTypes.shape({
      referralID: PropTypes.number.isRequired,
      patient: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        address: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        county: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        sexID: PropTypes.number,
      }).isRequired,
      pipelineID: PropTypes.number.isRequired,
      assignedToAccountIDs: PropTypes.arrayOf(PropTypes.number),
      priorityNeedID: PropTypes.number,
      urgencyTypeID: PropTypes.number,
      createdAt: PropTypes.string,
      lastUpdated: PropTypes.string,
    })
  ),
  pending: PropTypes.arrayOf(
    PropTypes.shape({
      referralID: PropTypes.number.isRequired,
      patient: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        address: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        county: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        sexID: PropTypes.number,
      }).isRequired,
      pipelineID: PropTypes.number.isRequired,
      assignedToAccountIDs: PropTypes.arrayOf(PropTypes.number),
      priorityNeedID: PropTypes.number,
      urgencyTypeID: PropTypes.number,
      createdAt: PropTypes.string,
      lastUpdated: PropTypes.string,
    })
  ),
  closed: PropTypes.arrayOf(
    PropTypes.shape({
      referralID: PropTypes.number.isRequired,
      patient: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        address: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        county: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        sexID: PropTypes.number,
      }).isRequired,
      pipelineID: PropTypes.number.isRequired,
      assignedToAccountIDs: PropTypes.arrayOf(PropTypes.number),
      priorityNeedID: PropTypes.number,
      urgencyTypeID: PropTypes.number,
      createdAt: PropTypes.string,
      lastUpdated: PropTypes.string,
    })
  ),
  followup: PropTypes.arrayOf(
    PropTypes.shape({
      referralID: PropTypes.number.isRequired,
      patient: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        address: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        county: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        sexID: PropTypes.number,
      }).isRequired,
      pipelineID: PropTypes.number.isRequired,
      assignedToAccountIDs: PropTypes.arrayOf(PropTypes.number),
      priorityNeedID: PropTypes.number,
      urgencyTypeID: PropTypes.number,
      createdAt: PropTypes.string,
      lastUpdated: PropTypes.string,
    })
  ),
  fetchReferrals: PropTypes.func.isRequired,
  totalClosedReferralsPages: PropTypes.number.isRequired,
  fetchingReferrals: PropTypes.bool.isRequired,
};

Referrals.defaultProps = {
  pipelineOptions: [],
  careAdvisorOptions: [],
  urgencyOptions: [],
  needStatusOptions: [],
  referralStatusOptions: [],
  pipelineFilter: null,
  careAdvisorFilter: null,
  statusFilter: null,
  urgencyFilter: null,
  needStatusFilter: null,
  referrerFilter: null,
  sortBy: null,
  dateStart: "",
  dateEnd: "",
  unassigned: [],
  ongoing: [],
  pending: [],
  closed: [],
  followup: [],
};

export default Referrals;
