import * as endpoints from '../services/endpoints';

// constants
export const SET_PATIENT = 'SET_PATIENT';
export const SET_HEAD_OF_CASE = 'SET_HEAD_OF_CASE';
export const SET_CUSTOM_ATTRIBUTES = 'SET_CUSTOM_ATTRIBUTES';
export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';
export const UPLOADING_ATTACHMENT = 'UPLOADING_ATTACHMENT';
export const EDIT_PERSONAL_INFO = 'EDIT_PERSONAL_INFO';

// actions
export const setPatient = (patient) => ({
  type: SET_PATIENT,
  patient,
});

export const setHeadOfCase = (headOfCase) => ({
  type: SET_HEAD_OF_CASE,
  headOfCase,
});

export const setCustomAttributes = (customAttributes) => ({
  type: SET_CUSTOM_ATTRIBUTES,
  customAttributes,
});

export const setAttachments = (attachments) => ({
  type: SET_ATTACHMENTS,
  attachments,
});

const uploadingAttachment = () => ({
  type: UPLOADING_ATTACHMENT,
});

export const editPersonalInfo = ({ personID, referralID, personalInfo }) => (
  async (dispatch, getState) => {
    await endpoints.editPerson(personID, referralID, personalInfo);
    const state = getState();
    const { patientInfo: { patient } } = state;
    const newPatientInfo = {
      ...patient,
      ...personalInfo,
    };
    dispatch(setPatient(newPatientInfo));
  }
);

export const addAttachments = (files, patientID) => (
  async (dispatch, getState) => {
    dispatch(uploadingAttachment());
    const newAttachments = await endpoints.uploadAttachments(files, patientID);
    const state = getState();
    const { patientInfo: { attachments } } = state;
    const updatedAttachments = [
      ...attachments,
      ...newAttachments,
    ];
    dispatch(setAttachments(updatedAttachments));
  }
);

export const deleteAttachments = (attachmentIDs) => (
  (dispatch, getState) => {
    const state = getState();
    const { patientInfo: { attachments } } = state;
    const updatedAttachments = attachments.filter((attachment) => (
      !attachmentIDs.includes(attachment.attachmentID)
    ));
    dispatch(setAttachments(updatedAttachments));
    endpoints.deleteAttachments(attachmentIDs);
  }
);

export const createHeadOfCase = (headOfCase) => (
  async (dispatch, getState) => {
    const {
      referral: { referral: { referralID } },
      patientInfo: { patient: { personID: patientPersonID } },
    } = getState();
    const { headOfCasePersonID } = await endpoints
      .createPatientHeadOfCase(headOfCase, referralID, patientPersonID);
    const createdHeadOfCase = { ...headOfCase, personID: headOfCasePersonID };
    dispatch(setHeadOfCase(createdHeadOfCase));
  }
);

export const updateHeadOfCase = (headOfCase) => (
  async (dispatch, getState) => {
    const { patientInfo: { patient: { personID: patientPersonID } } } = getState();
    const { newRelationshipID } = await endpoints
      .updatePatientHeadOfCase(headOfCase, patientPersonID);
    const updatedHeadOfCase = {
      ...headOfCase,
      relationship: {
        ...headOfCase.relationship,
        ...(!!newRelationshipID && { relationshipID: newRelationshipID }),
      },
    };
    dispatch(setHeadOfCase(updatedHeadOfCase));
  }
);

// action handlers
const ACTION_HANDLERS = {
  [SET_PATIENT]: (state, action) => ({
    ...state,
    patient: action.patient,
  }),
  [SET_HEAD_OF_CASE]: (state, action) => ({
    ...state,
    headOfCase: action.headOfCase,
  }),
  [SET_CUSTOM_ATTRIBUTES]: (state, action) => ({
    ...state,
    customAttributes: action.customAttributes,
  }),
  [SET_ATTACHMENTS]: (state, action) => ({
    ...state,
    attachments: action.attachments,
    uploadingAttachment: false,
  }),
  [UPLOADING_ATTACHMENT]: (state) => ({
    ...state,
    uploadingAttachment: true,
  }),
};

const initialState = {
  patient: {},
  headOfCase: {},
  customAttributes: [],
  attachments: [],
  uploadingAttachment: false,
};

export default function patientInfoReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
