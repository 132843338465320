import React from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import './Breadcrumb.scss';

const {
  components: {
    TextLink,
  },
} = common;

const Breadcrumb = ({
  patientFirstName,
  patientLastName,
  patientID,
  referralID,
  isPatientHistoryView,
}) => (
  <div className="breadcrumb" data-testid="breadcrumb">
    <TextLink
      label="All Referrals"
      url="/referrals"
    />
    {isPatientHistoryView && (
      <p
        className="text-link on-page"
        data-testid="patient-name-text"
      >
        {`${patientFirstName} ${patientLastName}`}
      </p>
    )}
    {!isPatientHistoryView && (
      <>
        <TextLink
          label={`${patientFirstName} ${patientLastName}`}
          url={`/patient/${patientID}`}
        />
        <p className="text-link on-page">{referralID}</p>
      </>
    )}
  </div>
);

Breadcrumb.propTypes = {
  patientFirstName: PropTypes.string.isRequired,
  patientLastName: PropTypes.string.isRequired,
  patientID: PropTypes.string.isRequired,
  referralID: PropTypes.string.isRequired,
  isPatientHistoryView: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  isPatientHistoryView: false,
};

export default Breadcrumb;
