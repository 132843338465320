import { connect } from 'react-redux';
import { subDays, isAfter } from 'date-fns';
import ReferralDetailPanel from './ReferralDetailPanel';
import {
  updateReferrers, assignAdvisors, unassignAdvisors, updatePriorityNeed,
} from '../../reducers/referral';
import AppDataService from '../../services/AppData';
import { CLOSED_STATUS_TYPE } from '../../constants';
import { openEditCareTeamLightBox, closeLightBox } from '../../reducers/lightbox';

const mapStateToProps = (state) => {
  const {
    referral: { referral },
    appData: {
      careAdvisors,
      governmentCategories,
      sdohCategories,
      applicationTypes,
      urgencyTypes,
      statuses,
      referrerAccounts,
    },
  } = state;

  const {
    assignedToAccountIDs, needs, urgencyTypeID, priorityNeedID, lastUpdated,
  } = referral;

  const assignedAdvisors = assignedToAccountIDs?.map((accountID) => ({
    value: accountID,
    label: AppDataService.getAccountName(accountID, careAdvisors),
  }));

  const allCategories = [
    ...governmentCategories,
    ...sdohCategories,
  ];

  const priorityNeedOptions = needs?.map((need) => ({
    value: need.needID,
    label: AppDataService.getNeedLabel(
      need.needCategoryID,
      allCategories,
      need.applicationTypeID,
      applicationTypes,
    ),
  }));

  const selectedPriorityNeed = priorityNeedOptions?.find(
    ({ value }) => value === priorityNeedID,
  );

  const urgencyType = AppDataService.getUrgencyType(urgencyTypeID, urgencyTypes);

  const isClosed = needs?.every((need) => {
    const { statusID } = need;
    const foundStatus = statuses?.find((status) => status.id === statusID);
    return foundStatus && foundStatus.type === CLOSED_STATUS_TYPE;
  });

  const tenDaysAgo = subDays(new Date(), 10);
  const isPending = isAfter(tenDaysAgo, new Date(lastUpdated));

  return {
    referral,
    statuses,
    assignedAdvisors,
    priorityNeedOptions,
    selectedPriorityNeed,
    urgencyType,
    isClosed,
    isPending,
    careAdvisors,
    referrerAccounts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatePriorityNeed: (priorityNeedID) => dispatch(updatePriorityNeed(priorityNeedID)),
  assignAdvisors: (assignedToAccountIDs) => dispatch(assignAdvisors(assignedToAccountIDs)),
  updateReferrers: (referrers, referralID) => dispatch(updateReferrers(referrers, referralID)),
  unassignAdvisors: (assignedToAccountIDs) => dispatch(unassignAdvisors(assignedToAccountIDs)),
  closeLightBox: () => dispatch(closeLightBox()),
  openEditCareTeamLightBox: (props) => dispatch(openEditCareTeamLightBox(props)),
});

const ReferralDetailPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralDetailPanel);

export default ReferralDetailPanelContainer;
