/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditableText from '../EditableText';
import './PipelineSiteTable.scss';

const PipelineSiteTable = ({
  pipelines,
  createPipeline,
  updatePipeline,
  createSite,
  updateSite,
}) => {
  const [pipelineBeingEdited, setPipelineBeingEdited] = useState(null);
  const [siteBeingEdited, setSiteBeingEdited] = useState(null);
  const [textBeingEdited, setTextBeingEdited] = useState('');
  const [isAddingPipeline, setIsAddingPipeline] = useState(false);
  const [newPipelineLabel, setNewPipelineLabel] = useState('');
  const [newSite, setNewSite] = useState({ pipelineID: null, label: '' });

  // Adding Sites
  const addSiteClickHandler = (pipelineID) => {
    setNewSite((prev) => ({ ...prev, pipelineID }));
  };

  const newSiteChangeHandler = (event) => {
    setNewSite((prev) => ({ ...prev, label: event.target.value }));
  };

  const confirmNewSiteClickHandler = async () => {
    await createSite(newSite.pipelineID, newSite.label);
    setNewSite({ pipelineID: null, label: '' });
  };

  const cancelNewSiteClickHandler = () => {
    setNewSite({ pipelineID: null, label: '' });
  };

  // Editing sites
  const editSiteClickHandler = (siteID, siteLabel) => {
    setSiteBeingEdited(siteID);
    setTextBeingEdited(siteLabel);
  };

  const editSiteBlurHandler = () => {
    setSiteBeingEdited(null);
  };

  const cancelEditSiteClickHandler = () => {
    setSiteBeingEdited(null);
  };

  const editSiteKeyPressHandler = async (event, siteID) => {
    const { key } = event;
    if (key === 'Enter') {
      await updateSite(siteID, textBeingEdited);
      setSiteBeingEdited(null);
    }
  };

  // Adding Pipelines
  const addPipelineClickHandler = () => {
    setIsAddingPipeline(true);
  };

  const newPipelineChangeHandler = (event) => {
    setNewPipelineLabel(event.target.value);
  };

  const confirmNewPipelineClickHandler = async () => {
    await createPipeline(newPipelineLabel);
    setIsAddingPipeline(false);
    setNewPipelineLabel('');
  };

  const cancelNewPipelineClickHandler = () => {
    setIsAddingPipeline(false);
    setNewPipelineLabel('');
  };

  // Editing Pipelines
  const editPipelineClickHandler = (pipelineID, pipelineLabel) => {
    setPipelineBeingEdited(pipelineID);
    setTextBeingEdited(pipelineLabel);
  };

  const editPipelineBlurHandler = () => {
    setPipelineBeingEdited(null);
  };

  const cancelEditPipelineClickHandler = () => {
    setPipelineBeingEdited(null);
  };

  const editPipelineKeyPressHandler = async (event, pipelineID) => {
    const { key } = event;
    if (key === 'Enter') {
      await updatePipeline(pipelineID, textBeingEdited);
      setPipelineBeingEdited(null);
    }
  };

  const editTextChangeHandler = (event) => {
    const { target: { value } } = event;
    setTextBeingEdited(value);
  };

  return (
    <div className="pipeline-site-table">
      <div className="table-row header-row">
        <div className="table-cell pipelines-cell body-1-bold">
          Pipelines
        </div>
        <div className="table-cell sites-cell body-1-bold">
          Sites
        </div>
        <div className="table-cell users-cell body-1-bold">
          Users
        </div>
        <div className="table-cell spacer-cell">
          <button
            className="button-primary-small"
            type="button"
            disabled={isAddingPipeline}
            onClick={addPipelineClickHandler}
          >
            + Add Pipeline
          </button>
        </div>
      </div>
      {isAddingPipeline && (
      <div className="table-row pipeline-row new-pipeline">
        <div className="table-cell pipelines-cell body-1-bold">
          <div className="input-1">
            <input
              onChange={newPipelineChangeHandler}
              value={newPipelineLabel}
              placeholder="Pipeline Name"
              autoFocus
            />
          </div>
        </div>
        <div className="table-cell sites-cell">
          <button
            className="button-primary-small"
            type="button"
            onClick={confirmNewPipelineClickHandler}
            disabled={!newPipelineLabel}
          >
            Confirm
          </button>
          <button
            className="edit-btn body-1-bold"
            type="button"
            onClick={cancelNewPipelineClickHandler}
          >
            cancel
          </button>
        </div>
        <div className="table-cell users-cell body-1-bold" />
        <div className="table-cell spacer-cell" />
      </div>
      )}
      {pipelines.map(({ id, label, sites }) => {
        const isEditingPipeline = pipelineBeingEdited === id;
        const numPipelineUsers = sites.reduce((acc, current) => acc + current.numUsers, 0);
        const isAddingSite = newSite.pipelineID === id;
        return (
          <React.Fragment key={`pipeline-${id}`}>
            <div className="table-row pipeline-row">
              <div className="table-cell pipelines-cell body-1-bold">
                <EditableText
                  text={isEditingPipeline ? textBeingEdited : label}
                  isEditing={isEditingPipeline}
                  onChange={editTextChangeHandler}
                  onBlur={editPipelineBlurHandler}
                  onKeyPress={(event) => editPipelineKeyPressHandler(event, id)}
                />
                <button
                  className="edit-btn body-1-bold"
                  type="button"
                  onClick={isEditingPipeline
                    ? () => cancelEditPipelineClickHandler()
                    : () => editPipelineClickHandler(id, label)}
                >
                  {isEditingPipeline ? 'cancel' : 'edit'}
                </button>
              </div>
              <div className="table-cell sites-cell">
                <button
                  className="add-site-btn button-primary-small"
                  type="button"
                  onClick={() => addSiteClickHandler(id)}
                >
                  + Add Site
                </button>
              </div>
              <div className="table-cell users-cell body-1-bold">
                {numPipelineUsers}
              </div>
              <div className="table-cell spacer-cell" />
            </div>
            {isAddingSite && (
            <div
              className="table-row site-row new-site"
              key="new-site"
            >
              <div className="table-cell pipelines-cell" />
              <div className="table-cell sites-cell body-1">
                <div className="input-1">
                  <input
                    onChange={newSiteChangeHandler}
                    value={newSite.label}
                    placeholder="Site Name"
                    autoFocus
                  />
                </div>
                <button
                  className="button-primary-small"
                  type="button"
                  onClick={confirmNewSiteClickHandler}
                  disabled={!newSite.label}
                >
                  Confirm
                </button>
                <button
                  className="edit-btn body-1-bold"
                  type="button"
                  onClick={cancelNewSiteClickHandler}
                >
                  cancel
                </button>
              </div>
              <div className="table-cell users-cell body-1" />
              <div className="table-cell spacer-cell" />
            </div>
            )}
            {sites.map((site, siteIndex) => {
              const isEditingSite = site.id === siteBeingEdited;
              return (
                <div
                  className={`table-row site-row${siteIndex === sites.length - 1 ? ' last-site' : ''}`}
                  key={`site-${site.id}`}
                >
                  <div className="table-cell pipelines-cell" />
                  <div className="table-cell sites-cell body-1">
                    <EditableText
                      text={isEditingSite ? textBeingEdited : site.label}
                      isEditing={isEditingSite}
                      onChange={editTextChangeHandler}
                      onBlur={editSiteBlurHandler}
                      onKeyPress={(event) => editSiteKeyPressHandler(event, site.id)}
                    />
                    <button
                      className="edit-btn body-1-bold"
                      type="button"
                      onClick={isEditingSite
                        ? () => cancelEditSiteClickHandler()
                        : () => editSiteClickHandler(site.id, site.label)}
                    >
                      {isEditingSite ? 'cancel' : 'edit'}
                    </button>
                  </div>
                  <div className="table-cell users-cell body-1">
                    {site.numUsers}
                  </div>
                  <div className="table-cell spacer-cell" />
                </div>
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

PipelineSiteTable.propTypes = {
  pipelines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      sites: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          label: PropTypes.string.isRequired,
          pipeline_id: PropTypes.number.isRequired,
          numUsers: PropTypes.number.isRequired,
        }),
      ),
    }),
  ),
  createPipeline: PropTypes.func.isRequired,
  updatePipeline: PropTypes.func.isRequired,
  createSite: PropTypes.func.isRequired,
  updateSite: PropTypes.func.isRequired,
};

PipelineSiteTable.defaultProps = {
  pipelines: [],
};

export default PipelineSiteTable;
