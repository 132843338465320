import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import common from 'gateway-common';
import { getPatient } from '../../services/endpoints';
import Attachment from '../Attachment/Attachment';
import {
  formatPhoneInput, formatSSNInput, formatAddress, formatISODate,
} from '../../utils';
import './PatientInfoPanel.scss';

const {
  svgs: {
    icons: {
      getPatientInfoIcon,
    },
  },
  components: {
    TextLink,
    EditButton,
  },
} = common;

const PatientInfoPanel = ({
  patient,
  headOfCase,
  customAttributes,
  attachments,
  hasNewReferralButton,
  textLinkLabel,
  textLinkUrl,
  openEditHeadOfCaseLightBox,
  openEditCustomAttributesLightBox,
  openEditAttachmentsLightBox,
  openNewReferralWizard,
  openPersonalInfoLightbox,
  setNewReferralPatient,
  setNewReferralHeadOfCase,
  setPatient,
  setHeadOfCase,
  setCustomAttributes,
  setAttachments,
  patientID,
  language,
  isClosed,
  lastUpdated,
}) => {
  const newReferralClickHandler = () => {
    setNewReferralPatient(patient);
    setNewReferralHeadOfCase(headOfCase);
    openNewReferralWizard();
  };

  const headerSection = (
    <div
      className="header-section"
      data-testid="header-section"
    >
      <div>
        <aside>
          { getPatientInfoIcon() }
        </aside>
        <aside>
          {patient.firstName && patient.lastName && (
          <div className="title-2" data-testid="patient-full-name">
            {`${patient.firstName} ${patient.lastName}`}
          </div>
          )}
          {textLinkLabel && (
            <TextLink
              label={textLinkLabel}
              url={textLinkUrl}
            />
          )}
          {(isClosed && lastUpdated) && (
            <div className="tag-closed" data-testid="tag-closed">
              {`Closed ${format(new Date(lastUpdated), 'MM-dd-yyyy')}`}
            </div>
          )}
        </aside>
      </div>
      {hasNewReferralButton && (
        <button
          type="button"
          className="body-1-bold button-primary-big"
          onClick={newReferralClickHandler}
          data-testid="new-patient-referral-btn"
        >
          + New Referral
        </button>
      )}
    </div>
  );

  const headOfCaseFullName = headOfCase
    ? [headOfCase.firstName, headOfCase.lastName].filter((name) => name).join(' ')
    : null;

  const hasAddress = (person) => (
    person?.address || person?.addressLine2 || person?.city || person?.state || person?.zip
  );

  const headOfCaseSection = (
    <div className="panel-section">
      <div className="footnote">
        <div>Head of Case</div>
        <EditButton
          dataTestID="edit-head-of-case-btn"
          clickHandler={() => openEditHeadOfCaseLightBox()}
        />
      </div>
      { headOfCase?.relationship && <p data-testid="relationship">{headOfCase.relationship}</p> }
      { headOfCaseFullName && <p>{headOfCaseFullName}</p> }
      { headOfCase?.phone && (
        <a
          href={`tel:${headOfCase.phone}`}
          className="phone-link"
          data-testid="tel-hoc"
        >
          <p>{formatPhoneInput(headOfCase.phone)}</p>
        </a>
      ) }
      { headOfCase?.email && <p>{headOfCase.email}</p> }
      { (hasAddress(headOfCase)) && (
        <p>
          {formatAddress(
            headOfCase.address, headOfCase.addressLine2,
            headOfCase.city, headOfCase.state, headOfCase.zip,
          )}
        </p>
      )}
      {
        headOfCase?.ssn
        && (
          <p>
            SSN:&nbsp;
            {formatSSNInput(headOfCase.ssn)}
          </p>
        )
      }
    </div>
  );

  useEffect(() => {
    const fetchPatient = async () => {
      const {
        patient: fetchedPatient,
        headOfCase: fetchedHeadOfCase,
        customAttributes: fetchedCustomAttributes,
        attachments: fetchedAttachments,
      } = await getPatient(patientID);
      setPatient(fetchedPatient);
      setHeadOfCase(fetchedHeadOfCase || {});
      setCustomAttributes(fetchedCustomAttributes);
      setAttachments(fetchedAttachments);
    };
    fetchPatient();
  }, [patientID, setAttachments, setCustomAttributes, setHeadOfCase, setPatient]);

  const personalInformationSection = (
    <div className="panel-section">
      <div className="footnote">
        <div>Personal Information</div>
        <EditButton clickHandler={() => openPersonalInfoLightbox({ patientID })} />
      </div>
      {patient.sex && <p>{patient.sex}</p>}
      { patient.dob && <p>{formatISODate(patient.dob)}</p> }
      { patient.phone && (
        <a
          href={`tel:${patient.phone}`}
          className="phone-link"
          data-testid="tel-patient"
        >
          <p>{formatPhoneInput(patient.phone)}</p>
        </a>
      ) }
      { patient.email && <p>{patient.email}</p> }
      { (hasAddress(patient)) && (
        <p>
          {formatAddress(
            patient.address, patient.addressLine2,
            patient.city, patient.state, patient.zip,
          )}
        </p>
      )}
      { patient.county && <p>{`${patient.county} County`}</p> }
      {
        language
        && (
          <p>{`${language} Speaking`}</p>
        )
      }
      {
        patient.ssn
        && (
          <p>
            SSN:&nbsp;
            {formatSSNInput(patient.ssn)}
          </p>
        )
      }
    </div>
  );

  const confidentialSection = (
    <div className="panel-section">
      <div className="footnote">
        <div>
          Patient Information&nbsp;
          <span className="confidential-tag">Confidential</span>
        </div>
        <EditButton clickHandler={() => openEditCustomAttributesLightBox({ patientID })} dataTestID="edit-custom-attributes-btn" />
      </div>
      {
        customAttributes.map((customAttribute, index) => (
          <p key={customAttribute.key} data-testid={`key-value-${index}`}>
            {`${customAttribute.key}: ${customAttribute.value}`}
          </p>
        ))
      }
    </div>
  );

  const attachmentsSection = (
    <div className="panel-section attachments">
      <div className="footnote">
        <div>Attachments</div>
        <EditButton clickHandler={() => openEditAttachmentsLightBox({ patientID })} dataTestID="edit-attachments-btn" />
      </div>
      {
        attachments.map((attachment) => (
          <Attachment
            dataTestID={`patient-info-attachment-${attachment.attachmentID}`}
            key={attachment.attachmentID}
            label={attachment.label}
            url={attachment.url}
            fileType={attachment.fileType}
            createdAt={attachment.createdAt}
          />
        ))
      }
    </div>
  );

  return (
    <div
      className="patient-info-panel"
      data-testid="patient-info-panel"
    >
      { headerSection }
      <div className="scrollable-section">
        { personalInformationSection }
        { headOfCaseSection }
        { confidentialSection }
        { attachmentsSection }
      </div>
    </div>
  );
};

PatientInfoPanel.propTypes = {
  patient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    sex: PropTypes.string,
    dob: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    county: PropTypes.string,
    zip: PropTypes.string,
    language: PropTypes.string,
    ssn: PropTypes.string,
    personID: PropTypes.number,
  }),
  headOfCase: PropTypes.shape({
    relationship: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    ssn: PropTypes.string,
  }),
  customAttributes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  attachments: PropTypes.arrayOf(PropTypes.shape({
    attachmentID: PropTypes.number,
    label: PropTypes.string,
    filename: PropTypes.string,
    createdAt: PropTypes.string,
    filetype: PropTypes.string,
  })),
  hasNewReferralButton: PropTypes.bool,
  textLinkLabel: PropTypes.string,
  textLinkUrl: PropTypes.string,
  openEditHeadOfCaseLightBox: PropTypes.func.isRequired,
  openEditCustomAttributesLightBox: PropTypes.func.isRequired,
  openEditAttachmentsLightBox: PropTypes.func.isRequired,
  openPersonalInfoLightbox: PropTypes.func.isRequired,
  openNewReferralWizard: PropTypes.func.isRequired,
  setNewReferralPatient: PropTypes.func.isRequired,
  setNewReferralHeadOfCase: PropTypes.func.isRequired,
  setPatient: PropTypes.func.isRequired,
  setHeadOfCase: PropTypes.func.isRequired,
  setCustomAttributes: PropTypes.func.isRequired,
  setAttachments: PropTypes.func.isRequired,
  patientID: PropTypes.string.isRequired,
  language: PropTypes.string,
  isClosed: PropTypes.bool,
  lastUpdated: PropTypes.string,
};

PatientInfoPanel.defaultProps = {
  attachments: [],
  patient: {},
  customAttributes: [],
  headOfCase: {},
  hasNewReferralButton: false,
  textLinkLabel: null,
  textLinkUrl: null,
  language: null,
  isClosed: false,
  lastUpdated: null,
};

export default withRouter(PatientInfoPanel);
