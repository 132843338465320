/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import { formatDateTime } from '../../utils';
import { getNotes } from '../../services/endpoints';
import ReferralNotesCard from '../ReferralNotesCard';
import * as endpoints from '../../services/endpoints';
import './ReferralNotes.scss';

const {
  components: {
    FormSelect,
    Button,
  },
} = common;

const ReferralNotes = ({
  accountID: currentCareAdvisorID,
  governmentCategories,
  sdohCategories,
  applicationTypes,
  careAdvisors,
  needs,
  openNoteLightBox,
  noteAdded,
}) => {
  const { referralID } = useParams();
  const allCategories = [...governmentCategories, ...sdohCategories];

  const [notes, setNotes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [needFilter, setNeedFilter] = useState(null);

  const onSearchChange = (event) => {
    const { target: { value } } = event;
    setSearchTerm(value);
  };

  const filterNotesBySearchTerm = (referralNotes) => referralNotes.filter(
    (note) => {
      const { noteLabel, noteText } = note;
      return (
        (noteLabel && noteLabel.toLowerCase().includes(searchTerm.toLowerCase()))
        || (noteText && noteText.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    },
  );

  const filterNotesByNeed = (referralNotes) => {
    if (!needFilter) return referralNotes;
    return referralNotes.filter((note) => {
      const { needsWithAttributes } = note;
      return needsWithAttributes.some((need) => need.needID === needFilter.value);
    });
  };

  const clearClickHandler = () => {
    setSearchTerm('');
    setNeedFilter(null);
  };

  const getNeedLabel = (needCategoryID, applicationTypeID) => {
    const needCategory = allCategories.find((category) => category.id === needCategoryID);
    const categoryLabel = needCategory ? needCategory.label : '';

    let applicationLabel = null;

    if (applicationTypeID) {
      const applicationType = applicationTypes.find((at) => at.id === applicationTypeID);

      applicationLabel = applicationType ? applicationType.label : null;
    }

    const needLabel = categoryLabel + (applicationLabel ? ` ${applicationLabel}` : '');
    return needLabel;
  };

  const getCareAdvisorLabel = (accountID) => {
    const {
      firstName,
      lastName,
    } = careAdvisors.find((advisor) => advisor.id === accountID);

    return `${firstName} ${lastName}`;
  };

  const getNotesAttributes = (notesData) => (
    notesData.map(({
      noteID,
      accountID,
      label: noteLabel,
      noteText,
      createdAt,
      lastUpdated,
      needIDs,
    }) => {
      const needsWithAttributes = needIDs.map((needID) => {
        const {
          needCategoryID,
          applicationTypeID,
        } = needs.find((need) => need.needID === needID);

        return {
          needID,
          needLabel: getNeedLabel(needCategoryID, applicationTypeID),
        };
      });

      const careAdvisorLabel = getCareAdvisorLabel(accountID);

      return {
        noteID,
        accountID,
        noteLabel,
        noteText,
        createdAt,
        lastUpdated,
        needsWithAttributes,
        careAdvisorLabel,
      };
    })
  );

  useEffect(() => {
    const fetchNotes = async () => {
      const notesData = await getNotes(referralID);
      setNotes(getNotesAttributes(notesData));
    };

    fetchNotes();
  }, [referralID]);

  const addNote = async ({ noteLabel, noteText, needIDs }) => {
    const { newNoteID } = await endpoints.addNote(noteLabel, noteText, referralID, needIDs);
    const needsWithAttributes = needIDs.map((needID) => {
      const {
        needCategoryID,
        applicationTypeID,
      } = needs.find((need) => need.needID === needID);

      return {
        needID,
        needLabel: getNeedLabel(needCategoryID, applicationTypeID),
      };
    });
    const careAdvisorLabel = getCareAdvisorLabel(currentCareAdvisorID);
    const newNote = {
      noteID: newNoteID,
      accountID: currentCareAdvisorID,
      noteLabel,
      noteText,
      createdAt: new Date().toISOString(),
      lastUpdated: new Date().toISOString(),
      needsWithAttributes,
      careAdvisorLabel,
    };
    setNotes([
      newNote,
      ...notes,
    ]);
    noteAdded();
  };

  const editNote = async ({
    noteID, noteLabel, noteText, needIDs,
  }) => {
    await endpoints.editNote(noteID, noteLabel, noteText, needIDs);
    const notesClone = notes.slice();
    const needsWithAttributes = needIDs.map((needID) => {
      const {
        needCategoryID,
        applicationTypeID,
      } = needs.find((need) => need.needID === needID);

      return {
        needID,
        needLabel: getNeedLabel(needCategoryID, applicationTypeID),
      };
    });
    const editedNoteIndex = notesClone.findIndex((note) => note.noteID === noteID);
    const noteToEdit = notesClone.splice(editedNoteIndex, 1);
    const editedNote = {
      ...noteToEdit[0],
      noteLabel,
      noteText,
      needsWithAttributes,
    };
    notesClone.splice(editedNoteIndex, 0, editedNote);
    setNotes(notesClone);
  };

  const needOptions = needs.map((need) => ({
    value: need.needID,
    label: getNeedLabel(need.needCategoryID, need.applicationTypeID),
  }));

  const notesFilteredBySearchTerm = filterNotesBySearchTerm(notes);
  const notesFilteredBySearchTermAndNeed = filterNotesByNeed(notesFilteredBySearchTerm);

  return (
    <div
      className="referral-notes-container"
      data-testid="referral-notes-container"
    >
      <div className="note-utilities">
        <div className="dropdown-container">
          <div className="dropdown-title footnote">
            Related Need
          </div>
          <FormSelect
            id="related-need-select"
            options={needOptions}
            selectedOption={needFilter}
            onChangeHandler={setNeedFilter}
            placeholder="Select a need..."
          />
        </div>
        <input
          data-testid="notes-search"
          className="searchbar-1 text-link"
          placeholder="Search a keyword"
          value={searchTerm}
          onChange={onSearchChange}
        />
        {(needFilter || searchTerm) && (
        <div
          data-testid="clear-btn"
          className="text-link clear-btn"
          role="button"
          tabIndex="0"
          onClick={clearClickHandler}
        >
          Clear
        </div>
        )}
        <div className="add-note-btn-container">
          <Button
            dataTestID="add-note-btn"
            className="add-note-btn button-primary-big"
            buttonText="+ Add Note"
            buttonTextClassName="add-note-btn-text body-1-bold"
            onClickHandler={() => openNoteLightBox({
              referralNeeds: needOptions.map((option) => ({ ...option, needID: option.value })),
              addNote,
            })}
          />
        </div>
      </div>
      <div
        className="referral-notes-cards-container"
        data-testid="referral-notes-cards-container"
      >
        {notesFilteredBySearchTermAndNeed.map((note) => (
          <ReferralNotesCard
            key={note.noteID}
            dataTestID={`note-${note.noteID}`}
            noteID={note.noteID}
            noteLabel={note.noteLabel}
            isCurrentCareAdvisor={note.accountID === currentCareAdvisorID}
            needsLabel={note.needsWithAttributes.map((need) => need.needLabel).join(', ')}
            noteText={note.noteText}
            dateLabel={formatDateTime(note.createdAt)}
            careAdvisorLabel={note.careAdvisorLabel}
            emphasizedText={searchTerm}
            noteNeedIDs={note.needsWithAttributes.map((need) => need.needID)}
            referralNeeds={needOptions.map((option) => ({ ...option, needID: option.value }))}
            openNoteLightBox={openNoteLightBox}
            editNote={editNote}
          />
        ))}
      </div>
    </div>
  );
};

ReferralNotes.propTypes = {
  accountID: PropTypes.number,
  governmentCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      categoryType: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  sdohCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      categoryType: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  applicationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  careAdvisors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ).isRequired,
  needs: PropTypes.arrayOf(
    PropTypes.shape({
      needID: PropTypes.number,
      applicationTypeID: PropTypes.number,
      needCategoryID: PropTypes.number,
      statusID: PropTypes.number,
    }),
  ).isRequired,
  openNoteLightBox: PropTypes.func.isRequired,
  noteAdded: PropTypes.func.isRequired,
};

ReferralNotes.defaultProps = {
  accountID: null,
};

export default ReferralNotes;
