import React from 'react';
import common from 'gateway-common';
import EditHeadOfCaseLightBox from '../components/EditHeadOfCaseLightBox';
import EditCustomAttributesLightBox from '../components/EditCustomAttributesLightBox';
import EditAttachmentsLightBox from '../components/EditAttachmentsLightBox';
import EditPersonalInfoLightbox from '../components/EditPersonalInfoLightbox';
import NoteLightBox from '../components/NoteLightBox';
import NeedStatusLightbox from '../components/NeedStatusLightbox';
import AddNeedLightBox from '../components/AddNeedLightBox';
import { LIGHT_BOXES } from '../constants';
import EditAccountModal from '../components/EditAccountModal';
import AccountCreationForm from '../components/AccountCreationForm';
import ConfirmArchiveModal from '../components/ConfirmArchiveModal';

const {
  components: {
    EditCareTeamLightBox,
  },
} = common;

const LIGHT_BOX_COMPONENTS = {
  [LIGHT_BOXES.EDIT_HEAD_OF_CASE]: EditHeadOfCaseLightBox,
  [LIGHT_BOXES.EDIT_CUSTOM_ATTRIBUTES]: EditCustomAttributesLightBox,
  [LIGHT_BOXES.EDIT_ATTACHMENTS]: EditAttachmentsLightBox,
  [LIGHT_BOXES.EDIT_PERSONAL_INFO]: EditPersonalInfoLightbox,
  [LIGHT_BOXES.EDIT_CARE_TEAM]: EditCareTeamLightBox,
  [LIGHT_BOXES.NOTE]: NoteLightBox,
  [LIGHT_BOXES.NEED_STATUS_CHANGE]: NeedStatusLightbox,
  [LIGHT_BOXES.ADD_NEED]: AddNeedLightBox,
  [LIGHT_BOXES.EDIT_ACCOUNT]: EditAccountModal,
  [LIGHT_BOXES.ACCOUNT_CREATION_FORM]: AccountCreationForm,
  [LIGHT_BOXES.CONFIRM_ARCHIVE_MODAL]: ConfirmArchiveModal,
};

const LightBoxService = (lightBox, props = {}) => {
  const LightBoxComponent = LIGHT_BOX_COMPONENTS[lightBox];
  return <LightBoxComponent {...props} />;
};

export default LightBoxService;
