import { connect } from 'react-redux';
import { openNoteLightBox } from '../../reducers/lightbox';
import ReferralNotes from './ReferralNotes';

const mapStateToProps = (state) => {
  const {
    appData: {
      accountID,
      governmentCategories,
      sdohCategories,
      applicationTypes,
      careAdvisors,
    },
    referral: {
      referral: {
        needs,
      },
    },
  } = state;
  return {
    accountID,
    governmentCategories,
    sdohCategories,
    applicationTypes,
    careAdvisors,
    needs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openNoteLightBox: (props) => dispatch(openNoteLightBox(props)),
});

const ReferralNotesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralNotes);

export default ReferralNotesContainer;
