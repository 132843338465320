/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux';
import { setAllReferralsFilters } from '../../reducers/referrals';
import PipelineHome from './PipelineHome';

const mapDispatchToProps = (dispatch) => ({
  setAllReferralsFilters: (filterSettings) => dispatch(setAllReferralsFilters(filterSettings)),
});

const PipelineHomeContainer = connect(
  null,
  mapDispatchToProps,
)(PipelineHome);

export default PipelineHomeContainer;
