// constants
export const SET_ACTIVITY = 'SET_ACTIVITY';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';

// actions
export const setActivity = (activity) => ({
  type: SET_ACTIVITY,
  activity,
});

export const addActivity = (activity) => ({
  type: ADD_ACTIVITY,
  activity,
});

// action handlers
const ACTION_HANDLERS = {
  [SET_ACTIVITY]: (state, action) => ({
    ...state,
    activity: action.activity,
  }),
  [ADD_ACTIVITY]: (state, action) => ({
    ...state,
    activity: [...state.activity, action.activity],
  }),
};

const initialState = {
  activity: [],
};

export default function activityReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
