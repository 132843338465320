import { connect } from 'react-redux';
import PatientInfoPanel from './PatientInfoPanel';
import {
  openEditHeadOfCaseLightBox,
  openEditCustomAttributesLightBox,
  openEditAttachmentsLightBox,
  openPersonalInfoLightbox,
} from '../../reducers/lightbox';
import {
  setNewReferralPatient,
  setNewReferralHeadOfCase,
  openNewReferralWizard,
} from '../../reducers/newReferral';
import {
  setPatient, setHeadOfCase, setCustomAttributes, setAttachments,
} from '../../reducers/patientInfo';
import AppData from '../../services/AppData';
import { CLOSED_STATUS_TYPE } from '../../constants';

const mapStateToProps = (state) => {
  const {
    patientInfo: {
      patient,
      headOfCase,
      customAttributes,
      attachments,
    },
    referral: { referral: { language, lastUpdated, needs } },
    appData: { relationshipTypes, statuses, sexTypes },
  } = state;

  const sex = AppData.getSexTypeLabel(patient?.sexID, sexTypes);

  const transformedPatient = { ...patient, sex };

  const relationship = (
    AppData.getRelationshipTypeLabel(
      headOfCase?.relationship?.relationshipTypeID,
      relationshipTypes,
    )
  );

  const transformedHeadOfCase = headOfCase ? { ...headOfCase, relationship } : null;

  const isClosed = needs?.length > 0 && needs?.every((need) => {
    const { statusID } = need;
    const status = statuses.find((s) => s.id === statusID);
    return status?.type === CLOSED_STATUS_TYPE;
  });

  return {
    patient: transformedPatient,
    headOfCase: transformedHeadOfCase,
    customAttributes,
    attachments,
    language,
    isClosed,
    lastUpdated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openEditHeadOfCaseLightBox: (props) => dispatch(openEditHeadOfCaseLightBox(props)),
  openEditCustomAttributesLightBox: (props) => dispatch(openEditCustomAttributesLightBox(props)),
  openEditAttachmentsLightBox: (props) => dispatch(openEditAttachmentsLightBox(props)),
  openPersonalInfoLightbox: (props) => dispatch(openPersonalInfoLightbox(props)),
  openNewReferralWizard: () => dispatch(openNewReferralWizard()),
  setNewReferralPatient: (patient) => dispatch(setNewReferralPatient(patient)),
  setNewReferralHeadOfCase: (headOfCase) => dispatch(setNewReferralHeadOfCase(headOfCase)),
  setPatient: (patient) => dispatch(setPatient(patient)),
  setHeadOfCase: (headOfCase) => dispatch(setHeadOfCase(headOfCase)),
  setCustomAttributes: (customAttributes) => dispatch(setCustomAttributes(customAttributes)),
  setAttachments: (attachments) => dispatch(setAttachments(attachments)),
});

const PatientInfoPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientInfoPanel);

export default PatientInfoPanelContainer;
