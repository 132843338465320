import { connect } from 'react-redux';
import ConfirmArchiveModal from './ConfirmArchiveModal';
import { closeLightBox } from '../../reducers/lightbox';

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeLightBox()),
});

const ConfirmArchiveModalContainer = connect(
  null,
  mapDispatchToProps,
)(ConfirmArchiveModal);

export default ConfirmArchiveModalContainer;
