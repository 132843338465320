export const APP_NAME = 'crm';

// action card types
export const actionCardFields = {
  // dashboard cards
  unassigned: {
    headingText: 'Unassigned Referrals',
    summaryText: 'New referrals need care advisors',
    buttonText: 'All Unassigned Referrals',
    linkText: 'Assign care advisors',
  },
  all: {
    headingText: 'All Referrals',
    summaryText: 'All referrals referred to Care Advisors',
    buttonText: 'All Referrals',
    linkText: 'View All',
  },
  pending: {
    headingText: 'Requires Update',
    summaryText: 'Referrals have not been updated for 10+ days',
    buttonText: 'All Requires Update',
    linkText: 'View All',
  },
  // pipeline cards
  pipeline: {
    summaryText: 'Total Open Referrals',
    buttonText: 'View All',
    referralStatuses: {
      unassigned: 'Unassigned Referrals',
      ongoing: 'Ongoing Referrals',
      pending: 'Requires Update',
    },
  },
};

export const LIGHT_BOXES = {
  EDIT_PERSONAL_INFO: 'EDIT_PERSONAL_INFO',
  EDIT_HEAD_OF_CASE: 'EDIT_HEAD_OF_CASE_LIGHT_BOX',
  EDIT_CUSTOM_ATTRIBUTES: 'EDIT_CUSTOM_ATTRIBUTES',
  EDIT_ATTACHMENTS: 'EDIT_ATTACHMENTS',
  EDIT_CARE_TEAM: 'EDIT_CARE_TEAM',
  NOTE: 'NOTE',
  NEED_STATUS_CHANGE: 'NEED_STATUS_CHANGE',
  ADD_NEED: 'ADD_NEED',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
  ACCOUNT_CREATION_FORM: 'ACCOUNT_CREATION_FORM',
  CONFIRM_ARCHIVE_MODAL: 'CONFIRM_ARCHIVE_MODAL',
};

// TODO REPLACE BELOW IDS WIH RESPONSE FROM APP DATA ENDPOINT

export const needsStatusTypes = {
  REFERRAL_RECEIVED: 'referral_received',
  SCREENED_PATIENT: 'screened_patient',
  APPLICATION_SUBMITTED: 'application_submitted',
  CASE_CLOSED: 'case_closed',
  MATCHING_PATIENT_WITH_SERVICE: 'service_matching',
};

export const subStatusTypes = {
  DOCUMENTS_NEEDED: 'documents_needed',
  CONTACT_INFO_ISSUE: 'contact_info_issue',
  WAITING_FOR_REPLY: 'waiting_for_reply',
  PATIENT_UNRESPONSIVE: 'patient_unresponsive',
  CASE_RECEIVED_GOVT: 'case_received_govt',
  PENDING: 'pending',
  INTERVIEW_SCHEDULED: 'interview_scheduled',
  INTERVIEW_COMPLETED: 'interview_completed',
  APPLICATION_EXPEDITED: 'application_expedited',
  APPLICATION_APPROVED: 'application_approved',
  APPLICATION_DENIED: 'application_denied',
  PATIENT_INELIGIBLE: 'patient_ineligible',
  AWAITING_APPROVAL: 'awaiting_approval',
  SERVICE_APPROVED: 'service_approved',
  APPLICATION_SUBMIT: 'application_submitted',
  SERVICE_DENIED_BY_PATIENT: 'service_denied_by_patient',
};

export const CLOSED_STATUS_TYPE = 'case_closed';

export const TABLEAU_REPORT_URL = 'https://prod-useast-b.online.tableau.com/t/careadvisors/views/CareAdvisorsDashboards/CAReferralsDB?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';

export const referralSortByTypes = {
  REFERRED_DATE_OLDEST_NEWEST: 'referred_date_oldest_newest',
  REFERRED_DATE_NEWEST_OLDEST: 'referred_date_newest_oldest',
  LAST_UPDATED_OLDEST_NEWEST: 'last_updated_oldest_newest',
  LAST_UPDATED_NEWEST_OLDEST: 'last_updated_newest_oldest',
};

export const sortBySelectOptions = [
  {
    val: referralSortByTypes.REFERRED_DATE_OLDEST_NEWEST,
    label: 'Referred Date, Oldest to Newest',
    type: referralSortByTypes.REFERRED_DATE_OLDEST_NEWEST,
  },
  {
    val: referralSortByTypes.REFERRED_DATE_NEWEST_OLDEST,
    label: 'Referred Date, Newest to Oldest',
    type: referralSortByTypes.REFERRED_DATE_NEWEST_OLDEST,
  },
  {
    val: referralSortByTypes.LAST_UPDATED_OLDEST_NEWEST,
    label: 'Last Updated, Oldest to Newest',
    type: referralSortByTypes.LAST_UPDATED_OLDEST_NEWEST,
  },
  {
    val: referralSortByTypes.LAST_UPDATED_NEWEST_OLDEST,
    label: 'Last Updated, Newest to Oldest',
    type: referralSortByTypes.LAST_UPDATED_NEWEST_OLDEST,
  },
];

export const LOGIN_REDIRECT_URL = '/referrals';
export const AUTHENTICATION_STRATEGIES = ['email', 'google'];

export const REFERRALS_DEFAULT_OFFSET = 0;
export const REFERRALS_DEFAULT_CLOSED_PER_PAGE_LIMIT = 50;

export const TOTAL_CHARS_FORMATTED_PHONE_INPUT = 14; // "(ddd) ddd dddd"

export const DEPARTMENT_LIST=[
  {
    value:'1',
    label:'Cardiology & Cardiac Surgery',
  },
  {
    value:'2',
    label:'Urology',
  },
  {
    value:'3',
    label:'Orthopaedics',
  },
  {
    value:'4',
    label:'Nephrology(Kidney Diseases)',
  },
  {
    value:'5',
    label:'Neonatology',
  },
  {
    value:'6',
    label:'Pulmonary Medicine',
  },
  {
    value:'7',
    label:'Diabetes & Endocrinology',
  },
  {
    value:'8',
    label:'Gastroenterology & GI Surgery',
  },
  {
    value:'9',
    label:'Cancer & Blood Disorders',
  },
  {
    value:'10',
    label:'Neurology & Neurosurgery',
  },
]

export const TABLE_HEADER_VALUES=[
  {
    label:'ID',
    sort:'accountID',
    sortTextUp:'id_up',
    sortTextDown:'id_down',
  },
  {
    label:'Name',
    sort:'firstName',
    sortTextUp:'name_up',
    sortTextDown:'name_down',
  },
  {
    label:'Email',
    sort:'email',
    sortTextUp:'email_up',
    sortTextDown:'email_down',
  },
  {
    label:'Phone Number',
    sort:'phone',
    sortTextUp:'phone_up',
    sortTextDown:'phone_down',
  },
  {
    label:'Pipeline',
    sort:'pipelineID',
    sortTextUp:'pipeline_up',
    sortTextDown:'pipeline_down',
  },
  {
    label:'Site',
    sort:'siteID',
    sortTextUp:'site_up',
    sortTextDown:'site_down',
  },
  {
    label:'Department',
    sort:'department',
    sortTextUp:'department_up',
    sortTextDown:'department_down',
  },
  {
    label:'Last Login Date',
    sort:'lastLogin',
    sortTextUp:'lastlogin_up',
    sortTextDown:'lastlogin_down',
  },
  {
    label:'Account Creation Date',
    sort:'createdAt',
    sortTextUp:'createdat_up',
    sortTextDown:'createdat_down',
  },
  {
    label:'Last Case Submission Days',
    sort:'lastCaseSubmittedDate',
    sortTextUp:'last_case_submission_date_up',
    sortTextDown:'last_case_submission_date_down',
  },

];