import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import common from "gateway-common";
import "./AssignAdvisorDropdown.scss";

const {
  components: { FormSelectMultiple },
} = common;

const AssignAdvisorDropdown = ({
  id,
  dataTestID,
  assignAdvisors,
  unassignAdvisors,
  assignedAdvisors,
  advisorOptions,
}) => {
  const [advisorUpdates, setAdvisorUpdates] = useState({
    advisorsToAssign: [],
    advisorsToUnassign: [],
  });
  const isMounted = useRef(null);
  // useEffect(() => {
  //   isMounted.current = true;
  //   return () => {
  //     isMounted.current = false;
  //   };
  // }, []);

  const { advisorsToAssign, advisorsToUnassign } = advisorUpdates;
  const clicked = useRef(false);

  const onBlurHandler = async () => {
    if (!clicked.current) {
      if (advisorsToAssign?.length) {
        clicked.current = true;
        const advisorValues = advisorsToAssign.map(({ value }) => value);
        await assignAdvisors(advisorValues);
      }

      if (advisorsToUnassign?.length) {
        const advisorValues = advisorsToUnassign.map(({ value }) => value);
        await unassignAdvisors(advisorValues);
      }
      if (isMounted.current) {
        setAdvisorUpdates({
          advisorsToAssign: [],
          advisorsToUnassign: [],
        });
      }
    }
  };

  const onChangeHandler = (updatedAssignedAdvisors) => {
    const updatedAdvisorsToAssign = _.differenceWith(
      updatedAssignedAdvisors,
      assignedAdvisors,
      _.isEqual
    );
    const updatedAdvisorsToUnassign = _.differenceWith(
      assignedAdvisors,
      updatedAssignedAdvisors,
      _.isEqual
    );
    setAdvisorUpdates({
      advisorsToAssign: updatedAdvisorsToAssign,
      advisorsToUnassign: updatedAdvisorsToUnassign,
    });
  };

  const selectedAdviosrs = _.differenceWith(
    [...assignedAdvisors, ...advisorsToAssign],
    advisorsToUnassign
  );
  return (
    <div className="assign-advisor-dropdown" data-testid="advisor-dropdown">
      <FormSelectMultiple
        id={id}
        datatTestID={dataTestID}
        options={advisorOptions}
        selectedOptions={selectedAdviosrs}
        onChangeHandler={onChangeHandler}
        onBlurHandler={onBlurHandler}
        placeholder="Select a care advisor"
      />
    </div>
  );
};

AssignAdvisorDropdown.propTypes = {
  id: PropTypes.string,
  dataTestID: PropTypes.string,
  assignAdvisors: PropTypes.func,
  unassignAdvisors: PropTypes.func,
  assignedAdvisors: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  advisorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
};

AssignAdvisorDropdown.defaultProps = {
  id: null,
  dataTestID: null,
  assignAdvisors: () => {},
  unassignAdvisors: () => {},
  assignedAdvisors: [],
};

export default AssignAdvisorDropdown;
