import { connect } from 'react-redux';
import EditAttachmentsLightBox from './EditAttachmentsLightBox';
import { addAttachments, deleteAttachments } from '../../reducers/patientInfo';
import { closeLightBox } from '../../reducers/lightbox';

const mapStateToProps = (state) => {
  const {
    patientInfo: { attachments, uploadingAttachment },
  } = state;

  return {
    attachments,
    uploadingAttachment,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addAttachments: (files, patientID) => dispatch(addAttachments(files, patientID)),
  deleteAttachments: (attachmentIDs) => dispatch(deleteAttachments(attachmentIDs)),
  closeLightBox: () => dispatch(closeLightBox()),
});

const EditAttachmentsLightBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAttachmentsLightBox);

export default EditAttachmentsLightBoxContainer;
