export const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';
export const COMPLETE_PASSWORD_CHANGE = 'COMPLETE_PASSWORD_CHANGE';

export const setFirstLogin = () => ({
  type: SET_FIRST_LOGIN,
});

export const completePasswordChange = () => ({
  type: COMPLETE_PASSWORD_CHANGE,
});

const ACTION_HANDLERS = {
  [SET_FIRST_LOGIN]: (state) => ({
    ...state,
    firstLogin: true,
    passwordChangeRequired: true,
  }),
  [COMPLETE_PASSWORD_CHANGE]: (state) => ({
    ...state,
    passwordChangeRequired: false,
  }),
};

const initialState = {
  firstLogin: false,
  passwordChangeRequired: false,
};

export default function accountReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
