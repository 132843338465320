import { toast } from 'react-toastify';

const notifySuccess = (message) => {
  toast.success(message, { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
};

const notifyError = (message) => {
  toast.error(message, { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
};

const notifyWarning = (message) => {
  toast.warning(message, { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
};

export {
  notifyError,
  notifySuccess,
  notifyWarning,
};

export const UPDATE_SUCCESS_MESSAGE = 'Updated Successfully';
