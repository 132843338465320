import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import ReferralTimeline from '../ReferralTimeline';
import ReferralNotes from '../ReferralNotes';
import './ReferralContent.scss';

const ReferralContent = ({ noteCount, openAddNeedLightBox, referralUpdated }) => {
  const [selectedTab, setSelectedTab] = useState('Referral Timeline');

  const [currentNoteCount, setCurrentNoteCount] = useState(noteCount);

  const tabContents = [
    {
      tabText: 'Referral Timeline',
      isSelected: selectedTab === 'Referral Timeline',
      tabClickHandler: () => setSelectedTab('Referral Timeline'),
    },
    {
      tabText: `Notes (${currentNoteCount})`,
      isSelected: selectedTab === 'Notes',
      tabClickHandler: () => setSelectedTab('Notes'),
    },
  ];

  const incNotesCount = () => {
    setCurrentNoteCount((prevNoteCount) => (prevNoteCount + 1));
    referralUpdated();
  };

  return (
    <div className="referral-content" data-testid="referral-content">
      <div className="referral-navbar">
        <Tabs tabsContents={tabContents} />
        <div className="add-need-container">
          <button
            type="button"
            className="add-need-btn body-1-bold"
            onClick={openAddNeedLightBox}
          >
            + Add Need
          </button>
        </div>
      </div>
      <div className="tab-details-container">
        {(selectedTab === 'Referral Timeline') && <ReferralTimeline />}
        {(selectedTab === 'Notes') && <ReferralNotes noteAdded={incNotesCount} />}
      </div>
    </div>
  );
};

ReferralContent.propTypes = {
  noteCount: PropTypes.number,
  openAddNeedLightBox: PropTypes.func.isRequired,
  referralUpdated: PropTypes.func.isRequired,
};

ReferralContent.defaultProps = {
  noteCount: 0,
};

export default ReferralContent;
