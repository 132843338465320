import { connect } from 'react-redux';
import ReferralTimeline from './ReferralTimeline';
import { getNeedTimelineByID, deleteSubStatus,closeDeletePopup } from '../../reducers/needTimeline';
import { updateNeedBillingCode, updateSubStatusAndMessage, setEditStatusOpen } from '../../reducers/referral';
import AppDataService from '../../services/AppData';

const mapStateToProps = (state) => {
  const {
    appData: {
      governmentCategories,
      sdohCategories,
      statuses,
      applicationTypes,
      urgencyTypes,
      billingCodes,
    },
    referral: {
      referral: {
        needs,
        urgencyTypeID,
        referralID,
      },
      referralUpdatePopup,
    },
    needTimeline: {
      needTimeline: {
        needStatuses,
      },
      gettingNeedTimeline,
      currentNeedID,
      closeDeleteMessagePopup,
    },
  } = state;

  const allCategories = [...governmentCategories, ...sdohCategories];

  const isUrgent = AppDataService.isUrgent(urgencyTypeID, urgencyTypes);

  const transformedNeeds = needs?.map((need) => {
    const label = AppDataService.getNeedLabel(
      need.needCategoryID,
      allCategories,
      need.applicationTypeID,
      applicationTypes,
    );
    const categoryType = AppDataService.getNeedCategoryType(need.needCategoryID, allCategories);
    const isClosed = AppDataService.isNeedClosed(need?.statusID, statuses);
    return {
      ...need,
      label,
      categoryType,
      isClosed,
    };
  });

  const currentNeed = needs.find(({ needID }) => needID === currentNeedID);
  const currentNeedStatusCreatedAt = needStatuses
    ?.find(({ statusID }) => statusID === currentNeed?.statusID)
    ?.createdAt;

  const billingCodeOptions = billingCodes.map(({ id, label }) => ({
    value: id,
    label,
  }));

  const selectedBillingCodeOption = billingCodeOptions
    .find(({ value }) => value === currentNeed?.billingCodeID);

  return {
    needs: transformedNeeds,
    gettingNeedTimeline,
    currentNeedID,
    isUrgent,
    currentNeedStatusCreatedAt,
    billingCodeOptions,
    selectedBillingCodeOption,
    referralID,
    referralUpdatePopup,
    closeDeleteMessagePopup,
    statuses,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNeedTimelineByID: (needID) => dispatch(getNeedTimelineByID(needID)),
  setEditStatusOpen: (flag) => dispatch(setEditStatusOpen(flag)),
  closeDeletePopup: (flag) =>dispatch(closeDeletePopup(flag)),
  updateSubStatusAndMessage: (payload) => dispatch(updateSubStatusAndMessage(payload)),
  selectBillingCode: (needID, billingCodeID) => (
    dispatch(updateNeedBillingCode(needID, billingCodeID))
  ),
  deleteSubStatusMessage: (deletemessage,needID) => dispatch(deleteSubStatus(deletemessage,needID)),
});

const ReferralTimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralTimeline);

export default ReferralTimelineContainer;
