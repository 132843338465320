import { combineReducers } from 'redux';
import noteReducer from './note';
import attachmentReducer from './attachment';
import patientReducer from './patient';
import personReducer from './person';
import referrerReducer from './referrer';
import activityReducer from './activity';
import undoReducer from './undo';
import newReferralReducer from './newReferral';
import draftsReducer from './drafts';
import accountReducer from './account';
import needTimelineReducer from './needTimeline';
import appDataReducer from './app';
import referralsReducer from './referrals';
import lightBoxReducer from './lightbox';
import patientInfoReducer from './patientInfo';
import referralReducer from './referral';

const rootReducer = combineReducers({
  note: noteReducer,
  attachment: attachmentReducer,
  patient: patientReducer,
  person: personReducer,
  referrer: referrerReducer,
  activity: activityReducer,
  undo: undoReducer,
  newReferral: newReferralReducer,
  drafts: draftsReducer,
  account: accountReducer,
  needTimeline: needTimelineReducer,
  appData: appDataReducer,
  referrals: referralsReducer,
  lightBox: lightBoxReducer,
  patientInfo: patientInfoReducer,
  referral: referralReducer,
});

export default rootReducer;
