import { LIGHT_BOXES } from '../constants';
import { searchAdmin } from '../services/endpoints';

export const OPEN_EDIT_PERSONAL_INFO_LIGHTBOX = 'OPEN_EDIT_PERSONAL_INFO_LIGHTBOX';
export const OPEN_EDIT_HEAD_OF_CASE_LIGHT_BOX = 'OPEN_EDIT_HEAD_OF_CASE_LIGHT_BOX';
export const OPEN_EDIT_CUSTOM_ATTRIBUTES_LIGHT_BOX = 'OPEN_EDIT_CUSTOM_ATTRIBUTES_LIGHT_BOX';
export const OPEN_EDIT_ATTACHMENTS_LIGHT_BOX = 'OPEN_EDIT_ATTACHMENTS_LIGHT_BOX';
export const OPEN_NOTE_LIGHT_BOX = 'OPEN_NOTE_LIGHT_BOX';
export const OPEN_NEED_STATUS_CHANGE_LIGHT_BOX = 'OPEN_NEED_STATUS_CHANGE_LIGHT_BOX';
export const OPEN_EDIT_CARE_TEAM_LIGHT_BOX = 'OPEN_EDIT_CARE_TEAM_LIGHT_BOX';
export const OPEN_ADD_NEED_LIGHT_BOX = 'OPEN_ADD_NEED_LIGHT_BOX';
export const OPEN_ACCOUNT_CREATION_FORM = 'OPEN_ACCOUNT_CREATION_FORM';
export const OPEN_EDIT_ACCOUNT_MODAL = 'OPEN_EDIT_ACCOUNT_MODAL';
export const OPEN_CONFIRM_ARCHIVE_MODAL = 'OPEN_CONFIRM_ARCHIVE_MODAL';
export const CLOSE_LIGHT_BOX = 'CLOSE_LIGHT_BOX';
export const SEARCH_ADMIN_DATA='SEARCH_ADMIN_DATA';

export const openPersonalInfoLightbox = (props) => ({
  type: OPEN_EDIT_PERSONAL_INFO_LIGHTBOX,
  props,
});

export const openEditHeadOfCaseLightBox = (props) => ({
  type: OPEN_EDIT_HEAD_OF_CASE_LIGHT_BOX,
  props,
});

export const openEditCustomAttributesLightBox = (props) => ({
  type: OPEN_EDIT_CUSTOM_ATTRIBUTES_LIGHT_BOX,
  props,
});

export const openEditAttachmentsLightBox = (props) => ({
  type: OPEN_EDIT_ATTACHMENTS_LIGHT_BOX,
  props,
});

export const openNoteLightBox = (props) => ({
  type: OPEN_NOTE_LIGHT_BOX,
  props,
});

export const openNeedStatusChangeLightbox = (props) => ({
  type: OPEN_NEED_STATUS_CHANGE_LIGHT_BOX,
  props,
});

export const openEditCareTeamLightBox = (props) => ({
  type: OPEN_EDIT_CARE_TEAM_LIGHT_BOX,
  props,
});

export const openAddNeedLightBox = (props) => ({
  type: OPEN_ADD_NEED_LIGHT_BOX,
  props,
});

export const openAccountCreationForm = (props) => ({
  type: OPEN_ACCOUNT_CREATION_FORM,
  props,
});

export const openEditAccountModal = (props) => ({
  type: OPEN_EDIT_ACCOUNT_MODAL,
  props,
});

export const openConfirmArchiveModal = (props) => ({
  type: OPEN_CONFIRM_ARCHIVE_MODAL,
  props,
});

export const closeLightBox = () => ({
  type: CLOSE_LIGHT_BOX,
});

export const adminSearchData =()=>({
  type:SEARCH_ADMIN_DATA,
})

export const searchAdminData =(inputData,pagePerData, pagenumber, sort, order, isReferrer)=>(
  async (dispatch) => {
    dispatch(adminSearchData());
    const admindata= await searchAdmin(inputData,pagePerData, pagenumber, sort, order, isReferrer);
    return admindata 
  }
);

const ACTION_HANDLERS = {
  [OPEN_EDIT_PERSONAL_INFO_LIGHTBOX]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.EDIT_PERSONAL_INFO,
    props: action.props,
  }),
  [OPEN_EDIT_HEAD_OF_CASE_LIGHT_BOX]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.EDIT_HEAD_OF_CASE,
    props: action.props,
  }),
  [OPEN_EDIT_CUSTOM_ATTRIBUTES_LIGHT_BOX]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.EDIT_CUSTOM_ATTRIBUTES,
    props: action.props,
  }),
  [OPEN_EDIT_ATTACHMENTS_LIGHT_BOX]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.EDIT_ATTACHMENTS,
    props: action.props,
  }),
  [OPEN_EDIT_CARE_TEAM_LIGHT_BOX]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.EDIT_CARE_TEAM,
    props: action.props,
  }),
  [OPEN_NOTE_LIGHT_BOX]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.NOTE,
    props: action.props,
  }),
  [OPEN_NEED_STATUS_CHANGE_LIGHT_BOX]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.NEED_STATUS_CHANGE,
    props: action.props,
  }),
  [OPEN_ADD_NEED_LIGHT_BOX]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.ADD_NEED,
    props: action.props,
  }),
  [OPEN_ACCOUNT_CREATION_FORM]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.ACCOUNT_CREATION_FORM,
    props: action.props,
  }),
  [OPEN_EDIT_ACCOUNT_MODAL]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.EDIT_ACCOUNT,
    props: action.props,
  }),
  [OPEN_CONFIRM_ARCHIVE_MODAL]: (state, action) => ({
    ...state,
    lightBox: LIGHT_BOXES.CONFIRM_ARCHIVE_MODAL,
    props: action.props,
  }),
  [CLOSE_LIGHT_BOX]: (state) => ({
    ...state,
    lightBox: null,
    props: {},
  }),
};

const initialState = {
  lightbox: null,
  props: {},
};

export default function lightBoxReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
