import { connect } from 'react-redux';
import ReferralDetail from './ReferralDetail';
import { fetchReferral, resetFetchingReferral } from '../../reducers/referral';

const mapStateToProps = (state) => {
  const {
    patientInfo: {
      patient,
    },
    referral: {
      fetchingReferral,
    },
  } = state;

  return {
    patient,
    fetchingReferral,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReferral: (referralID) => dispatch(fetchReferral(referralID)),
  resetFetchingReferral: () => dispatch(resetFetchingReferral()),
});

const ReferralDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralDetail);

export default ReferralDetailContainer;
