import React from 'react';
import common from 'gateway-common';
import { updatePasswordFromForgotPasswordFlow } from '../../services/endpoints';

const { components: { PasswordReset } } = common;

const PasswordResetContainer = () => (
  <PasswordReset updatePassword={updatePasswordFromForgotPasswordFlow} />
);

export default PasswordResetContainer;
