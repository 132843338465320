import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import EditLightbox from '../EditLightbox';
import Attachment from '../Attachment/Attachment';
import './EditAttachmentsLightBox.scss';

const ONE_HUNDRED_MB = 104857600;
const ACCEPTED_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'image/png',
  'image/jpeg',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/pdf',
];

const EditAttachmentsLightBox = ({
  patientID,
  attachments,
  addAttachments,
  deleteAttachments,
  uploadingAttachment,
  closeLightBox,
}) => {
  const [attachmentsToDelete, setAttachmentsToDelete] = useState([]);
  const [error, setError] = useState(false);

  const onDrop = (newAttachments) => {
    const uploadSize = newAttachments.reduce((accumulator, file) => {
      const { size } = file;
      return accumulator + size;
    }, 0);

    if (uploadSize > ONE_HUNDRED_MB) {
      setError(true);
      return;
    }
    setError(false);
    addAttachments(newAttachments, patientID);
  };

  const onDelete = (attachmentID) => {
    setAttachmentsToDelete([
      ...attachmentsToDelete,
      attachmentID,
    ]);
  };

  const onConfirm = () => {
    if (attachmentsToDelete.length > 0) {
      deleteAttachments(attachmentsToDelete);
    }
    closeLightBox();
  };
  return (
    <EditLightbox
      headerLabel="Attachment"
      isOpen
      confirmButtonHandler={onConfirm}
      closeButtonHandler={closeLightBox}
    >
      <div className="attachments-container" data-testid="edit-attachments-lightbox">
        {attachments
          .filter((attachment) => !attachmentsToDelete.includes(attachment.attachmentID))
          .map((attachment) => (
            <Attachment
              dataTestID={`attachment-${attachment.attachmentID}`}
              key={attachment.attachmentID}
              label={attachment.label}
              url={attachment.url}
              fileType={attachment.fileType}
              createdAt={attachment.createdAt}
              deleteable
              onDelete={() => onDelete(attachment.attachmentID)}
            />
          ))}
        <Dropzone onDrop={onDrop} accept={ACCEPTED_FILE_TYPES}>
          {({ getRootProps, getInputProps }) => (
            <section className="dropzone-container">
              <div className="dropzone" data-testid="dropzone" {...getRootProps()}>
                <input data-testid="dropzone-input" {...getInputProps()} />
                <p className="body-2">Maximum File Size: 100 MB</p>
              </div>
            </section>
          )}
        </Dropzone>
        {uploadingAttachment && (
          <div className="spinner-container">
            <i className="fas fa-circle-notch fa-spin" data-testid="spinner-icon" />
          </div>
        )}
        {error && (
          <div className="error-msg" data-testid="error-msg">Upload cannot be greater than 100 MB</div>
        )}
      </div>
    </EditLightbox>
  );
};

EditAttachmentsLightBox.propTypes = {
  patientID: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      attachmentID: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      fileType: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
  ),
  uploadingAttachment: PropTypes.bool,
  addAttachments: PropTypes.func.isRequired,
  deleteAttachments: PropTypes.func.isRequired,
  closeLightBox: PropTypes.func.isRequired,
};

EditAttachmentsLightBox.defaultProps = {
  attachments: [],
  uploadingAttachment: false,
};

export default EditAttachmentsLightBox;
