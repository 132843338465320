import React, { useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import Logo from '../../assets/CA-logo-horizontal-white.png';
import { logout } from '../../services/endpoints';
import { TABLEAU_REPORT_URL } from '../../constants';
import './Navbar.scss';

const {
  svgs: {
    icons: {
      getChevronDown,
      getAccountIcon,
    },
  },
  components: { Button },
  constants: {
    WHITE,
    GREY_1,
    PRIMARY_COLOR_DARK,
    PRIMARY_COLOR_LIGHT,
  },
} = common;

const Navbar = ({
  setTriggerDrafts,
  openNewReferralWizard,
  setDraft,
  newReferralWizardIsOpen,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const { push } = useHistory();
  const { pathname } = useLocation();

  const signOut = async (event) => {
    event.preventDefault();
    await logout();
    push('/');
  };

  const clickNewReferral = async () => {
    await setDraft({});
    openNewReferralWizard();
  };

  const setAccountIcon = () => {
    let accountIcon = getAccountIcon(PRIMARY_COLOR_LIGHT);
    if (pathname === '/profile') accountIcon = getAccountIcon(PRIMARY_COLOR_DARK);
    if (!isHovered) accountIcon = getAccountIcon(GREY_1);

    return accountIcon;
  };

  return (
    <div data-testid="top-nav" className="top-nav">
      <nav>
        <div className="title-logo">
          <Link to="/home">
            <img src={Logo} alt="Care Advisors Logo" />
          </Link>
        </div>
        <div className="subheadings">
          <ul>
            <li
              data-testid="subheading"
              className={`subheading-text ${(pathname === '/home') ? 'current-location' : ''}`}
            >
              <Link
                to="/home"
                className="body-1-bold"
                data-testid="subheading-link"
              >
                Dashboard
              </Link>
            </li>
            <li
              data-testid="subheading"
              className={`subheading-text ${(pathname === '/referrals') ? 'current-location' : ''}`}
            >
              <Link
                to="/referrals"
                className="body-1-bold"
                data-testid="subheading-link"
              >
                Referrals
              </Link>
            </li>
            <li
              data-testid="subheading"
              className={`subheading-text subheading-text-report ${(pathname === '/report') ? 'current-location' : ''}`}
            >
              <Link
                to={{ pathname: TABLEAU_REPORT_URL }}
                target="blank"
                className="body-1-bold"
                data-testid="subheading-link"
              >
                Report
              </Link>
            </li>
            <li data-testid="subheading">
              <Button
                dataTestID="button-new-referral"
                className={`button-secondary-small-1-onnav ${newReferralWizardIsOpen ? 'current-location' : ''}`}
                buttonText="New Referral"
                buttonTextClassName="text-link"
                onClickHandler={() => clickNewReferral()}
              />
            </li>
            <li data-testid="subheading">
              <div
                data-testid="dropdown-container"
                className="dropdown-container"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <div className="profile-icon">
                  {setAccountIcon()}
                  {getChevronDown(WHITE)}
                </div>
                {isHovered && (
                  <div
                    data-testid="profile-dropdown"
                    className="profile-dropdown"
                  >
                    <Link
                      data-testid="dropdown-link"
                      to="/profile"
                      className="text-link"
                    >
                      My Profile
                    </Link>
                    <div
                      data-testid="dropdown-link"
                      role="button"
                      tabIndex={0}
                      onClick={() => { setTriggerDrafts(true); }}
                      onKeyPress={(e) => { if (e.code === 'Enter') setTriggerDrafts(true); }}
                    >
                      <p
                        className="text-link"
                        data-testid="dropdown-link-my-drafts"
                      >
                        My Drafts
                      </p>
                    </div>
                    <Link
                      data-testid="dropdown-link"
                      to="/admin"
                      className="text-link"
                    >
                      Admin
                    </Link>
                    <Link
                      data-testid="dropdown-link"
                      to="/"
                      className="text-link"
                      onClick={(event) => signOut(event)}
                    >
                      Sign Out
                    </Link>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

Navbar.propTypes = {
  setTriggerDrafts: PropTypes.func.isRequired,
  openNewReferralWizard: PropTypes.func.isRequired,
  setDraft: PropTypes.func.isRequired,
  newReferralWizardIsOpen: PropTypes.bool.isRequired,
};

export default Navbar;
