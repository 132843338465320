import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import { useSelector } from 'react-redux';
import VerticalTimeline from '../VerticalTimeline';
import EditStatusAndMessageLightbox from '../EditStatusAndMessageLightbox';
import './ReferralTimeline.scss';
import DeleteStatusLightbox from '../DeletedStatusLightbox/DeleteStatusLightbox';

const {
  components: {
    Button,
    FormSelect,
  },
  svgs: {
    getNeedCategoryIcon,
  },
  utils: {
    formatISODate,
  },
} = common;

const ReferralTimeline = ({
  needs,
  getNeedTimelineByID,
  gettingNeedTimeline,
  currentNeedID,
  isUrgent,
  currentNeedStatusCreatedAt,
  billingCodeOptions,
  selectedBillingCodeOption,
  selectBillingCode,
  referralID,
  updateSubStatusAndMessage,
  setEditStatusOpen,
  closeDeletePopup,
  referralUpdatePopup,
  deleteSubStatusMessage,
  closeDeleteMessagePopup,
  statuses,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [editStatusOptions, setEditStatusOptions] = useState({
    subStatus: {
      value: null,
      label: '',
      type: '',
    },
    message: '',
    messageId: null,
    currentStatusId: null,
  });
  const [deleteStatusOpen, setDeleteStatusOpen]=useState(false)
  const [deletemessage, setDeletemessage]=useState('')
  const masterSubStatus = useSelector((state)=> state);
  const popupValue = masterSubStatus.needTimeline.closeDeleteMessagePopup;
  const needIDData = masterSubStatus?.needTimeline?.currentNeedID;

  const updateURL = (needID) => {
    const newURL = `${location.pathname}?needID=${needID}`;
    history.push(newURL);
  };

  /**
   * Function to handle onchange for update popup.
   * @param {obj} subStatus 
   * @param {string} message 
   * @param {integer} messageId 
   * @param {integer} statusID
   */
  const editStatusOpenCallback = (subStatus, message, messageId, statusID) => {
    setEditStatusOpen(true);
    setEditStatusOptions({...{
      subStatus:{
        value: subStatus?.subStatusID || subStatus?.value || null,
        label: subStatus?.subStatusLabel || subStatus?.label,
        type: subStatus?.subStatusType || subStatus?.type,
      },
      message,
      messageId,
      currentStatusId: statusID
    }})
  }

  /**
   * Function to make payload for update message and substatus for referral
   */
  const confirmButtonHandler = () => {
    const payload = {
      statusID: editStatusOptions.currentStatusId,
      subStatusID: editStatusOptions?.subStatus?.value | null,
      message: editStatusOptions.message,
      referralID: referralID,
      isStatusUpdated: true,
      currentNeedID: currentNeed.needID,
      needIDs: [currentNeed.needID],
      needStatusMessageID: editStatusOptions.messageId,
    }
    updateSubStatusAndMessage(payload);
  }

  const search = new URLSearchParams(location.search);
  const searchNeedID = parseInt(search.get('needID'), 10);

  useEffect(() => {
    const isValidSearchNeedID = needs.some((need) => need.needID === searchNeedID);
    if (searchNeedID && isValidSearchNeedID) {
      getNeedTimelineByID(searchNeedID);
    } else {
      const { needID: initialNeedID } = needs[0];
      updateURL(initialNeedID);
      getNeedTimelineByID(initialNeedID);
    }
  }, [searchNeedID]);

  useEffect(() =>{
    if(popupValue){
      setDeleteStatusOpen(false)
    } 
  },[popupValue])

  const currentNeed = needs.find(({ needID }) => needID === currentNeedID);

  const isLoadedData = gettingNeedTimeline === false;

  const needTabs = needs.map((need) => {
    const onClick = () => {
      if (currentNeedID !== need.needID) updateURL(need.needID);
    };
    const className = `button-secondary-small-2${(need.needID === currentNeedID) ? ' active' : ''}${need.isClosed ? ' closed' : ''}`;
    return (
      <Button
        key={need.needID}
        dataTestID="need-tab-button"
        className={className}
        buttonTextClassName="text-link"
        buttonText={need.label}
        onClickHandler={onClick}
      />
    );
  });

  return (
    <div
      className="referral-timeline-container"
      data-testid="referral-timeline-container"
    >
      {needs.length > 1 && (
        <div
          className="needs-tabs-container"
          data-testid="needs-tabs-container"
        >
          {needTabs}
        </div>
      )}
      {isLoadedData && currentNeed && (
        <div className="need-summary">
          <div
            className="need-icon"
            data-testid="need-icon"
          >
            {getNeedCategoryIcon(currentNeed.categoryType, isUrgent, currentNeed.isClosed)}
          </div>
          <h2
            className="title-1"
            data-testid="need-label"
          >
            {currentNeed.label}
          </h2>
          {currentNeed.isClosed && currentNeedStatusCreatedAt && (
            <p
              className="body-1-bold"
              data-testid="need-closed-date"
            >
              {`Closed ${formatISODate(currentNeedStatusCreatedAt)}`}
            </p>
          )}
          <div className="billing-code-container">
            <div className="billing-code-select">
              <FormSelect
                id="billing-code-dropdown"
                placeholder="Select a billing code..."
                options={billingCodeOptions}
                selectedOption={selectedBillingCodeOption}
                label="Billing Code"
                onChangeHandler={({ value }) => selectBillingCode(currentNeedID, value)}
              />
            </div>
          </div>
        </div>
      )}
      {isLoadedData && (
        <VerticalTimeline {...{isEditable: true, editStatusOpenCallback, referralUpdatePopup,setDeleteStatusOpen,setDeletemessage,closeDeletePopup}} />
      )}
      <EditStatusAndMessageLightbox {
        ...{
          referralUpdatePopup,
          setEditStatusOpen,
          headerLabel: 'Update Status',
          editStatusOptions,
          editStatusOpenCallback,
          currentNeed,
          confirmButtonHandler,
          statuses,
        }
      }/>
      <DeleteStatusLightbox {...{deleteStatusOpen, setDeleteStatusOpen, deletemessage ,deleteSubStatusMessage, needIDData,closeDeletePopup,closeDeleteMessagePopup}}/>
    </div>
  );
};

ReferralTimeline.propTypes = {
  needs: PropTypes.arrayOf(
    PropTypes.shape({
      needID: PropTypes.number.isRequired,
      applicationTypeID: PropTypes.number,
      needCategoryID: PropTypes.number.isRequired,
      statusID: PropTypes.number.isRequired,
      categoryType: PropTypes.string.isRequired,
      isClosed: PropTypes.bool.isRequired,
    }),
  ),
  gettingNeedTimeline: PropTypes.bool,
  getNeedTimelineByID: PropTypes.func.isRequired,
  currentNeedID: PropTypes.number,
  isUrgent: PropTypes.bool,
  currentNeedStatusCreatedAt: PropTypes.string,
  billingCodeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedBillingCodeOption: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  selectBillingCode: PropTypes.func.isRequired,
};

ReferralTimeline.defaultProps = {
  needs: [],
  gettingNeedTimeline: false,
  currentNeedID: null,
  isUrgent: false,
  currentNeedStatusCreatedAt: null,
  selectedBillingCodeOption: null,
};

export default ReferralTimeline;
