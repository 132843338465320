export const OPEN_NEW_REFERRAL_WIZARD = 'OPEN_NEW_REFERRAL_WIZARD';
export const CLOSE_NEW_REFERRAL_WIZARD = 'CLOSE_NEW_REFERRAL_WIZARD';
export const SET_NEW_REFERRAL_PATIENT = 'SET_NEW_REFERRAL_PATIENT';
export const SET_NEW_REFERRAL_HEAD_OF_CASE = 'SET_NEW_REFERRAL_HEAD_OF_CASE';
export const SET_DUPLICATE_REFERRAL = 'SET_DUPLICATE_REFERRAL';

export const openNewReferralWizard = () => ({
  type: OPEN_NEW_REFERRAL_WIZARD,
});

export const closeNewReferralWizard = () => ({
  type: CLOSE_NEW_REFERRAL_WIZARD,
});

export const setNewReferralPatient = (patient) => ({
  type: SET_NEW_REFERRAL_PATIENT,
  patient,
});

export const setNewReferralHeadOfCase = (headOfCase) => ({
  type: SET_NEW_REFERRAL_HEAD_OF_CASE,
  headOfCase,
});

export const setDuplicateReferral = (duplicateRefData) => ({
  type: SET_DUPLICATE_REFERRAL,
  duplicateRefData,
});

const ACTION_HANDLERS = {
  [OPEN_NEW_REFERRAL_WIZARD]: (state, action) => ({
    ...state,
    isOpen: true,
  }),
  [CLOSE_NEW_REFERRAL_WIZARD]: (state, action) => ({
    ...state,
    isOpen: false,
    patient: null,
    headOfCase: null,
  }),
  [SET_NEW_REFERRAL_PATIENT]: (state, action) => ({
    ...state,
    patient: action.patient,
  }),
  [SET_NEW_REFERRAL_HEAD_OF_CASE]: (state, action) => ({
    ...state,
    headOfCase: action.headOfCase,
  }),
  [SET_DUPLICATE_REFERRAL]: (state, action) => ({
    ...state,
    duplicateRefData: action.duplicateRefData,
  }),
};

const initialState = {
  isOpen: false,
  draft: {},
  patient: null,
  headOfCase: null,
  duplicateRefData: null,
};

export default function newReferralReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
