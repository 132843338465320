import React from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import './ActionCard.scss';

const {
  svgs: {
    icons: {
      getRightArrowIcon,
    },
  },
} = common;

const ActionCard = ({
  actionCardType,
  onClickHandlerActionCardButton,
  isActive,
  isPendingReferral,
  icon: { src, width, height },
  headingText,
  summaryValue,
  summaryText,
  buttonText,
  dataArray,
}) => {
  let cardTypeAttribute;

  switch (actionCardType) {
    case 'dashboard':
      cardTypeAttribute = '-dashboard';
      break;
    case 'pipeline':
      cardTypeAttribute = '-pipeline';
      break;
    default:
      cardTypeAttribute = '';
      break;
  }

  const actionCardArrayElements = dataArray.map((element) => {
    const {
      key,
      value,
      label,
      linkText,
      isActive: isActionCardActive,
      isPendingReferral: isActionCardPendingReferral,
      onClickLinkHandler,
    } = element;

    return (
      <div
        key={key}
        data-testid="action-card-array-element"
        className="action-card-array-element"
      >
        <div className="action-card-array-element-data">
          <p
            data-testid="action-card-array-element-value"
            className={`title-1 action-card-array-element-value
              ${(isActionCardActive ? ' is-active' : ' is-inactive')}
              ${(isActionCardPendingReferral ? ' is-pending-referral' : '')}
            `}
          >
            {value}
          </p>
          <p
            data-testid="action-card-array-element-label"
            className="body-2 action-card-array-element-label"
          >
            {label}
          </p>
        </div>
        <div
          className="action-card-array-element-link"
          onClick={isActionCardActive ? onClickLinkHandler : null}
          onKeyPress={(e) => { if (e.code === 'Enter') onClickLinkHandler(); }}
          role="button"
          tabIndex={0}
          disabled={!isActionCardActive}
        >
          <p
            data-testid="action-card-array-element-link-text"
            className={`
              text-link action-card-array-element-link-text
              ${(isActionCardActive ? ' is-active' : ' is-inactive')}
            `}
          >
            {linkText}
          </p>
          {getRightArrowIcon(isActionCardActive)}
        </div>
      </div>
    );
  });

  return (
    <div
      data-testid={`action-card${cardTypeAttribute}`}
      className={`action-card${cardTypeAttribute}`}
    >
      <div className="action-card-top-section">
        <div className="action-card-top-section-info">
          <h2
            data-testid="action-card-heading"
            className="title-2"
          >
            {headingText}
          </h2>
          <div className="action-card-summary">
            <p
              data-testid="action-card-summary-value"
              className={`
                data-1 action-card-summary-value
                ${(isActive ? ' is-active' : ' is-inactive')}
                ${(isPendingReferral ? ' is-pending-referral' : '')}
              `}
            >
              {summaryValue}
            </p>
            <p
              data-testid="action-card-summary-text"
              className="body-2 action-card-summary-text"
            >
              {summaryText}
            </p>
          </div>
        </div>
        <img src={src} alt={dataArray.key} width={width} height={height} />
      </div>
      <hr />
      <div className="action-card-array">
        {actionCardArrayElements}
      </div>
      <hr />
      <button
        data-testid="action-card-button"
        className={`
          text-link action-card-button
          ${(isActive ? ' is-active-button' : ' is-inactive-button')}
        `}
        disabled={!isActive}
        onClick={() => onClickHandlerActionCardButton()}
        type="button"
      >
        {buttonText}
      </button>
    </div>
  );
};

ActionCard.propTypes = {
  actionCardType: PropTypes.string.isRequired,
  onClickHandlerActionCardButton: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isPendingReferral: PropTypes.bool.isRequired,
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
  }).isRequired,
  headingText: PropTypes.string.isRequired,
  summaryValue: PropTypes.number.isRequired,
  summaryText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  dataArray: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      isPendingReferral: PropTypes.bool.isRequired,
      onClickLinkHandler: PropTypes.func.isRequired,
    }).isRequired,
  ).isRequired,
};

export default ActionCard;
