/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import common from 'gateway-common';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import './Attachment.scss';

const {
  svgs: {
    icons: {
      getFileIconByFileType,
    },
  },
} = common;

const AttachmentCard = ({
  dataTestID, label, url, fileType, createdAt, deleteable, onDelete,
}) => (
  <div className="attachment" data-testid={dataTestID}>
    <div className="file-icon">{getFileIconByFileType(fileType)}</div>
    <div className="attachment-text">
      <a
        className="body-1-bold attachment-link"
        data-testid={`${dataTestID}-link`}
        href={url}
        target="_blank"
      >
        {label}
      </a>
      {createdAt && <div className="body-2">{format(new Date(createdAt), 'MM-dd-yyyy')}</div>}
    </div>
    {deleteable && (
      <div className="delete-section">
        <button
          className="footnote delete-btn"
          data-testid={`${dataTestID}-delete-btn`}
          type="button"
          onClick={onDelete}
        >
          Delete
        </button>
      </div>
    )}
  </div>
);

export default AttachmentCard;

AttachmentCard.propTypes = {
  dataTestID: PropTypes.string,
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  deleteable: PropTypes.bool,
  onDelete: PropTypes.func,
};

AttachmentCard.defaultProps = {
  dataTestID: null,
  deleteable: false,
  onDelete: () => {},
};
