import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import PatientInfoPanel from '../../components/PatientInfoPanel';
import Breadcrumb from '../../components/Breadcrumb';
import ReferralContent from '../../components/ReferralContent';
import ReferralDetailPanel from '../../components/ReferralDetailPanel';
import './ReferralDetail.scss';

const ReferralDetail = ({
  patient,
  getReferral,
  fetchingReferral,
  resetFetchingReferral,
}) => {
  const { patientID, referralID } = useParams();
  const { firstName, lastName } = patient;

  useEffect(() => {
    getReferral(referralID);
    return () => resetFetchingReferral();
  }, [referralID]);

  return (
    <div className="referral-detail-container" data-testid="referral-detail-container">
      <div className="patient-info-panel-container">
        <PatientInfoPanel
          patientID={patientID}
        />
      </div>
      {(firstName && lastName) && (
        <div className="referral-detail" data-testid="referral-detail">
          <Breadcrumb
            patientFirstName={firstName}
            patientLastName={lastName}
            patientID={patientID}
            referralID={referralID}
          />
          <ReferralDetailPanel />
          {(fetchingReferral === false) && <ReferralContent />}
        </div>
      )}
    </div>
  );
};

ReferralDetail.propTypes = {
  patient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    sex: PropTypes.string,
    dob: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    language: PropTypes.string,
    ssn: PropTypes.string,
    personID: PropTypes.number,
  }),
  getReferral: PropTypes.func.isRequired,
  fetchingReferral: PropTypes.bool,
  resetFetchingReferral: PropTypes.func.isRequired,
};

ReferralDetail.defaultProps = {
  patient: {},
  fetchingReferral: null,
};

export default ReferralDetail;
