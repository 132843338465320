import { connect } from 'react-redux';
import ReferralContent from './ReferralContent';
import { openAddNeedLightBox } from '../../reducers/lightbox';
import { referralUpdated } from '../../reducers/referral';

const mapStateToProps = (state) => {
  const {
    referral: {
      referral: {
        noteCount,
      },
    },
  } = state;
  return {
    noteCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openAddNeedLightBox: () => dispatch(openAddNeedLightBox()),
  referralUpdated: () => dispatch(referralUpdated()),
});

const ReferralContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralContent);

export default ReferralContentContainer;
