import { connect } from 'react-redux';
import NeedStatusLightbox from './NeedStatusLightbox';
import { assignAdvisors, unassignAdvisors, referralUpdated } from '../../reducers/referral';
import { addNeedStatus } from '../../reducers/needTimeline';
import { closeLightBox } from '../../reducers/lightbox';
import AppDataService from '../../services/AppData';

const mapStateToProps = (state) => {
  const {
    referral: {
      referral: {
        assignedToAccountIDs,
        needs,
      },
    },
    needTimeline: {
      needTimeline: {
        timelineStatuses,
        needStatuses,
      },
      currentNeedID,
    },
    appData: {
      subStatuses,
      statuses,
      careAdvisors,
      governmentCategories,
      sdohCategories,
      applicationTypes,
    },
  } = state;

  const allCategories = [...governmentCategories, ...sdohCategories];

  const needStatus = needStatuses[needStatuses.length - 1];
  const currentStatus = statuses.find(({ id }) => id === needStatus.statusID);
  const statusOptions = statuses
    .map((statusOption) => {
      const isDisabledExternal = (
        currentStatus?.order > statusOption.order || 
        !timelineStatuses?.some(({ id }) => id === statusOption.id)
        );
      const isDisabledInternal = (!timelineStatuses?.some(({ id }) => id === statusOption.id));
      
      const isDisabled = ( currentStatus?.type === 'case_re_opened' || currentStatus?.type === 'follow_up_initiated' ? isDisabledInternal : isDisabledExternal )
      return {
        ...statusOption,
        isDisabled,
      };
    })
    .sort((status1, status2) => status1.order - status2.order);

  const subStatusOptions = subStatuses.map(({ subStatusID, subStatusLabel, subStatusType }) => ({
    value: subStatusID,
    label: subStatusLabel,
    type: subStatusType,
  }));

  const assignedAdvisors = assignedToAccountIDs.map((accountID) => ({
    label: AppDataService.getAccountName(accountID, careAdvisors),
    value: accountID,
  }));

  const needOptions = needs.map(({
    needID,
    needCategoryID,
    applicationTypeID,
    needTypeID,
    statusID,
  }) => {
    const label = AppDataService.getNeedLabel(
      needCategoryID,
      allCategories,
      applicationTypeID,
      applicationTypes,
    );

    const needOptionStatus = statuses.find(({ id }) => id === statusID);

    return {
      needID,
      label,
      needTypeID,
      statusID,
      statusOrder: needOptionStatus.order,
    };
  });

  const currentNeed = needOptions.find((need) => need.needID === currentNeedID);

  return {
    assignedAdvisors,
    statusOptions,
    currentStatus,
    subStatusOptions,
    needOptions,
    currentNeed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  assignAdvisors: (assignedToAccountIDs) => dispatch(assignAdvisors(assignedToAccountIDs)),
  unassignAdvisors: (assignedToAccountIDs) => dispatch(unassignAdvisors(assignedToAccountIDs)),
  addNeedStatus: (data) => dispatch(addNeedStatus(data)),
  closeLightBox: () => dispatch(closeLightBox()),
  referralUpdated: () => dispatch(referralUpdated()),
});

const NeedStatusLightboxContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NeedStatusLightbox);

export default NeedStatusLightboxContainer;
