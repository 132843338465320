import React, { useState } from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import EditLightBox from '../EditLightbox';
import { formatPhoneInput } from '../../utils';
import { TOTAL_CHARS_FORMATTED_PHONE_INPUT } from '../../constants';
import './EditHeadOfCaseLightBox.scss';

const {
  components: { Input, FormSelect, AddressFields },
} = common;

const EditHeadOfCaseLightBox = ({
  headOfCase: headOfCaseProp,
  relationshipTypes,
  closeLightBox,
  createHeadOfCase,
  updateHeadOfCase,
}) => {
  const [headOfCase, setHeadOfCase] = useState(headOfCaseProp);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
  });

  const relationshipOptions = relationshipTypes.map((relationshipType) => ({
    label: relationshipType.label,
    value: relationshipType.id,
  }));

  const selectedRelationshipOption = relationshipOptions.find((option) => (
    option.value === headOfCase?.relationship?.relationshipTypeID
  ));

  const updateErrors = () => {
    setErrors({
      firstName: !headOfCase.firstName,
      lastName: !headOfCase.lastName,
      phone: !headOfCase.phone,
    });
  };

  const changeHandler = (event) => {
    setHeadOfCase({
      ...headOfCase,
      [event.target.id]: event.target.value,
    });
    if (errors[event.target.id]) {
      setErrors({
        ...errors,
        [event.target.id]: event.target.value.length === 0,
      });
    }
  };

  const relationshipChangeHandler = (option) => {
    const { value } = option;
    setHeadOfCase({
      ...headOfCase,
      relationship: {
        ...headOfCase.relationship,
        relationshipTypeID: value,
      },
    });
  };

  const addressChangeHandler = (key, value) => {
    setHeadOfCase((prevHeadOfCase) => ({
      ...prevHeadOfCase,
      [key]: value,
    }));
  };

  const submitEditHeadOfCaseHandler = async () => {
    if (!(headOfCase.firstName && headOfCase.lastName && headOfCase.phone)) {
      updateErrors();
      return;
    }

    if (!headOfCase?.personID) {
      await createHeadOfCase(headOfCase);
    } else {
      await updateHeadOfCase(headOfCase);
    }

    closeLightBox();
  };

  return (
    <EditLightBox
      headerLabel="Current Head of Case"
      isOpen
      confirmButtonHandler={submitEditHeadOfCaseHandler}
      closeButtonHandler={closeLightBox}
      isConfirmDisabled={!(headOfCase.firstName && headOfCase.lastName && headOfCase.phone)}
    >
      <div className="edit-head-of-case-lightbox" data-testid="edit-head-of-case-lightbox">
        <div className="form-field">
          <FormSelect
            options={relationshipOptions}
            label="Relationship"
            selectedOption={selectedRelationshipOption}
            onChangeHandler={relationshipChangeHandler}
          />
        </div>
        <div className="form-field">
          <Input
            id="firstName"
            dataTestID="firstName"
            type="text"
            value={headOfCase.firstName || ''}
            label="First Name"
            handleOnChange={changeHandler}
            isRequiredField
            hasError={errors.firstName}
            errorMessage="First Name cannot be empty"
          />
        </div>
        <div className="form-field">
          <Input
            id="lastName"
            dataTestID="lastName"
            type="text"
            value={headOfCase.lastName || ''}
            handleOnChange={changeHandler}
            label="Last Name"
            isRequiredField
            hasError={errors.lastName}
            errorMessage="Last Name cannot be empty"
          />
        </div>
        <div className="form-field">
          <Input
            id="phone"
            dataTestID="phone"
            type="text"
            value={`${headOfCase.phone ? formatPhoneInput(headOfCase.phone) : ''}`}
            handleOnChange={changeHandler}
            label="Phone Number"
            isRequiredField
            hasError={errors.phone}
            errorMessage="Phone cannot be empty"
            maxLength={TOTAL_CHARS_FORMATTED_PHONE_INPUT}
          />
        </div>
        <div>
          <AddressFields
            placesAPIKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
            changeHandler={addressChangeHandler}
            address={headOfCase.address}
            addressLine2={headOfCase.addressLine2}
            city={headOfCase.city}
            county={headOfCase.county}
            state={headOfCase.state}
            zip={headOfCase.zip}
          />
        </div>
      </div>
    </EditLightBox>
  );
};

EditHeadOfCaseLightBox.propTypes = {
  headOfCase: PropTypes.shape({
    personID: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    county: PropTypes.string,
    relationship: PropTypes.shape({
      relationshipID: PropTypes.number,
      relationshipTypeID: PropTypes.number,
      relationshipType: PropTypes.string,
    }),
  }),
  relationshipTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  closeLightBox: PropTypes.func.isRequired,
  createHeadOfCase: PropTypes.func.isRequired,
  updateHeadOfCase: PropTypes.func.isRequired,
};

EditHeadOfCaseLightBox.defaultProps = {
  headOfCase: {},
  relationshipTypes: [],
};

export default EditHeadOfCaseLightBox;
