import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from '../Lightbox';
import './EditLightbox.scss';

const EditLightbox = ({
  isKeyValuePair,
  addKeyValueHandler,
  closeButtonHandler,
  confirmButtonHandler,
  isOpen,
  headerLabel,
  editButtonLabel,
  isConfirmDisabled,
  children,
  parentSelector,
}) => {
  const header = () => {
    let headerDisplay;
    if (isKeyValuePair) {
      headerDisplay = (
        <div className="key-value">
          <div className="title-2">{ headerLabel }</div>
          <button
            data-testid="add-key-value-btn"
            type="button"
            className="button-primary-small"
            onClick={addKeyValueHandler}
          >
            { editButtonLabel }
          </button>
        </div>
      );
    } else {
      headerDisplay = (
        <div>
          <div className="title-2">{ headerLabel }</div>
        </div>
      );
    }

    return headerDisplay;
  };

  const footer = () => (
    <div>
      <button
        data-testid="confirm-btn"
        type="button"
        className={`button-primary-small${isConfirmDisabled ? ' inactive' : ''}`}
        onClick={confirmButtonHandler}
      >
        Confirm
      </button>
      <div
        data-testid="cancel-btn"
        role="button"
        tabIndex={0}
        className="text-link"
        onClick={closeButtonHandler}
        onKeyPress={(e) => (e.code === 'Enter' ? closeButtonHandler() : null)}
      >
        Cancel
      </div>
    </div>
  );

  return (
    <div data-testid="edit-lightbox">
      <Lightbox
        closeButtonHandler={closeButtonHandler}
        isOpen={isOpen}
        className="edit-lightbox"
        parentSelector={parentSelector}
      >
        <div className="container">
          { header() }
          <div className="content">{ children }</div>
          { footer() }
        </div>
      </Lightbox>
    </div>
  );
};

EditLightbox.propTypes = {
  isKeyValuePair: PropTypes.bool,
  closeButtonHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  headerLabel: PropTypes.string.isRequired,
  editButtonLabel: PropTypes.string,
  confirmButtonHandler: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  addKeyValueHandler: PropTypes.func,
  isConfirmDisabled: PropTypes.bool,
  parentSelector: PropTypes.func,
};

EditLightbox.defaultProps = {
  isKeyValuePair: false,
  editButtonLabel: '+ Add New Value',
  addKeyValueHandler: () => {},
  isConfirmDisabled: false,
  parentSelector: undefined,
};

export default EditLightbox;
