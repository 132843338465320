const undoChange = (entityID, entityType, newValues) => {
  let type = '';
  switch (entityType) {
    case 'note':
      type = 'UNDO_NOTE_CHANGE';
      break;
    case 'attachment':
      type = 'UNDO_ATTACHMENT_CHANGE';
      break;
    case 'person':
      type = 'UNDO_PERSON_CHANGE';
      break;
    case 'patient':
      type = 'UNDO_PATIENT_CHANGE';
      break;
    default:
      return null;
  }
  return {
    type,
    entityID,
    newValues,
  };
};

module.exports = {
  undoChange,
};
