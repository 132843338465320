import React, { useState } from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import EditLightBox from '../EditLightbox';
import './AddNeedLightBox.scss';

const {
  components: { DropdownDrilldown, MultiSelect },
  svgs: { icons: { getCancelIcon } },
} = common;

const AddNeedLightBox = ({
  closeLightBox,
  governmentBenefitsOptions,
  sdohOptions,
  addNeeds,
}) => {
  const [selectedGovernmentBenefits, setSelectedGovernmentBenefits] = useState([]);
  const [selectedSdoh, setSelectedSdoh] = useState([]);

  const addGovernmentCategory = (categories) => {
    const addedCategories = categories.map((category) => ({
      ...category,
      categoryID: category.firstSelected.id,
      applicationTypeID: category.secondSelected.id,
    }));
    setSelectedGovernmentBenefits(addedCategories);
  };

  const removeGovernmentCategory = (categoryID) => {
    const remainingSelectedCategories = selectedGovernmentBenefits.filter(
      (category) => category.categoryID !== categoryID,
    );
    setSelectedGovernmentBenefits(remainingSelectedCategories);
  };

  const addSdohCategory = (category) => {
    setSelectedSdoh([...selectedSdoh, category]);
  };

  const removeSdohCategory = (categoryID) => {
    const remainingSelectedCategories = selectedSdoh.filter(({ id }) => id !== categoryID);
    setSelectedSdoh(remainingSelectedCategories);
  };

  const confirmButtonHandler = async () => {
    const addedGovernmentBenefitsNeeds = selectedGovernmentBenefits
      .map(({ categoryID, applicationTypeID }) => ({
        needCategoryID: categoryID, applicationTypeID,
      }));
    const addedSdohNeeds = selectedSdoh
      .map(({ id }) => ({ needCategoryID: id, applicationTypeID: null }));
    const allAddedNeeds = [
      ...addedGovernmentBenefitsNeeds,
      ...addedSdohNeeds,
    ];
    await addNeeds(allAddedNeeds);
    closeLightBox();
  };

  const governmentTags = selectedGovernmentBenefits.map((benefit) => ({
    key: benefit.firstSelected.id,
    remove: () => removeGovernmentCategory(benefit.firstSelected.id),
    label: `${benefit.firstSelected.label} ${benefit.secondSelected.label}`,
  }));

  const sdohTags = selectedSdoh.map((sdoh) => ({
    key: sdoh.id,
    remove: () => removeSdohCategory(sdoh.id),
    label: sdoh.label,
  }));

  const allTags = [...governmentTags, ...sdohTags];

  return (
    <div className="add-need-lightbox" data-testid="add-need-lightbox">
      <EditLightBox
        isOpen
        closeButtonHandler={closeLightBox}
        confirmButtonHandler={confirmButtonHandler}
        headerLabel="Add Need"
        isConfirmDisabled={selectedSdoh.length === 0 && selectedGovernmentBenefits.length === 0}
        parentSelector={() => document.querySelector('.add-need-lightbox')}
      >
        <div className="lightbox-content">
          <div className="dropdowns-container">
            <div className="government-categories">
              <h3 className="footnote">
                {selectedGovernmentBenefits.length > 0 && 'Government Resources'}
              &nbsp;
              </h3>
              <DropdownDrilldown
                menuItems={governmentBenefitsOptions}
                selectedItems={selectedGovernmentBenefits}
                addItem={addGovernmentCategory}
                removeItem={removeGovernmentCategory}
                placeholder="Government Resources"
                rootNodeID="root"
              />
            </div>
            <div className="sdoh-categories">
              <h3 className="footnote">
                {selectedSdoh.length > 0 && 'Community Resources'}
              &nbsp;
              </h3>
              {/* Should we use FormMultiSelect instead of MultiSelect? */}
              <MultiSelect
                name="sdoh-categories"
                options={sdohOptions}
                selected={selectedSdoh}
                label="Community Resources"
                removeOption={removeSdohCategory}
                addOption={addSdohCategory}
                rootNodeID="root"
              />
            </div>
          </div>
          <div className="tags-container">
            {allTags.map((tag) => (
              <div className="tag" key={tag.key}>
                <span
                  className="tag-label"
                  data-testid={`tag-${tag.key}`}
                >
                  {tag.label}
                </span>
                <button
                  className="remove-tag-btn"
                  type="button"
                  onClick={tag.remove}
                  data-testid={`tag-remove-btn-${tag.key}`}
                >
                  {getCancelIcon()}
                </button>
              </div>
            ))}
          </div>
        </div>
      </EditLightBox>
    </div>
  );
};

AddNeedLightBox.propTypes = {
  governmentBenefitsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.any,
      ),
    }),
  ),
  sdohOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  addNeeds: PropTypes.func.isRequired,
  closeLightBox: PropTypes.func.isRequired,
};

AddNeedLightBox.defaultProps = {
  governmentBenefitsOptions: [],
  sdohOptions: [],
};

export default AddNeedLightBox;
