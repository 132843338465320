import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Admin.scss';
import Tabs from '../../components/Tabs';
import UserManagement from '../../components/UserManagement';
import Pipelines from '../../components/Pipelines';

const Admin = () => {
  const history = useHistory();
  const path = history.location.pathname;
  let initialPath = 'pipeline';
  if (path.includes('user-management')) {
    initialPath = 'user-management';
  } else if (path.includes('pipeline')) {
    initialPath = 'pipeline';
  }

  const [selectedTab, setSelectedTab] = useState(initialPath);
  const setPathAndState = (selectedPath) => {
    history.push(`/admin/${selectedPath}`);
    setSelectedTab(selectedPath);
  };

  const tabContents = [
    {
      tabText: 'Pipelines and Sites',
      isSelected: selectedTab === 'pipeline',
      tabClickHandler: () => setPathAndState('pipeline'),
    },
    {
      tabText: 'User Management',
      isSelected: selectedTab === 'user-management',
      tabClickHandler: () => setPathAndState('user-management'),
    },
  ];
  return (
    <div className="admin-view">
      <div className="admin-navbar">
        <Tabs tabsContents={tabContents} />
      </div>
      {(selectedTab === 'pipeline') && <Pipelines />}
      {(selectedTab === 'user-management') && <UserManagement />}
    </div>
  );
};

export default Admin;
