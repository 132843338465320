import { connect } from 'react-redux';
import common from 'gateway-common';
import Referrals from './Referrals';
import { setAllReferralsFilters, fetchReferrals, resetAllReferrals } from '../../reducers/referrals';

const {
  constants: {
    REFERRAL_STATUS_ID_UNASSIGNED,
    REFERRAL_STATUS_ID_ONGOING,
    REFERRAL_STATUS_ID_PENDING,
    REFERRAL_STATUS_ID_CLOSED,
    REFERRAL_STATUS_ID_FOLLOW_UP,
  },
} = common;

const mapStateToProps = (state) => {
  const {
    appData: {
      pipelines,
      careAdvisors,
      urgencyTypes,
      sexTypes,
      statuses,
      referrerAccounts,
      governmentCategories,
      applicationTypes,
      sdohCategories,
    },
    referrals: {
      filterSettings: {
        pipelineID,
        referralStatusID,
        careAdvisorID,
        needStatusID,
        urgencyID,
        referrerID,
        sortBy,
        dateStart,
        dateEnd,
      },
      referrals: {
        unassigned,
        ongoing,
        pending,
        closed,
        followup,
      },
      totalClosedReferralsPages,
      fetchingReferrals,
    },
  } = state;

  const pipelineOptions = pipelines.map((pipeline) => ({
    val: pipeline.id,
    label: pipeline.label,
  }));
  const careAdvisorOptions = careAdvisors.map((careAdvisor) => ({
    val: careAdvisor.id,
    label: `${careAdvisor.firstName} ${careAdvisor.lastName}`,
  }));

  const referralStatusOptions = [
    { val: REFERRAL_STATUS_ID_UNASSIGNED, label: 'Unassigned', type: 'unassigned' },
    { val: REFERRAL_STATUS_ID_ONGOING, label: 'Ongoing', type: 'ongoing' },
    { val: REFERRAL_STATUS_ID_PENDING, label: 'Requires Update', type: 'pending' },
    { val: REFERRAL_STATUS_ID_CLOSED, label: 'Closed', type: 'closed' },
    { val: REFERRAL_STATUS_ID_FOLLOW_UP, label: 'Follow Up', type: 'followup' },
  ];

  const urgencyOptions = urgencyTypes.map(({ id, type }) => ({ val: id, label: type }));
  const needStatusOptions = statuses.map(({ id, label , isInternal, type}) => ( type === "case_closed" &&  isInternal ? {val: '', label:''  }: {val: id, label  } ));

  const referrerOptions = referrerAccounts.map((referrer) => ({
    val: referrer.referrerID,
    label: `${referrer.firstName} ${referrer.lastName}`,
  }));

  return {
    pipelineOptions,
    careAdvisorOptions,
    urgencyOptions,
    needStatusOptions,
    referralStatusOptions,
    referrerOptions,
    pipelineFilter: pipelineID,
    statusFilter: referralStatusID,
    careAdvisorFilter: careAdvisorID,
    needStatusFilter: needStatusID,
    urgencyFilter: urgencyID,
    referrerFilter: referrerID,
    sortBy,
    dateStart,
    dateEnd,
    sexTypes,
    unassigned,
    ongoing,
    pending,
    closed,
    followup,
    totalClosedReferralsPages,
    fetchingReferrals,
    governmentCategories,
    applicationTypes,
    sdohCategories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFilters: (filters) => dispatch(setAllReferralsFilters(filters)),
  resetAllReferrals: (filters) => dispatch(resetAllReferrals(filters)),
  fetchReferrals: (queryString) => dispatch(fetchReferrals(queryString)),
});

const ReferralsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Referrals);

export default ReferralsContainer;
