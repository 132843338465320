import { connect } from 'react-redux';
import UserManagement from './UserManagement';
import { openAccountCreationForm, openEditAccountModal,searchAdminData } from '../../reducers/lightbox';

const mapStateToProps = (state) => ({
  accounts: state.appData.allAccounts,
  sites: state.appData.sites,
  pipelines: state.appData.pipelines,
  appDataFetched: state.appData.appDataFetched,
});

const mapDispatchToProps = (dispatch) => ({
  openAccountCreationForm: (props) => dispatch(openAccountCreationForm(props)),
  openEditAccountModal: (account) => dispatch(openEditAccountModal(account)),
  searchAdminData:(inputData, pagePerData , pagenumber, sort, order, isReferrer) => dispatch(searchAdminData(inputData, pagePerData, pagenumber, sort, order, isReferrer )),
});

const UserManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserManagement);

export default UserManagementContainer;
