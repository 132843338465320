/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import common from "gateway-common";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import AssignAdvisorDropdown from "../AssignAdvisorDropdown";
import { formatISODate, formatPhoneInput } from "../../utils";
import { CLOSED_STATUS_TYPE } from "../../constants";
import Logo from "../../assets/logo-square.png";
import "./ReferralRow.scss";

const {
  components: { HorizontalTimeline, Select },
  svgs: {
    icons: { getPriorityNeedIcon },
  },
} = common;

const ReferralRow = ({
  dataTestID,
  referralID,
  fullName,
  dob,
  sex,
  email,
  phone,
  fullAddress,
  county,
  assignedToAccountIDs,
  needs,
  icon,
  urgency,
  createdAt,
  lastUpdated,
  priorityNeedID,
  careAdvisorOptions,
  referralStatus,
  linkToReferralDetail,
  referrers,
  updatePriorityNeed,
  assignAdvisors,
  archiveReferral,
  statuses,
  openConfirmArchiveModal,
}) => {
  const getNeedLabel = (need) =>
    `${need?.categoryLabel} ${need?.applicationType || ""}`;

  const priorityNeed = needs?.find((need) => need?.needID === priorityNeedID);
  const priorityNeedOptions = needs?.map((need) => {
    const label = getNeedLabel(need);
    const val = need?.needID;
    return { val, label };
  });

  const getAdvisorName = (id) => {
    const careAdvisor = careAdvisorOptions?.find((opt) => opt?.val === id);
    return careAdvisor?.label;
  };

  const priorityNeedChangeHandler = async (needID) => {
    await updatePriorityNeed(referralStatus, referralID, needID);
  };

  const advisorChangeHandler = async (accountIDsToAssign) => {
    await assignAdvisors(referralID, accountIDsToAssign);
  };

  const archiveBtnClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    openConfirmArchiveModal({
      archiveReferral: () => archiveReferral(referralStatus, referralID),
    });
  };

  const currentNeedStatuses = needs?.map(
    (need) => need?.needStatuses[need?.needStatuses?.length - 1]
  );
  const closedDate =
    referralStatus === "closed"
      ? new Date(
          Math.max(
            ...currentNeedStatuses?.map((status) => new Date(status?.createdAt))
          )
        )
      : null;
  return (
    <Link to={linkToReferralDetail} target="_blank" rel="noopener noreferrer">
      <div
        className="referral-row"
        data-testid={dataTestID}
        role="button"
        tabIndex="0"
      >
        <div className="left-section section">
          <div className="urgency-created-at">
            {urgency && (
              <div className={`tag-${urgency.toLowerCase()}`}>{urgency}</div>
            )}
            <div
              data-testid="referral-created-on"
              className="created-at body-2"
            >
              <div>{`Created on ${format(
                new Date(createdAt),
                "MM-dd-yyy"
              )}`}</div>
              <div>{`Last updated on ${format(
                new Date(lastUpdated),
                "MM-dd-yyy"
              )}`}</div>
            </div>
          </div>
          <div className="name-container title-2">{fullName}</div>
          {referralStatus === "pending" && (
            <div
              className="pending-message body-2"
              data-testid="pending-message"
            >
              This referral was last updated on
              <span>{` ${format(new Date(lastUpdated), "MM-dd-yyy")}`}</span>
            </div>
          )}
          {closedDate && (
            <div className="tag-container">
              <div className="tag-closed" data-testid="tag-closed">
                {`Closed ${format(closedDate, "MM-dd-yyy")}`}
              </div>
            </div>
          )}
          <div className="pipeline-icon">
            <img
              src={icon.src}
              alt="Pipeline Icon"
              width="49px"
              height="48px"
            />
          </div>
        </div>
        <div className="left-middle-section section">
          <p className="body-1 info-item" data-testid="patient-dob">
            {formatISODate(dob)}
          </p>
          {sex && (
            <p className="body-1 info-item" data-testid="patient-sex">
              {sex}
            </p>
          )}
          {email && (
            <p className="body-1 info-item" data-testid="patient-email">
              {email}
            </p>
          )}
          {phone && (
            <a
              href={`tel:${phone}`}
              className="phone-link"
              data-testid="tel-patient"
            >
              <p className="body-1 info-item" data-testid="patient-phone">
                {formatPhoneInput(phone)}
              </p>
            </a>
          )}
          {fullAddress && (
            <p className="body-1 info-item" data-testid="patient-address">
              {fullAddress}
            </p>
          )}
          {county && (
            <p
              className="body-1 info-item"
              data-testid="patient-county"
            >{`${county} County`}</p>
          )}
        </div>
        <div className="right-middle-section section">
          {needs?.map((need) => {
            const { needStatuses, needTypeID } = need;
            const currentStatus = needStatuses[needStatuses?.length - 1];
            const allNeedStatuses = statuses?.filter(({ needTypes }) =>
              needTypes?.includes(needTypeID)
            );
            const externalNeedStatuses = allNeedStatuses?.filter(
              ({ isInternal }) => !isInternal
            );
            const numPoints = currentStatus?.isInternal
              ? allNeedStatuses?.length
              : externalNeedStatuses?.length;
            return (
              <div className="timeline-container" key={need?.needID}>
                <div className="timeline-title">
                  <h2 className="timeline-category title-2">
                    {getNeedLabel(need)}
                  </h2>
                  {currentStatus?.statusType === CLOSED_STATUS_TYPE && (
                    <span className="body-2 closed-date">
                      {format(new Date(currentStatus?.createdAt), "M-d-yyy")}
                    </span>
                  )}
                </div>
                <HorizontalTimeline
                  dataTestID={`timeline-${need?.needID}`}
                  numPoints={numPoints}
                  numColoredPoints={currentStatus?.order}
                  isUrgent={urgency === "Urgent"}
                />
              </div>
            );
          })}
        </div>
        <div className="right-section section">
          {referrers?.map(({ referrerID, name }) => {
            return (
              <div
                key={referrerID}
                className="item-container"
                data-testid="referrer-container"
              >
                <div className="icon-container">
                  <img
                    src={icon.src}
                    alt="pipeline-img"
                    className="pipeline-img"
                  />
                </div>
                <span
                  className="body-1-bold"
                  data-testid={`referrer-${referrerID}`}
                >
                  {name}
                </span>
              </div>
            );
          })}
          {assignedToAccountIDs?.length >= 1 ? (
            assignedToAccountIDs?.map((accountID) => (
              <div className="item-container" key={accountID}>
                <div className="icon-container">
                  <img
                    src={Logo}
                    alt="care-advisors-logo"
                    className="care-advisors-logo"
                  />
                </div>
                <span
                  className="body-1-bold"
                  data-testid="assigned-care-advisor"
                >
                  {getAdvisorName(accountID)}
                </span>
              </div>
            ))
          ) : (
            <div className="item-container">
              <div className="icon-container">
                <img
                  src={Logo}
                  alt="care-advisors-logo"
                  className="care-advisors-logo"
                />
              </div>
              <div className="advisor-dropdown-container">
                <AssignAdvisorDropdown assignAdvisors={advisorChangeHandler} />
              </div>
            </div>
          )}
          {needs?.length > 1 && (
            <div className="item-container priority-need">
              <div className="icon-container">{getPriorityNeedIcon()}</div>
              {priorityNeed ? (
                <span className="body-1-bold" data-testid="priority-need">
                  {getNeedLabel(priorityNeed)}
                </span>
              ) : (
                <Select
                  dataTestID="priority-need-dropdown"
                  options={priorityNeedOptions}
                  value={null}
                  id="priority-need"
                  placeholder="Select a priority need"
                  onChange={priorityNeedChangeHandler}
                  selectContainerClassName="item-select-container"
                  selectValueContainerClassName="item-select-value-container"
                  menuClassName="item-select-menu"
                />
              )}
            </div>
          )}
          <div className="archive-btn-container">
            <div
              className="button-secondary-big-2 body-1-bold"
              role="button"
              tabIndex="0"
              onClick={archiveBtnClickHandler}
            >
              Archive
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ReferralRow;

ReferralRow.propTypes = {
  referralID: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  sex: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  fullAddress: PropTypes.string,
  county: PropTypes.string,
  assignedToAccountIDs: PropTypes.arrayOf(PropTypes.number),
  needs: PropTypes.arrayOf(
    PropTypes.shape({
      needID: PropTypes.number,
      categoryLabel: PropTypes.string,
      applicationType: PropTypes.string,
      needTypeID: PropTypes.number,
      needStatuses: PropTypes.arrayOf(
        PropTypes.shape({
          statusType: PropTypes.string,
          statusLabel: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
  priorityNeedID: PropTypes.number,
  updatePriorityNeed: PropTypes.func.isRequired,
  archiveReferral: PropTypes.func.isRequired,
  assignAdvisors: PropTypes.func.isRequired,
  careAdvisorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
  }).isRequired,
  urgency: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  referralStatus: PropTypes.string.isRequired,
  dataTestID: PropTypes.string,
  linkToReferralDetail: PropTypes.string.isRequired,
  referrers: PropTypes.arrayOf(
    PropTypes.shape({
      referrerID: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      isInternal: PropTypes.bool.isRequired,
    })
  ).isRequired,
  openConfirmArchiveModal: PropTypes.func.isRequired,
};

ReferralRow.defaultProps = {
  sex: "",
  email: "",
  phone: "",
  fullAddress: "",
  county: "",
  assignedToAccountIDs: [],
  priorityNeedID: null,
  dataTestID: null,
  referrers: [],
};
