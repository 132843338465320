import _ from 'lodash';
import {
  getReferral,
  updatePriorityNeedID,
  assignAdvisorsToReferral,
  unassignAdvisorsFromReferral,
  grantAccessToReferral,
  removeAccessToReferral,
  createNeeds,
  updateNeed,
  updateReferralSubStatusAndMessage,
} from '../services/endpoints';
import { notifySuccess, UPDATE_SUCCESS_MESSAGE } from '../services/Notifications';
import { getNeedTimelineByID } from './needTimeline';

export const UPDATE_REFERRAL = 'UPDATE_REFERRAL';
export const SET_REFERRAL = 'SET_REFERRAL';
export const FETCHING_REFERRAL = 'FETCHING_REFERRAL';
export const SET_REFERRERS = 'SET_REFERRERS';
const RESET_FETCHING_REFERRAL = 'RESET_FETCHING_REFERRAL';
export const SET_EDIT_STATUS_OPEN = 'SET_EDIT_STATUS_OPEN';

export const setReferral = (referral) => ({
  type: SET_REFERRAL,
  referral,
});

const fetchingReferral = () => ({
  type: FETCHING_REFERRAL,
});

export const updateReferral = (valuesToUpdate) => ({
  type: UPDATE_REFERRAL,
  valuesToUpdate,
});

export const setEditStatusOpen = (flag) => ({
  type: SET_EDIT_STATUS_OPEN,
  flag
});

export const referralUpdated = () => (
  (dispatch) => {
    dispatch(updateReferral({
      lastUpdated: new Date().toDateString(),
    }));
  }
);

export const resetFetchingReferral = () => ({
  type: RESET_FETCHING_REFERRAL,
});

export const fetchReferral = (referralID) => (
  (dispatch) => {
    dispatch(fetchingReferral());
    getReferral(referralID)
      .then((referral) => dispatch(setReferral(referral)));
  }
);

export const updatePriorityNeed = (priorityNeedID) => (
  async (dispatch, getState) => {
    const state = getState();
    const { referral: { referral: { referralID } } } = state;
    await updatePriorityNeedID(referralID, priorityNeedID);
    dispatch(updateReferral({ priorityNeedID }));
  }
);

/**
 * Function to trigger the api call to update sub status and message
 */
export const updateSubStatusAndMessage = (payload) => {
  return async (dispatch) => {
    await updateReferralSubStatusAndMessage(payload);
    dispatch(getNeedTimelineByID(payload?.currentNeedID));
    notifySuccess(UPDATE_SUCCESS_MESSAGE);
    dispatch(setEditStatusOpen(false));
  }
};

export const assignAdvisors = (accountIDsToAssign) => (
  async (dispatch, getState) => {
    const state = getState();
    const { referral: { referral: { referralID, assignedToAccountIDs } } } = state;
    await assignAdvisorsToReferral(accountIDsToAssign, referralID);
    dispatch(updateReferral({
      assignedToAccountIDs: [
        ...assignedToAccountIDs,
        ...accountIDsToAssign,
      ],
      lastUpdated: new Date().toDateString(),
    }));
  }
);

export const unassignAdvisors = (accountIDsToUnassign) => (
  async (dispatch, getState) => {
    const state = getState();
    const { referral: { referral: { referralID, assignedToAccountIDs } } } = state;
    await unassignAdvisorsFromReferral(accountIDsToUnassign, referralID);
    const updatedReferral = {
      assignedToAccountIDs: assignedToAccountIDs.filter(
        (accountID) => !accountIDsToUnassign.includes(accountID),
      ),
    };
    dispatch(updateReferral(updatedReferral));
  }
);

const setReferrers = (referrers) => ({
  type: SET_REFERRERS,
  referrers,
});

export const updateReferrers = (updatedReferrers, referralID) => (
  async (dispatch, getState) => {
    const { referral: { referral: { referrers } } } = getState();
    const isReferrerEqual = (referrer1, referrer2) => (
      !!(
        referrer1.accountID
        && referrer2.accountID
        && referrer1.accountID === referrer2.accountID
      ));
    const referrersToAdd = _.differenceWith(updatedReferrers, referrers, isReferrerEqual);
    const referrersToAddAccountIDs = referrersToAdd.map(({ accountID }) => accountID);
    if (referrersToAddAccountIDs.length > 0) {
      await grantAccessToReferral(referrersToAddAccountIDs, referralID);
    }
    const referrersToRemove = _.differenceWith(referrers, updatedReferrers, isReferrerEqual);
    const referrersToRemoveAccountIDs = referrersToRemove.map(({ accountID }) => accountID);
    if (referrersToRemoveAccountIDs.length > 0) {
      await removeAccessToReferral(referrersToRemoveAccountIDs, referralID);
    }
    dispatch(setReferrers(updatedReferrers));
  }
);

export const updateNeedBillingCode = (updatedNeedID, billingCodeID) => (
  async (dispatch, getState) => {
    await updateNeed(updatedNeedID, { billingCodeID });
    const { referral: { referral: { needs } } } = getState();
    const needIndex = needs.findIndex(({ needID }) => needID === updatedNeedID);
    const updatedNeeds = needs.slice();
    const needToUpdate = updatedNeeds.splice(needIndex, 1)[0];
    const updatedNeed = {
      ...needToUpdate,
      billingCodeID,
    };
    updatedNeeds.splice(needIndex, 0, updatedNeed);
    dispatch(updateReferral({ needs: updatedNeeds }));
  }
);

export const addNeeds = (newNeeds) => (
  async (dispatch, getState) => {
    const { referral: { referral: { referralID, needs } } } = getState();
    const addedNeeds = await createNeeds(referralID, newNeeds);
    const updatedNeeds = [
      ...needs,
      ...addedNeeds,
    ];
    dispatch(updateReferral({ needs: updatedNeeds }));
  }
);

const ACTION_HANDLERS = {
  [RESET_FETCHING_REFERRAL]: (state) => ({
    ...state,
    fetchingReferral: null,
  }),
  [SET_REFERRAL]: (state, action) => ({
    ...state,
    referral: action.referral,
    fetchingReferral: false,
  }),
  [UPDATE_REFERRAL]: (state, action) => ({
    ...state,
    referral: {
      ...state.referral,
      ...action.valuesToUpdate,
    },
  }),
  [SET_EDIT_STATUS_OPEN]: (state, action) => ({
    ...state,
    referral: {
      ...state.referral,
      ...state.valuesToUpdate,
    },
    referralUpdatePopup: action.flag,
  }),
  [FETCHING_REFERRAL]: (state) => ({
    ...state,
    fetchingReferral: true,
  }),
  [SET_REFERRERS]: (state, action) => ({
    ...state,
    referral: {
      ...state.referral,
      referrers: action.referrers,
    },
  }),
};

const initialState = {
  fetchingReferral: null,
  referral: {
    referrers: [],
    needs: [],
  },
  referralUpdatePopup: false,
};

export default function referralReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
