import React from 'react';
import common from 'gateway-common';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Lightbox from '../Lightbox';
import './EditStatusAndMessageLightbox.scss';
import { needsStatusTypes } from '../../constants';
import { needStatusToSubStatusMapping } from '../../utils';

const { components: { FormSelect, RadioButton } } = common;
const EditStatusAndMessageLightbox = ({
  closeButtonHandler,
  confirmButtonHandler,
  referralUpdatePopup,
  isConfirmDisabled,
  parentSelector,
  setEditStatusOpen,
  editStatusOptions,
  editStatusOpenCallback,
}) => {

  /**
   * Accessing global state value using react hook useSelector().
   * Structuring a object to pass the FormSelect component.
   */
  const masterSubStatus = useSelector((state)=> state.appData?.subStatuses);
  const selectedRefrralStatus = useSelector((state)=> state.appData?.selectedRefrralStatus);

  const subStatusOptions = masterSubStatus?.map(({ subStatusID, subStatusLabel, subStatusType }) => ({
    value: subStatusID,
    label: subStatusLabel,
    type: subStatusType,
  }));

  /**
   * Based in status filtering the substatus
   */
  const subStatusTypesOfStatus = needStatusToSubStatusMapping(selectedRefrralStatus?.type);
  const filteredSubStatusOptions = subStatusOptions?.filter((option) => subStatusTypesOfStatus?.includes(option.type));
  
  const footer = () => (
    <div>
      <button
        data-testid="confirm-btn"
        type="button"
        className={`button-primary-small${isConfirmDisabled ? ' inactive' : ''}`}
        onClick={confirmButtonHandler}
      >
        Save
      </button>
      <div
        data-testid="cancel-btn"
        role="button"
        tabIndex={0}
        className="text-link"
        onClick={()=>setEditStatusOpen(false)}
        onKeyPress={(e) => (e.code === 'Enter' ? closeButtonHandler() : null)}
      >
        Cancel
      </div>
    </div>
  );

  return (
    <div data-testid="status-edit-lightbox">
      <Lightbox
        closeButtonHandler={()=>setEditStatusOpen(false)}
        isOpen={referralUpdatePopup}
        className="status-edit-lightbox"
        parentSelector={parentSelector}
      >
        <div className="container">          
          <div className="content">
            <p className="section-label">Sub Status</p>
          <div className="status-dropdown">
            <FormSelect
              id="sub-status-select"
              options={filteredSubStatusOptions}
              selectedOption={editStatusOptions?.subStatus}
              onChangeHandler={(valObj) => editStatusOpenCallback(valObj, editStatusOptions?.message, editStatusOptions?.messageId, editStatusOptions.currentStatusId)}
              placeholder="Select a Sub Status..."
            />
          </div>
          <p className="section-label">Public Status Message</p>
          <textarea
            placeholder="Add a message to this status change here..."
            onChange={(e) => editStatusOpenCallback(editStatusOptions?.subStatus, e.target.value, editStatusOptions?.messageId, editStatusOptions.currentStatusId)}
            value={editStatusOptions?.message}
          /></div>
          { footer() }
        </div>
      </Lightbox>
    </div>
  );
};

EditStatusAndMessageLightbox.propTypes = {
  closeButtonHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  headerLabel: PropTypes.string.isRequired,
  editButtonLabel: PropTypes.string,
  confirmButtonHandler: PropTypes.func.isRequired,
  isConfirmDisabled: PropTypes.bool,
  parentSelector: PropTypes.func,
};

EditStatusAndMessageLightbox.defaultProps = {
  isKeyValuePair: false,
  editButtonLabel: '+ Add New Value',
  addKeyValueHandler: () => {},
  isConfirmDisabled: false,
  parentSelector: undefined,
};

export default EditStatusAndMessageLightbox;
