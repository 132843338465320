import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Lightbox from '../Lightbox';
import './DeleteStatusLightbox.scss';

const DeleteStatusLightbox= ({
    deleteSubStatusMessage,
    isConfirmDisabled,
    closeButtonHandler,
    parentSelector,
    deleteStatusOpen,
    setDeleteStatusOpen,
    deletemessage,
    needIDData,
    closeDeletePopup,
    closeDeleteMessagePopup,
}) =>{

    const reload = (deletemessage,needIDData) =>{
      deleteSubStatusMessage(deletemessage,needIDData)
    }
    const footer = () => (
        <div>
          <button
            data-testid="confirm-btn"
            type="button"
            className={`button-primary-small${isConfirmDisabled ? ' inactive' : ''}`}
            onClick={() =>reload(deletemessage,needIDData) }
          >
            Confirm
          </button>
          <div
            data-testid="cancel-btn"
            role="button"
            tabIndex={0}
            className="text-link"
            onClick={()=>closeDeletePopup(false)}
            onKeyPress={(e) => (e.code === 'Enter' ? closeButtonHandler() : null)}
          >
            Cancel
          </div>
        </div>
      );
      return (
        <div data-testid="status-delete-lightbox">
          <Lightbox
            closeButtonHandler={()=>closeDeletePopup(false)}
            isOpen={closeDeleteMessagePopup}
            className="status-delete-lightbox"
            parentSelector={parentSelector}
          >
            <div className="container">
                      <div className="content">
                          <p className="section-label">Are you sure you want to delete this message?</p>
                      </div>
                      {footer()}
                  </div>
          </Lightbox>
        </div>
      );

};
DeleteStatusLightbox.propTypes = {
    deleteSubStatusMessage : PropTypes.func.isRequired,
    editButtonLabel: PropTypes.string,
    deleteStatusOpen: PropTypes.bool,
    setDeleteStatusOpen: PropTypes.func.isRequired,
    deletemessage: PropTypes.array,
    parentSelector: PropTypes.func,
    closeButtonHandler: PropTypes.func.isRequired,
  };

DeleteStatusLightbox.defaultProps ={
  deleteStatusOpen:false,
  deleteSubStatusMessage : () =>void(0),
  setDeleteStatusOpen: () =>void(0),
  parentSelector :undefined,
  closeButtonHandler:()=>void(0)
}

export default DeleteStatusLightbox;