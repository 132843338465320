/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';

const EditableText = ({
  text,
  isEditing,
  onChange,
  onKeyPress,
  onBlur,
  textClassName,
  inputClassName,
  dataTestID,
}) => (
  <div className="editable-text input-1" data-testid={dataTestID}>
    {isEditing
      ? (
        <input
          className={inputClassName}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          value={text}
          autoFocus
          data-testid={`${dataTestID}-input`}
        />
      )
      : (
        <div className={textClassName} data-testid={`${dataTestID}-text`}>
          {text}
        </div>
      )}
  </div>
);

EditableText.propTypes = {
  text: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  textClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  dataTestID: PropTypes.string,
};

EditableText.defaultProps = {
  onKeyPress: () => {},
  onBlur: () => {},
  textClassName: null,
  inputClassName: null,
  dataTestID: null,
};

export default EditableText;
