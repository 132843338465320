import { connect } from 'react-redux';
import ParentPage from './ParentPage';
import { setAppData } from '../../reducers/app';

const mapStateToProps = (state) => {
  const {
    newReferral: { isOpen },
    drafts: { triggerDrafts },
    lightBox: { lightBox, props },
  } = state;
  return {
    newReferralWizardIsOpen: isOpen,
    triggerDrafts,
    lightBox,
    lightBoxProps: props,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAppData: (appData) => dispatch(setAppData(appData)),
});

const ParentPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParentPage);

export default ParentPageContainer;
