import { connect } from 'react-redux';
import AssignAdvisorDropdown from './AssignAdvisorDropdown';

const mapStateToProps = (state) => {
  const {
    appData: {
      careAdvisors,
    },
  } = state;

  const advisorOptions = careAdvisors?.map((careAdvisor) => ({
    value: careAdvisor.id,
    label: `${careAdvisor.firstName} ${careAdvisor.lastName}`,
  }));

  return {
    advisorOptions,
  };
};

const AssignAdvisorDropdownContainer = connect(
  mapStateToProps,
  null,
)(AssignAdvisorDropdown);

export default AssignAdvisorDropdownContainer;
