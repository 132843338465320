// constants
export const SET_PATIENT_INFO = 'SET_PATIENT_INFO';
export const EDIT_PATIENT_INFO = 'EDIT_PATIENT_INFO';
export const UNDO_PATIENT_CHANGE = 'UNDO_PATIENT_CHANGE';
export const ADD_CUSTOM_ATTRIBUTE = 'ADD_CUSTOM_ATTRIBUTE';
export const EDIT_CUSTOM_ATTRIBUTE = 'EDIT_CUSTOM_ATTRIBUTE';
export const DELETE_CUSTOM_ATTRIBUTE = 'DELETE_CUSTOM_ATTRIBUTE';
export const SET_MEDI_RESULTS = 'SET_MEDI_RESULTS';
export const UPDATE_PATIENT_INFO = 'UPDATE_PATIENT_INFO';

// actions
export const setMediResults = (mediResults) => ({
  type: SET_MEDI_RESULTS,
  mediResults,
});

export const setPatientInfo = (patientInfo) => ({
  type: SET_PATIENT_INFO,
  patientInfo,
});

export const editPatientInfo = (key, val) => ({
  type: EDIT_PATIENT_INFO,
  key,
  val,
});

export const addCustomAttribute = (key, val) => ({
  type: ADD_CUSTOM_ATTRIBUTE,
  key,
  val,
});

export const editCustomAttribute = (key, val) => ({
  type: EDIT_CUSTOM_ATTRIBUTE,
  key,
  val,
});

export const deleteCustomAttribute = (key) => ({
  type: DELETE_CUSTOM_ATTRIBUTE,
  key,
});

export const updatePatientInfo = (updatedAttributes) => ({
  type: UPDATE_PATIENT_INFO,
  updatedAttributes,
});

// action handlers
const ACTION_HANDLERS = {
  [SET_MEDI_RESULTS]: (state, action) => ({
    ...state,
    patientInfo: {
      ...state.patientInfo,
      mediResults: action.mediResults,
    },
  }),
  [SET_PATIENT_INFO]: (state, action) => ({
    ...state,
    patientInfo: action.patientInfo,
  }),
  [EDIT_PATIENT_INFO]: (state, action) => ({
    ...state,
    patientInfo: {
      ...state.patientInfo,
      [action.key]: action.val,
    },
  }),
  [UNDO_PATIENT_CHANGE]: (state, action) => (
    'custom_attributes' in action.newValues ? ({
      ...state.patientInfo,
      patientInfo: {
        ...state.patientInfo,
        customAttributes: Object.keys(action.newValues.custom_attributes).length ? ({
          ...state.patientInfo.customAttributes,
          ...action.newValues.custom_attributes,
        }) : {},
      },
    }) : ({
      ...state,
      patientInfo: {
        ...state.patientInfo,
        ...action.newValues,
      },
    })
  ),
  [ADD_CUSTOM_ATTRIBUTE]: (state, action) => ({
    ...state,
    patientInfo: {
      ...state.patientInfo,
      customAttributes: {
        ...state.patientInfo.customAttributes,
        [action.key]: action.val,
      },
    },
  }),
  [EDIT_CUSTOM_ATTRIBUTE]: (state, action) => ({
    ...state,
    patientInfo: {
      ...state.patientInfo,
      customAttributes: {
        ...state.patientInfo.customAttributes,
        [action.key]: action.val,
      },
    },
  }),
  [DELETE_CUSTOM_ATTRIBUTE]: (state, action) => {
    const { patientInfo: { customAttributes: { [action.key]: val, ...customAttributes } } } = state;
    return {
      ...state,
      patientInfo: {
        ...state.patientInfo,
        customAttributes,
      },
    };
  },
  [UPDATE_PATIENT_INFO]: (state, action) => ({
    ...state,
    patientInfo: {
      ...state.patientInfo,
      ...action.updatedAttributes,
    },
  }),
};

const initialState = {
  patientInfo: {},
};

export default function patientReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
