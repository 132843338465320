import { connect } from 'react-redux';
import EditHeadOfCaseLightBox from './EditHeadOfCaseLightBox';
import { closeLightBox } from '../../reducers/lightbox';
import {
  createHeadOfCase,
  updateHeadOfCase,
} from '../../reducers/patientInfo';

const mapStateToProps = (state) => {
  const {
    appData: { relationshipTypes },
    patientInfo: { headOfCase },
  } = state;
  return {
    headOfCase,
    relationshipTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeLightBox: () => dispatch(closeLightBox()),
  createHeadOfCase: (headOfCase) => dispatch(createHeadOfCase(headOfCase)),
  updateHeadOfCase: (headOfCase) => dispatch(updateHeadOfCase(headOfCase)),
});

const EditHeadOfCaseLightBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditHeadOfCaseLightBox);

export default EditHeadOfCaseLightBoxContainer;
