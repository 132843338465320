export const SET_TRIGGER_DRAFTS = 'SET_TRIGGER_DRAFTS';
export const SET_DRAFT = 'SET_DRAFT';

export const setTriggerDrafts = (val) => ({
  type: SET_TRIGGER_DRAFTS,
  triggerDrafts: val,
});

export const setDraft = (draft) => ({
  type: SET_DRAFT,
  draft,
});

const ACTION_HANDLERS = {
  [SET_TRIGGER_DRAFTS]: (state, action) => ({
    ...state,
    triggerDrafts: action.triggerDrafts,
  }),
  [SET_DRAFT]: (state, action) => ({
    ...state,
    draft: action.draft,
  }),
};

const initialState = {
  triggerDrafts: false,
  draft: {},
};

export default function draftsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
