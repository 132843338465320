import { CLOSED_STATUS_TYPE } from '../constants';

const getRelationshipTypeLabel = (relationshipTypeID, relationshipTypes) => {
  const relationshipType = relationshipTypes?.find((rt) => rt.id === relationshipTypeID);
  return relationshipType?.label;
};

const getSexTypeLabel = (sexTypeID, sexTypes) => {
  const sexType = sexTypes?.find((s) => s.id === sexTypeID);
  return sexType?.label;
};

const getNeedLabel = (needCategoryID, needCategories, applicationTypeID, applicationTypes) => {
  const foundNeedCategory = needCategories.find(
    (needCategory) => needCategory.id === needCategoryID,
  );
  const foundApplicationType = applicationTypes.find(
    (applicationType) => applicationType.id === applicationTypeID,
  );
  const needCategoryLabel = foundNeedCategory ? foundNeedCategory.label : '';
  const applicationTypeLabel = foundApplicationType ? foundApplicationType.label : '';
  return `${needCategoryLabel}${applicationTypeLabel ? ` ${applicationTypeLabel}` : ''}`;
};

const getNeedCategoryType = (needCategoryID, needCategories) => {
  const category = needCategories.find((cat) => cat.id === needCategoryID);
  return category?.type;
};

const getAccountName = (accountID, accounts) => {
  const account = accounts?.find(({ id }) => id === accountID);
  return account ? `${account.firstName} ${account.lastName}` : null;
};

const getUrgencyType = (urgencyTypeID, urgencyTypes) => {
  const urgencyType = urgencyTypes?.find((ut) => ut.id === urgencyTypeID);
  return urgencyType?.type;
};

const isUrgent = (urgencyTypeID, urgencyTypes) => {
  const urgencyType = getUrgencyType(urgencyTypeID, urgencyTypes);
  return urgencyType === 'Urgent';
};

const isNeedClosed = (statusID, statuses) => {
  const status = statuses.find(({ id }) => id === statusID);
  const statusType = status?.type ? status.type : null;
  return statusType === CLOSED_STATUS_TYPE;
};

const AppDataService = {
  getRelationshipTypeLabel,
  getSexTypeLabel,
  getNeedLabel,
  getNeedCategoryType,
  getAccountName,
  getUrgencyType,
  isUrgent,
  isNeedClosed,
};

export default AppDataService;
