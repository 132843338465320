/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { getDrafts, removeDraft } from '../../services/LocalStorage';
import { getPencilIcon, getCancelIcon } from '../../svgs';
import ErrorBoundary from '../../components/ErrorBoundary';
import './CaseDrafts.scss';

class CaseDrafts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drafts: getDrafts(),
      pageNo: 1,
      displayDropdown: false,
      sortBy: null,
    };
    this.draftsPerPage = 5;
    this.dropDown = React.createRef();
  }

  deleteDraft = (event, draftID) => {
    event.preventDefault();
    const updatedDrafts = removeDraft(draftID);
    this.setState({
      drafts: updatedDrafts,
    });
  }

  editDraft = async (event, draft) => {
    event.preventDefault();
    const { setTriggerDrafts, setDraft, openNewReferralWizard } = this.props;
    await setTriggerDrafts(false);
    await setDraft(draft);
    await openNewReferralWizard();
  }

  displayDropdown = async (displayDropdown) => {
    await this.setState({ displayDropdown });
    if (displayDropdown) this.dropDown.focus();
  }

  selectSortOption = (event, sortBy) => {
    event.preventDefault();
    this.setState({ sortBy });
  }

  sortByDate = (a, b) => new Date(a.dateSaved) - new Date(b.dateSaved)

  sortByLastName = (a, b) => {
    const { patient: { lastName: lastNameA } } = a;
    const { patient: { lastName: lastNameB } } = b;
    if (!(lastNameA || lastNameB)) return 0;
    if (!lastNameA && !!lastNameB) return 1;
    if (!!lastNameA && !lastNameB) return -1;
    if (lastNameA.toLowerCase() < lastNameB.toLowerCase()) return -1;
    if (lastNameA.toLowerCase() > lastNameB.toLowerCase()) return 1;
    return 0;
  }

  getSortFunction = (sortBy) => {
    switch (sortBy) {
      case 'lastName': return (drafts) => drafts.sort(this.sortByLastName);
      case 'dateSaved': return (drafts) => drafts.sort(this.sortByDate);
      default: return (drafts) => drafts;
    }
  }

  setPageNo = (pageNo) => {
    this.setState({ pageNo });
  }

  close = (event) => {
    event.preventDefault();
    const { setTriggerDrafts } = this.props;
    setTriggerDrafts(false);
  }

  render() {
    const {
      drafts, pageNo, displayDropdown, sortBy,
    } = this.state;
    const sort = this.getSortFunction(sortBy);
    const start = (pageNo - 1) * this.draftsPerPage;
    const stop = start + this.draftsPerPage;
    const lastPageNo = Math.ceil(drafts.length / this.draftsPerPage) || 1;
    return (
      <div className="dark-background-container">
        <div
          className="drafts-main-container"
          data-testid="drafts-main-container"
        >
          <button
            className="close-window-btn"
            type="button"
            onClick={(event) => this.close(event)}
          >
            {getCancelIcon()}
          </button>
          <div className="header-container">
            <h3>Saved Drafts</h3>
            <div className="sort-container">
              <button
                type="button"
                onClick={(event) => this.displayDropdown(event)}
              >
                Sort
              </button>
              {displayDropdown && (
                <div
                  className="dropdown-container"
                  ref={(div) => { this.dropDown = div; }}
                  role="menu"
                  tabIndex="0"
                  onBlur={() => this.displayDropdown(false)}
                >
                  <div
                    className="dropdown-option"
                    data-testid="dropdown-sort-last-name"
                    role="button"
                    tabIndex="0"
                    onClick={(event) => this.selectSortOption(event, 'lastName')}
                    onMouseDown={(event) => { event.preventDefault(); }}
                  >
                    Last Name
                  </div>
                  <div
                    className="dropdown-option"
                    data-testid="dropdown-sort-date-saved"
                    role="button"
                    tabIndex="0"
                    onClick={(event) => this.selectSortOption(event, 'dateSaved')}
                    onMouseDown={(event) => { event.preventDefault(); }}
                  >
                    Date Saved
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="drafts-table-container">
            <div className="table-header table-row">
              <div className="col-1 column">Patient Name</div>
              <div className="col-2 column">Date Saved</div>
              <div className="col-3 column">Action</div>
            </div>
            <div className="table-body">
              {sort(drafts).slice(start, stop).map((d) => (
                <div
                  className="table-row"
                  key={`draft-${d.draftID}`}
                  data-testid="table-row-draft"
                >
                  <div
                    className="col-1 column"
                    data-testid="table-row-draft-patient-name"
                  >
                    {(d.patient.firstName || d.patient.lastName) ? `${d.patient.lastName}, ${d.patient.firstName}` : 'N/A'}
                  </div>
                  <div
                    className="col-2 column"
                    data-testid="table-row-draft-date-saved"
                  >
                    {format(parseISO(d.dateSaved), 'MM / dd / yyyy')}
                  </div>
                  <div className="col-3 column">
                    <button
                      className="edit-btn"
                      data-testid="edit-btn-draft"
                      type="button"
                      onClick={(event) => this.editDraft(event, d)}
                    >
                      {getPencilIcon()}
                      Edit
                    </button>
                    <button
                      className="delete-btn"
                      type="button"
                      onClick={(event) => this.deleteDraft(event, d.draftID)}
                      data-testid="delete-btn"
                    >
                      {getCancelIcon()}
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="bottom-nav-container">
            <div className="page-controls-container">
              <button
                type="button"
                disabled={pageNo === 1}
                onClick={() => this.setPageNo(pageNo - 1)}
              >
                <i className="fas fa-chevron-left" />
              </button>
              {`${pageNo} of ${lastPageNo}`}
              <button
                type="button"
                disabled={pageNo === lastPageNo}
                onClick={() => this.setPageNo(pageNo + 1)}
              >
                <i className="fas fa-chevron-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CaseDrafts.propTypes = {
  setTriggerDrafts: PropTypes.func.isRequired,
  openNewReferralWizard: PropTypes.func.isRequired,
  setDraft: PropTypes.func.isRequired,
};

const wrapWithErrorBoundary = (props) => (
  <ErrorBoundary>
    <CaseDrafts {...props} />
  </ErrorBoundary>
);

export default wrapWithErrorBoundary;
