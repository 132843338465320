import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import common from 'gateway-common';
import './Lightbox.scss';

const { svgs: { icons: { getCancelIcon } } } = common;

const Lightbox = ({
  isOpen,
  closeButtonHandler,
  children,
  className,
  parentSelector,
}) => {
  if (isOpen) {
    return (
      <Modal
        isOpen={isOpen}
        className={`lightbox ${className.length && className}`}
        overlayClassName="overlay"
        ariaHideApp={false}
        parentSelector={parentSelector}
      >
        <div data-testid="lightbox">
          <button
            data-testid="close-btn"
            type="button"
            className="btn-close"
            onClick={closeButtonHandler}
          >
            {getCancelIcon()}
          </button>
          { children }
        </div>
      </Modal>
    );
  }
  return null;
};

Lightbox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  closeButtonHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  parentSelector: PropTypes.func,
};

Lightbox.defaultProps = {
  className: '',
  parentSelector: () => document.querySelector('#gateway-platform'),
};

export default Lightbox;
