/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { isLoggedIn } from '../../services/endpoints';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      authenticated: false,
    };
  }

  componentDidMount() {
    this.checkIfLoggedIn();
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname: newPath } } = this.props;
    const { location: { pathname: oldPath } } = prevProps;
    if (newPath !== oldPath) {
      this.checkIfLoggedIn();
    }
  }

  checkIfLoggedIn = async () => {
    const authenticated = await isLoggedIn();
    if (!authenticated) {
      const { history, location: { pathname } } = this.props;
      history.push({ pathname: '/', state: { from: pathname } });
    } else {
      this.setState({ authenticated, loaded: true });
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, authenticated } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={(props) => (
          authenticated
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/', state: { from: props.location.pathname } }} />
        )}
      />
    );
  }
}

export default withRouter(PrivateRoute);

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

PrivateRoute.defaultProps = {
  location: {},
  history: {
    push: () => {},
  },
};
