import React, { useState } from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import EditLightBox from '../EditLightbox';
import * as endpoints from '../../services/endpoints';
import './EditCustomAttributesLightBox.scss';

const { components: { Input } } = common;

const EditCustomAttributesLightBox = ({
  patientID,
  customAttributes: customAttributesProp,
  editCustomAttributes,
  closeLightBox,
}) => {
  const [customAttributes, setCustomAttributes] = useState(customAttributesProp);
  const [errorFields, setErrorFields] = useState(
    Array.from({ length: customAttributesProp.length }, () => ({ key: false, value: false })),
  );
  const [attributesToDelete, setAttributesToDelete] = useState([]);

  const errorChangeHandler = (value, index, type) => {
    const updatedErrorFields = errorFields.slice();
    const fieldToUpdate = updatedErrorFields[index];
    const updatedField = {
      ...fieldToUpdate,
      [type]: value === '',
    };
    updatedErrorFields[index] = updatedField;
    setErrorFields(updatedErrorFields);
  };

  const changeHandler = (event, index, type) => {
    const { target: { value } } = event;
    const updatedCustomAttributes = customAttributes.slice();
    const oldAttribute = updatedCustomAttributes.splice(index, 1)[0];
    updatedCustomAttributes.splice(index, 0, {
      ...oldAttribute,
      [type]: value,
    });
    errorChangeHandler(value, index, type);
    setCustomAttributes(updatedCustomAttributes);
  };

  const addAttributeHandler = () => {
    // Add empty custom attribute
    const updatedCustomAttributes = customAttributes.slice();
    const emptyAttribute = {
      key: '',
      value: '',
      reactKey: new Date().getTime(),
    };
    updatedCustomAttributes.push(emptyAttribute);
    setCustomAttributes(updatedCustomAttributes);

    // Add error field with errors set to false for key and value
    const updatedErrorFields = errorFields.slice();
    updatedErrorFields.push({ key: false, value: false });
    setErrorFields(updatedErrorFields);
  };

  const deleteAttributeBtnHandler = (index) => {
    // Update Custom Attributes
    const updatedCustomAttributes = customAttributes.slice();
    const [deletedAttribute] = updatedCustomAttributes.splice(index, 1);
    if (deletedAttribute.id) {
      const updatedAttributesToDelete = attributesToDelete.slice();
      updatedAttributesToDelete.push(deletedAttribute);
      setAttributesToDelete(updatedAttributesToDelete);
    }
    setCustomAttributes(updatedCustomAttributes);

    // Udpate Error Fields
    const updatedErrorFields = errorFields.slice();
    updatedErrorFields.splice(index, 1);
    setErrorFields(updatedErrorFields);
  };

  const isConfirmDisabled = (
    (customAttributes.length === 0 && attributesToDelete.length === 0)
    || customAttributes.some((attribute) => {
      const { key, value } = attribute;
      return (key.length === 0 || value.length === 0);
    })
  );

  const confirmBtnHandler = async () => {
    if (isConfirmDisabled) return;

    let fetchedCreatedCustomAttributes = [];

    const customAttributesToCreate = (
      customAttributes
        .filter((attribute) => attribute.id === undefined)
        .map((attribute) => {
          const { key, value } = attribute;
          return { key, value };
        })
    );

    if (customAttributesToCreate.length > 0) {
      const {
        newCustomAttributes,
      } = await endpoints.createCustomAttributes(patientID, customAttributesToCreate);
      fetchedCreatedCustomAttributes = newCustomAttributes;
    }

    const customAttributesToUpdate = customAttributes.filter(
      (attribute) => attribute.id !== undefined,
    );

    if (customAttributesToUpdate.length > 0) {
      await endpoints.updateCustomAttributes(customAttributesToUpdate);
    }

    if (attributesToDelete.length > 0) {
      const customAttributesToDeleteIDs = attributesToDelete.map(
        (attribute) => attribute.id,
      );
      await endpoints.deleteCustomAttributes(customAttributesToDeleteIDs);
    }

    editCustomAttributes([
      ...customAttributesToUpdate,
      ...fetchedCreatedCustomAttributes,
    ]);

    closeLightBox();
  };

  const emptyAttributesMessage = (
    <div className="empty-message body-2" data-testid="empty-message">
      {`No Medical Information yet.
      Click “Add New Value” to start.`}
    </div>
  );

  return (
    <EditLightBox
      headerLabel="Patient Information"
      isOpen
      confirmButtonHandler={confirmBtnHandler}
      closeButtonHandler={closeLightBox}
      isKeyValuePair
      addKeyValueHandler={addAttributeHandler}
      isConfirmDisabled={isConfirmDisabled}
    >
      <div className="edit-custom-attributes-lightbox" data-testid="edit-custom-attributes-lightbox">
        {customAttributes.length > 0 ? (
          customAttributes.map((attribute, index) => (
            <div className="key-value-fields" key={attribute.id || attribute.reactKey}>
              <Input
                dataTestID={`key-${index}`}
                type="text"
                value={attribute.key}
                label="Key"
                handleOnChange={(event) => changeHandler(event, index, 'key')}
                hasError={errorFields[index].key}
                errorMessage="Key cannot be empty"
              />
              <Input
                dataTestID={`value-${index}`}
                type="text"
                value={attribute.value}
                label="Value"
                handleOnChange={(event) => changeHandler(event, index, 'value')}
                hasError={errorFields[index].value}
                errorMessage="Value cannot be empty"
              />
              <div className="delete-btn-container">
                <div
                  data-testid={`delete-btn-${index}`}
                  role="button"
                  tabIndex={0}
                  className="text-link delete-btn"
                  onClick={() => deleteAttributeBtnHandler(index)}
                  onKeyPress={(e) => (e.code === 'Enter' ? deleteAttributeBtnHandler(index) : null)}
                >
                  Delete
                </div>
              </div>
            </div>
          )))
          : emptyAttributesMessage}
      </div>
    </EditLightBox>
  );
};

export default EditCustomAttributesLightBox;

EditCustomAttributesLightBox.propTypes = {
  patientID: PropTypes.string.isRequired,
  customAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  editCustomAttributes: PropTypes.func.isRequired,
  closeLightBox: PropTypes.func.isRequired,
};

EditCustomAttributesLightBox.defaultProps = {
  customAttributes: [],
};
