import React from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import './ReferralNotesCard.scss';

const {
  components: {
    EditButton,
  },
  svgs: {
    icons: {
      getNoteIcon,
    },
  },
} = common;

const ReferralNoteCard = ({
  dataTestID,
  noteID,
  noteLabel,
  isCurrentCareAdvisor,
  needsLabel,
  noteText,
  dateLabel,
  careAdvisorLabel,
  emphasizedText,
  noteNeedIDs,
  referralNeeds,
  openNoteLightBox,
  editNote,
}) => {
  const emphasizeText = (text) => {
    if (!emphasizedText) return text;
    const regex = new RegExp(`(${emphasizedText})`, 'gi');
    const parts = text.split(regex);
    return (
      parts.map((part, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {part.toLowerCase() === emphasizedText.toLowerCase()
            ? (<span className="emphasized" data-testid="emphasized">{part}</span>)
            : part}
        </React.Fragment>
      ))
    );
  };
  return (
    <div
      className="referral-notes-card"
      data-testid={dataTestID}
    >
      <div className="referral-notes-card-header">
        <div className="referral-notes-card-header-text">
          {getNoteIcon()}
          <h2 className="title-2" data-testid={`${dataTestID}-label`}>
            {emphasizeText(noteLabel)}
          </h2>
        </div>
        {(isCurrentCareAdvisor) && (
        <EditButton
          dataTestID={`edit-note-btn-${noteID}`}
          clickHandler={() => openNoteLightBox({
            noteID,
            noteLabel,
            noteText,
            relatedNeeds: noteNeedIDs,
            referralNeeds,
            editNote,
          })}
        />
        )}
      </div>
      <div className="referral-notes-card-content">
        <h3 className="body-1-bold">{needsLabel}</h3>
        <p className="body-1" data-testid={`${dataTestID}-text`}>
          {emphasizeText(noteText)}
        </p>
      </div>
      <div className="referral-notes-card-footer">
        <p className="body-1">{dateLabel}</p>
        <p className="body-1">{careAdvisorLabel}</p>
      </div>
    </div>
  );
};

ReferralNoteCard.propTypes = {
  dataTestID: PropTypes.string,
  noteID: PropTypes.number.isRequired,
  noteLabel: PropTypes.string.isRequired,
  isCurrentCareAdvisor: PropTypes.bool.isRequired,
  needsLabel: PropTypes.string.isRequired,
  noteText: PropTypes.string.isRequired,
  dateLabel: PropTypes.string.isRequired,
  careAdvisorLabel: PropTypes.string.isRequired,
  emphasizedText: PropTypes.string,
  noteNeedIDs: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
  referralNeeds: PropTypes.arrayOf(
    PropTypes.shape({
      needID: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  openNoteLightBox: PropTypes.func.isRequired,
  editNote: PropTypes.func.isRequired,
};

ReferralNoteCard.defaultProps = {
  dataTestID: null,
  emphasizedText: null,
};

export default ReferralNoteCard;
