import React, { useState,useEffect} from 'react';
import PropTypes, { element } from 'prop-types';
import common, { async } from 'gateway-common';
import moment from 'moment';
import { formatDateTime, formatPhoneInput, sanitizeNumber } from '../../utils';
import LoadingSpinner from '../LoadingSpinner';
import { TABLE_HEADER_VALUES } from './../../constants';
import Down from './../../assets/Down.png';
import Up from './../../assets/Up.png';
import './UserManagement.scss';


const { components: { Button } } = common;

const UserManagement = ({
  accounts,
  pipelines,
  sites,
  appDataFetched,
  openAccountCreationForm,
  openEditAccountModal,
  searchAdminData,
}) => {
  const [inputData,setInputData]=useState('');
  const [users,setUsers]=useState([]);
  const [pagePerData,setPagePerData]=useState(10);
  const [pageNumber,setPageNumber]=useState(1);
  const [totalValue,setTotalValue]=useState();
  const [sortTextUp,setSortTextUp]=useState('');
  const [sortTextDown,setSortTextDown]=useState('lastlogin_down');
  const [getdata,setGetData]=useState(false);
  const [sortText,setSortText]=useState('lastLogin');
  const [sortOrder,setSortOrder]=useState('desc')
  const ADMIN_USERS = 'admin';
  const REFERRER_USERS = 'referrer';
  const [activeUserTab, setActiveUserTab]=useState(ADMIN_USERS);
  const value=(totalValue/pagePerData);
  const paginationValue=Math.ceil(value)
  let array=[];
  for (let index = 1; index <=paginationValue; index++) {
     array.push(index) 
  }
  const sortTable= async (item,text)=>{
    if(text === 'UP'){
      setSortTextUp(item.sortTextUp)
      setSortTextDown('')
      const users = await searchAdminData(inputData, pagePerData,  pageNumber, item.sort, 'asc', activeUserTab === REFERRER_USERS ? true : false,);
      setUsers(users.data.accounts)
      setTotalValue(users.data.total)
      setGetData(users.success)
      setSortText(item.sort)
      setSortOrder('asc')
    }
    else if(text === 'Down'){
      setSortTextDown(item.sortTextDown)
      setSortTextUp('')
      const users = await searchAdminData(inputData, pagePerData, pageNumber, item.sort, 'desc', activeUserTab === REFERRER_USERS ? true : false,);
      setUsers(users.data.accounts)
      setTotalValue(users.data.total)
      setGetData(users.success)
      setSortText(item.sort)
      setSortOrder('desc')
    }
  }
  const buildAccountsTable = (users) => {

     const accountTableColumns=TABLE_HEADER_VALUES.map((item)=>{
       if(item.label != 'Department' && item.label != 'Last Case Submission Days'|| activeUserTab === 'referrer'){  
        return (
          <th>
            <div className="sort_row">
              <div className={item.sortTextUp === sortTextUp || item.sortTextDown === sortTextDown  ?'sort_text_select':'sort_text'}>{item.label}</div>
              <div className="sort_icon">
                <img src={Up} className={item.sortTextUp === sortTextUp?"up_icon_selected":"up_icon"} onClick={()=>sortTable(item,'UP')}/>
                <img src={Down} className={item.sortTextDown === sortTextDown ?"down_icon_selected":"down_icon"} onClick={()=>sortTable(item,'Down')} />
              </div>
            </div>
          </th>
        );
       }
     })
    // sorting the users array with accountId ascending before mapping
    const accountsTableRows = users.map((account) => {
      let pipeline;
      const site = sites.find((_site) => _site.id === account.siteID);
      if (site) {
        pipeline = pipelines.find((_pipeline) => _pipeline.id === site.pipeline_id);
      }
      const lastCaseSubmittedDate=moment(account?.lastCaseSubmittedDate).format('YYYY-MM-DD');
      const lastCaseAssignmentDate=moment(account?.lastCaseSubmittedDateByAssignment).format('YYYY-MM-DD');
      const days =lastCaseSubmittedDate==='Invalid date' ? null : moment().diff(moment(lastCaseSubmittedDate), 'days')
      const assignmentDays =lastCaseAssignmentDate==='Invalid date' ? null : moment().diff(moment(lastCaseAssignmentDate), 'days')
      return (
        <tr key={account.accountID}>
          <td>{ account.accountID }</td>
          <td>{`${account.firstName} ${account.middleName || ''} ${account.lastName}`}</td>
          <td>{ account.email }</td>
          <td>
            <a href={`tel:${sanitizeNumber(account.phone)}`}>{ formatPhoneInput(sanitizeNumber(account.phone)) }</a>
          </td>
          <td>{ pipeline?.label }</td>
          <td>{ site?.label }</td>
          {activeUserTab === 'referrer'?<td>{account?.department}</td>:null}
          <td>{ account.lastLogin ? formatDateTime(account.lastLogin) : 'Never' }</td>
          <td>{ formatDateTime(account.createdAt) }</td>
          {activeUserTab === 'referrer'?<td>{days !== null ? days : assignmentDays }</td>:null}
          <td>
            <Button
              dataTestID="edit-account-button"
              buttonText="Edit"
              className="button-secondary-small-2 button_table"
              onClickHandler={() => {
                openEditAccountModal({ account })}
              }
            />
          </td>
        </tr>
      );
    });
    return (
      <>
        <table className="accounts-list-container">
        <thead>
          <tr>
          { accountTableColumns }
          </tr>
        </thead>
          {users.length >0?
          <tbody>
           {getdata ? 
            accountsTableRows:<tr><td></td><td></td><td></td><td></td><td></td><td><LoadingSpinner/></td><td></td><td></td><td></td><td></td></tr>}
          </tbody>
          :<tbody><tr><td></td><td></td><td></td><td></td><td>No records found</td><td></td><td></td><td></td><td></td><td></td></tr></tbody>}
        </table>
        <div className='admin-pagination'>
          <ul>
            <li onClick={()=>pageDecrement()} className={pageNumber === 1 && 'disabled'}>{'<'}</li>
            {array.map(element => {
             return <li className={element === pageNumber && 'active'} key={element} onClick={()=>applyFiltersClickHandler(element)}>{element}</li>;
            })}
            <li onClick={()=>pageIncrement()} className={pageNumber === array.length && 'disabled'}>{'>'}</li>
          </ul>
        </div>
        </>
    );
  };

  useEffect(()=>{
    (async () => {
      if(inputData === ''){
        const users = await searchAdminData(
          inputData, 
          pagePerData,
          pageNumber,
          activeUserTab === REFERRER_USERS ? true : false,
        );
        setUsers(users.data.accounts);
        setTotalValue(users.data.total);
        setGetData(users.success)
      }
    })();
  },[inputData])

  useEffect(async () => {
    applyFiltersClickHandler(pageNumber);
    localStorage.setItem('activeUserTab',activeUserTab);
  },[activeUserTab,accounts])

  const pageIncrement= async ()=>{
    if(array.length !== pageNumber){
      setPageNumber(pageNumber+1)
      const users = await searchAdminData(inputData, pagePerData, pageNumber+1, sortText, sortOrder, activeUserTab === REFERRER_USERS ? true : false,);
      setUsers(users.data.accounts)
      setTotalValue(users.data.total)
      setGetData(users.success)
    }
  }

  const pageDecrement= async ()=>{
    if(pageNumber !== 1){
      const users = await searchAdminData(inputData, pagePerData, pageNumber-1, sortText, sortOrder, activeUserTab === REFERRER_USERS ? true : false);
      setUsers(users.data.accounts)
      setTotalValue(users.data.total)
      setPageNumber(pageNumber-1)
      setGetData(users.success)
    }
  }

  const searchChangeHandler =({ target: { value } })=>{
    setInputData(value)
  }

  const applyFiltersClickHandler = async (pagenumber) =>{
    const users = await searchAdminData(inputData, pagePerData, pagenumber, sortText , sortOrder, activeUserTab === REFERRER_USERS ? true : false);
    setUsers(users.data.accounts)
    setTotalValue(users.data.total)
    setPageNumber(pagenumber)
    setGetData(users.success)
  }

  const activeTabs=(value)=>{
    if(value === 'admin'){
      setActiveUserTab(value)
      setPageNumber(1)
      setSortTextDown('lastlogin_down')
      setSortTextUp('')
      setGetData(false)
      setSortText('lastLogin')
      setSortOrder('desc')
    }
    else{
      setActiveUserTab(value)
      setPageNumber(1)
      setSortTextDown('lastlogin_down')
      setSortTextUp('')
      setGetData(false)
      setSortText('lastLogin')
      setSortOrder('desc')
    }
  }

  return (
    <div className="user-management-container">
      <div className="add-account-btn-container">
        <div className="account-user-management-admin">
          <div
            onClick={() => activeTabs(ADMIN_USERS)}
            className={`account-user-management-admin__tab ${
              activeUserTab === ADMIN_USERS &&
              "account-user-management-admin__active"
            }`}
          >
            Care coordinators
          </div>
          <div
            onClick={() => activeTabs(REFERRER_USERS)}
            className={`account-user-management-admin__tab ${
              activeUserTab === REFERRER_USERS &&
              "account-user-management-admin__active"
            }`}
          >
            Referrers
          </div>
        </div>

        <div className="patient-search-container">
          <div className="search">
            <input
              className="searchbar-1"
              value={inputData}
              onChange={searchChangeHandler}
              placeholder="Search an account by name or email or phone number or pipeline or site "
              data-testid="patient-search-input"
            />
          </div>
          <Button
            dataTestID="apply-btn"
            className={`button-primary-small${
              inputData === "" ? " inactive" : ""
            }`}
            buttonText="Apply"
            buttonTextClassName="body-1-bold"
            onClickHandler={() => applyFiltersClickHandler(pageNumber)}
          />
        </div>
        {activeUserTab === ADMIN_USERS && (
          <button
            type="button"
            className="button-primary-small"
            onClick={openAccountCreationForm}
            data-testid="add-advisor-account"
          >
            Add Care Coordinators Account
          </button>
        )}
        {activeUserTab === REFERRER_USERS && (
          <button
            type="button"
            className="button-primary-small"
            onClick={() => openAccountCreationForm({ isReferrer: true })}
            data-testid="add-referrer-account"
          >
            Add Referrer Account
          </button>
        )}
      </div>
      {users && appDataFetched && buildAccountsTable(users)}
    </div>
  );
};

UserManagement.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountID: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      pipelineID: PropTypes.number.isRequired,
      siteID: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    description: PropTypes.string,
    pipeline_id: PropTypes.number,
  })).isRequired,
  pipelines: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
  appDataFetched: PropTypes.bool.isRequired,
  openAccountCreationForm: PropTypes.func.isRequired,
  openEditAccountModal: PropTypes.func.isRequired,
};

export default UserManagement;
