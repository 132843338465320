// constants
export const SET_NOTES = 'SET_NOTES';
export const ADD_NOTE = 'ADD_NOTE';
export const EDIT_NOTE = 'EDIT_NOTE';
export const ARCHIVE_NOTE = 'ARCHIVE_NOTE';
export const OPEN_NEW_NOTE_FORM = 'OPEN_NEW_NOTE_FORM';
export const CLOSE_NEW_NOTE_FORM = 'CLOSE_NEW_NOTE_FORM';
export const UNDO_NOTE_CHANGE = 'UNDO_NOTE_CHANGE';

// actions
export const setNotes = (notes) => ({
  type: SET_NOTES,
  notes,
});

export const addNote = (note) => ({
  type: ADD_NOTE,
  note,
});

export const editNote = (note) => ({
  type: EDIT_NOTE,
  note,
});

export const archiveNote = (note) => ({
  type: ARCHIVE_NOTE,
  note,
});

export const openNewNoteForm = () => ({
  type: OPEN_NEW_NOTE_FORM,
});

export const closeNewNoteForm = () => ({
  type: CLOSE_NEW_NOTE_FORM,
});

// action handlers
const ACTION_HANDLERS = {
  [SET_NOTES]: (state, action) => ({
    ...state,
    notes: action.notes,
  }),
  [ADD_NOTE]: (state, action) => ({
    ...state,
    notes: [...state.notes, action.note],
  }),
  [EDIT_NOTE]: (state, action) => ({
    ...state,
    notes: state.notes.map((note) => (note.id === action.note.id ? action.note : note)),
  }),
  [ARCHIVE_NOTE]: (state, action) => ({
    ...state,
    notes: state.notes.map((note) => (
      note.id === action.note.id ? { ...note, archived: true } : note
    )),
  }),
  [OPEN_NEW_NOTE_FORM]: (state) => ({
    ...state,
    triggerNewNote: true,
  }),
  [CLOSE_NEW_NOTE_FORM]: (state) => ({
    ...state,
    triggerNewNote: false,
  }),
  [UNDO_NOTE_CHANGE]: (state, action) => ({
    ...state,
    notes: state.notes.map((note) => (
      note.id === action.entityID ? { ...note, ...action.newValues } : note
    )),
  }),
};

const initialState = {
  notes: [],
  triggerNewNote: false,
};

export default function noteReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
