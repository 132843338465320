import { connect } from 'react-redux';
import NoteLightBox from './NoteLightBox';
import { closeLightBox } from '../../reducers/lightbox';

const mapDispatchToProps = (dispatch) => ({
  closeLightBox: () => dispatch(closeLightBox()),
});

const NoteLightBoxContainer = connect(
  null,
  mapDispatchToProps,
)(NoteLightBox);

export default NoteLightBoxContainer;
