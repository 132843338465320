// constants
export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const ARCHIVE_ATTACHMENT = 'ARCHIVE_ATTACHMENT';
export const OPEN_FILE_DIALOG = 'OPEN_FILE_DIALOG';
export const CLOSE_FILE_DIALOG = 'CLOSE_FILE_DIALOG';
export const UNDO_ATTACHMENT_CHANGE = 'UNDO_ATTACHMENT_CHANGE';

// actions
export const setAttachments = (attachments) => ({
  type: SET_ATTACHMENTS,
  attachments,
});

export const addAttachment = (attachment) => ({
  type: ADD_ATTACHMENT,
  attachment,
});

export const archiveAttachment = (attachment) => ({
  type: ARCHIVE_ATTACHMENT,
  attachment,
});

export const openFileDialog = () => ({
  type: OPEN_FILE_DIALOG,
});

export const closeFileDialog = () => ({
  type: CLOSE_FILE_DIALOG,
});

// action handlers
const ACTION_HANDLERS = {
  [SET_ATTACHMENTS]: (state, action) => ({
    ...state,
    attachments: action.attachments,
  }),
  [ADD_ATTACHMENT]: (state, action) => ({
    ...state,
    attachments: [...state.attachments, action.attachment],
  }),
  [ARCHIVE_ATTACHMENT]: (state, action) => ({
    ...state,
    attachments: state.attachments.map((attachment) => (
      attachment.id === action.attachment.id ? { ...attachment, archived: true } : attachment
    )),
  }),
  [OPEN_FILE_DIALOG]: (state) => ({
    ...state,
    triggerNewAttachment: true,
  }),
  [CLOSE_FILE_DIALOG]: (state) => ({
    ...state,
    triggerNewAttachment: false,
  }),
  [UNDO_ATTACHMENT_CHANGE]: (state, action) => ({
    ...state,
    attachments: state.attachments.map((attachment) => (
      attachment.id === action.entityID ? { ...attachment, ...action.newValues } : attachment
    )),
  }),
};

const initialState = {
  attachments: [],
  triggerNewAttachment: false,
};

export default function attachmentReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
