import * as Sentry from '@sentry/browser';

const sentryEnabled = () => process.env.REACT_APP_SENTRY_ENABLED === 'true';

const initializeSentry = () => {
  if (sentryEnabled()) {
    Sentry.init({
      dsn: 'https://1d517818d9e9485eb1ff823ccc17262c@o388887.ingest.sentry.io/5227577',
    });
  }
};

const throwError = (error) => {
  throw new Error(error);
};

const sendErrorWithScope = (error, errorInfo, callback) => {
  if (sentryEnabled()) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      if (callback) callback(eventId);
    });
  }
};

const sendAPIError = (error, callback) => {
  if (sentryEnabled()) {
    Sentry.withScope((scope) => {
      scope.setExtras(error);
      Sentry.captureException(error);
      if (callback) callback(error);
    });
  }
};

const setUser = (accountID) => {
  if (sentryEnabled()) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        accountID,
      });
    });
  }
};

export {
  initializeSentry,
  sendErrorWithScope,
  setUser,
  sendAPIError,
  throwError,
};
