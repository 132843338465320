import { format, parseISO } from 'date-fns';
import { camelCase } from 'lodash';
import { needsStatusTypes, subStatusTypes } from './constants';

// pipeline icons
import iconLurie from './assets/client-lurie.png';
import iconAdvocate from './assets/client-advocate.png';
import iconLoyola from './assets/client-loyola.png';
import iconNorthwestern from './assets/client-northwestern.png';
import iconUIHealth from './assets/client-ui-health.png';
import iconInsight from './assets/client-insight.png';

const camelToSnake = (string) => string.replace(/[\w]([A-Z])/g, (m) => `${m[0]}_${m[1]}`).toLowerCase();

const formatDateTime = (date) => format(parseISO(date), "MM-dd-yyyy 'at' h:mm a");

const formatISODate = (date) => format(parseISO(date), 'MM-dd-yyyy');

// handle camelCase conversion for special case where key ends with '_id'
const handleIDcamel = (key) => {
  const prefix = key.substring(0, key.indexOf('_id'));
  return `${camelCase(prefix)}ID`;
};

// camelize object keys recursively
const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [key.endsWith('_id') ? handleIDcamel(key) : camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);

const isValidEmail = (email) => validEmailRegex.test(email);

const isValidPassword = (password) => password.length >= 8;

const validPhoneNumberRegex = RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);

const isValidPhoneNumber = (phoneNumber) => validPhoneNumberRegex.test(phoneNumber);

const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';
  const units = [' Bytes', ' KB', ' MB'];
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return ((bytes / (k ** i)).toFixed(dm)).toString().concat(units[i]);
};

/**
 * Function for format the phone number
 * @param {string} input 
 * @returns formated values Eg: (###) ###-####
 */
const formatPhoneInput = (input) => {
  const cleanInput = input?.trim().replace(/[\D]/g, '');
  if (cleanInput?.length > 6) {
    return `(${cleanInput.slice(0, 3)}) ${cleanInput.slice(3, 6)}-${cleanInput.slice(6, 10)}`;
  }
  if (cleanInput?.length > 3) return `${cleanInput.slice(0, 3)} ${cleanInput.slice(3, 6)}`;
  return cleanInput;
};

/**
 *  If DB have any other format it could be sanitize the phone number before formating. 
 */
const sanitizeNumber = (num) => {
  if (!num) return null;
  return num.replace(/\D/g, '');
};

const formatPhoneWithHyphens = (phone) => {
  const firstPart = phone.slice(0, 3);
  const secondPart = phone.slice(3, 6);
  const thirdPart = phone.slice(6);
  return `${firstPart}-${secondPart}-${thirdPart}`;
};

const formatPhoneToNumsOnly = (phone) => phone.replace(/[()-\s+]/g, '');

const formatSSNInput = (input) => {
  const cleanInput = input.trim().replace(/[\D]/g, '');
  if (cleanInput.length > 5) return `${cleanInput.slice(0, 3)}-${cleanInput.slice(3, 5)}-${cleanInput.slice(5, 9)}`;
  if (cleanInput.length > 3) return `${cleanInput.slice(0, 3)}-${cleanInput.slice(3, 5)}`;
  return cleanInput;
};

const removeTimestamp = (date) => {
  if (!date) return '';
  return date.split('T')[0];
};

const capitalize = (string) => (
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
);

const getIconFromPipelineType = (pipelineType) => {
  switch (pipelineType) {
    // 'Lurie'
    case 'lurie':
      return {
        src: iconLurie,
        width: '89px',
        height: '86px',
      };
    // 'Advocate'
    case 'advocate':
      return {
        src: iconAdvocate,
        width: '94px',
        height: '94px',
      };
    // 'Loyola'
    case 'loyola':
      return {
        src: iconLoyola,
        width: '88px',
        height: '84px',
      };
    // 'Northwestern'
    case 'northwestern':
      return {
        src: iconNorthwestern,
        width: '113x',
        height: '93px',
      };
    // 'UI Health'
    case 'ui_health':
      return {
        src: iconUIHealth,
        width: '111px',
        height: '90px',
      };
    case 'insight': return {
      src: iconInsight,
      width: '111px',
      height: '90px',
    };
    default:
      return {
        src: '',
        width: '0px',
        height: '0px',
      };
  }
};

const formatAddress = (address, addressLine2, city, state, zip) => {
  const definedAddressItems = [address, addressLine2, city, state, zip].filter((item) => !!item);
  return definedAddressItems.join(', ');
};

const needStatusToSubStatusMapping = (needStatusType) => {
  const {
    REFERRAL_RECEIVED,
    SCREENED_PATIENT,
    APPLICATION_SUBMITTED,
    CASE_CLOSED,
    MATCHING_PATIENT_WITH_SERVICE,
  } = needsStatusTypes;
  const {
    DOCUMENTS_NEEDED,
    CONTACT_INFO_ISSUE,
    WAITING_FOR_REPLY,
    PATIENT_UNRESPONSIVE,
    CASE_RECEIVED_GOVT,
    PENDING,
    INTERVIEW_SCHEDULED,
    INTERVIEW_COMPLETED,
    APPLICATION_EXPEDITED,
    APPLICATION_APPROVED,
    APPLICATION_DENIED,
    PATIENT_INELIGIBLE,
    AWAITING_APPROVAL,
    SERVICE_APPROVED,
    APPLICATION_SUBMIT,
    SERVICE_DENIED_BY_PATIENT,
  } = subStatusTypes;
  switch (needStatusType) {
    case REFERRAL_RECEIVED:
      return [
        CONTACT_INFO_ISSUE,
        WAITING_FOR_REPLY,
        PATIENT_UNRESPONSIVE,
      ];
    case SCREENED_PATIENT:
      return [
        DOCUMENTS_NEEDED,
        WAITING_FOR_REPLY,
        PENDING,
        INTERVIEW_SCHEDULED,
      ];
    case APPLICATION_SUBMITTED:
      return [
        CASE_RECEIVED_GOVT,
        INTERVIEW_SCHEDULED,
        INTERVIEW_COMPLETED,
        APPLICATION_EXPEDITED,
        AWAITING_APPROVAL,
      ];
    case CASE_CLOSED:
      return [
        PATIENT_UNRESPONSIVE,
        CASE_RECEIVED_GOVT,
        APPLICATION_APPROVED,
        APPLICATION_DENIED,
        PATIENT_INELIGIBLE,
        SERVICE_APPROVED,
        SERVICE_DENIED_BY_PATIENT,
      ];
    case MATCHING_PATIENT_WITH_SERVICE:
      return [
        DOCUMENTS_NEEDED,
        WAITING_FOR_REPLY,
        PENDING,
        INTERVIEW_SCHEDULED,
        INTERVIEW_COMPLETED,
        APPLICATION_SUBMIT,
      ];
    default:
      return [];
  }
};

export {
  camelToSnake,
  formatDateTime,
  formatISODate,
  sanitizeNumber,
  camelizeKeys,
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
  formatBytes,
  formatPhoneInput,
  formatPhoneWithHyphens,
  formatPhoneToNumsOnly,
  formatSSNInput,
  removeTimestamp,
  capitalize,
  getIconFromPipelineType,
  formatAddress,
  needStatusToSubStatusMapping,
};
