import { connect } from 'react-redux';
import AddNeedLightBox from './AddNeedLightBox';
import { closeLightBox } from '../../reducers/lightbox';
import { addNeeds } from '../../reducers/referral';

const mapStateToProps = (state) => {
  const {
    referral: { referral: { needs } },
    appData: { governmentCategories, sdohCategories, applicationTypes },
  } = state;
  const existingReferralNeedsCategoryIDs = needs.map(({ needCategoryID }) => needCategoryID);
  const governmentBenefitsOptions = governmentCategories
    .filter(({ id }) => !existingReferralNeedsCategoryIDs.includes(id))
    .map((governmentCategory) => ({
      ...governmentCategory,
      type: governmentCategory.categoryType,
      children: applicationTypes,
    }));
  const sdohOptions = sdohCategories.filter(
    ({ id }) => !existingReferralNeedsCategoryIDs.includes(id),
  );
  return {
    governmentBenefitsOptions,
    sdohOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNeeds: (needs) => dispatch(addNeeds(needs)),
  closeLightBox: () => dispatch(closeLightBox()),
});

const AddNeedLightBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNeedLightBox);

export default AddNeedLightBoxContainer;
