import { connect } from 'react-redux';
import CaseDrafts from './CaseDrafts';
import { setTriggerDrafts, setDraft } from '../../reducers/drafts';
import { openNewReferralWizard } from '../../reducers/newReferral';

const mapDispatchToProps = (dispatch) => ({
  setTriggerDrafts: (val) => dispatch(setTriggerDrafts(val)),
  setDraft: (draft) => dispatch(setDraft(draft)),
  openNewReferralWizard: () => dispatch(openNewReferralWizard()),
});

const CaseDraftsContainer = connect(
  null,
  mapDispatchToProps,
)(CaseDrafts);

export default CaseDraftsContainer;
