import { connect } from 'react-redux';
import EditCustomAttributesLightBox from './EditCustomAttributesLightBox';
import { closeLightBox } from '../../reducers/lightbox';
import { setCustomAttributes } from '../../reducers/patientInfo';

const mapStateToProps = (state) => {
  const {
    patientInfo: {
      customAttributes,
    },
  } = state;
  return {
    customAttributes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editCustomAttributes: (customAttributes) => dispatch(setCustomAttributes(customAttributes)),
  closeLightBox: () => dispatch(closeLightBox()),
});

const EditCustomAttributesLightBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCustomAttributesLightBox);

export default EditCustomAttributesLightBoxContainer;
