import * as endpoints from '../services/endpoints';

// constants

export const SET_APP_DATA = 'SET_APP_DATA';
export const SET_APP_DATA_ALL_ACCOUNTS = 'SET_APP_DATA_ALL_ACCOUNTS';
export const SET_SELECTED_REFRRAL_STATUS = 'SET_SELECTED_REFRRAL_STATUS';

/**
 * Actions to updated selected refrral status
 */
export const setSelectedRefrralStatus = (selectedStatusID) => ({
  type: SET_SELECTED_REFRRAL_STATUS,
  selectedStatusID,
});

// actions
export const setAppData = (appData) => ({
  type: SET_APP_DATA,
  appData,
});

export const setAppDataAllAccounts = (allAccounts) => ({
  type: SET_APP_DATA_ALL_ACCOUNTS,
  allAccounts,
});

export const createPipeline = (label) => (
  async (dispatch, getState) => {
    const { appData: { pipelines } } = getState();
    const id = await endpoints.createPipeline(label);
    const newPipeline = { id, label, sites: [] };
    dispatch(setAppData({ pipelines: [newPipeline, ...pipelines] }));
  }
);

export const updatePipeline = (pipelineID, pipelineLabel) => (
  async (dispatch, getState) => {
    const { appData: { pipelines } } = getState();
    const isSuccess = await endpoints.updatePipeline(pipelineID, pipelineLabel);
    if (isSuccess) {
      const updatedPipelines = pipelines.map((pipeline) => (
        pipeline.id === pipelineID
          ? { ...pipeline, label: pipelineLabel }
          : pipeline));
      dispatch(setAppData({ pipelines: updatedPipelines }));
    }
  }
);

export const createSite = (pipelineID, label) => (
  async (dispatch, getState) => {
    const { appData: { sites } } = getState();
    const id = await endpoints.createSite(pipelineID, label);
    const newSite = { id, label, pipeline_id: pipelineID };
    dispatch(setAppData({ sites: [newSite, ...sites] }));
  }
);

export const updateSite = (siteID, siteLabel) => (
  async (dispatch, getState) => {
    const { appData: { sites } } = getState();
    const isSuccess = await endpoints.updateSite(siteID, siteLabel);
    if (isSuccess) {
      const updatedSites = sites.map((site) => (
        site.id === siteID
          ? { ...site, label: siteLabel }
          : site
      ));
      dispatch(setAppData({ sites: updatedSites }));
    }
  }
);

export const createAccount = (account) => (
  async (dispatch, getState) => {
    const { appData: { allAccounts } } = getState();
    const accountID = await endpoints.createAccount(account);
    const updatedAccounts = [
      ...allAccounts,
      {...account, accountID, createdAt: new Date().toISOString(),  },
    ];
    dispatch(setAppData({ allAccounts: updatedAccounts }));
  }
);

export const updateAccount = (updatedAccount) => (
  async (dispatch, getState) => {
    const { appData: { allAccounts } } = getState();
    await endpoints.updateAccount(updatedAccount);
    const updatedAccounts = allAccounts.map((account) => {
      if (account.accountID === updatedAccount.accountID) {
        return {
          ...account,
          ...updatedAccount,
        };
      } return account;
    });
    dispatch(setAppData({ allAccounts: updatedAccounts }));
  }
);

// action handlers
const ACTION_HANDLERS = {
  //add all accounts in appdata state
  [SET_APP_DATA_ALL_ACCOUNTS]: (state, action) => ({
    ...state,
    allAccounts: action.allAccounts,
    appDataFetched: true,
  }),
  [SET_APP_DATA]: (state, action) => ({
    ...state,
    ...action.appData,
    appDataFetched: true,
  }),
  [SET_SELECTED_REFRRAL_STATUS]: (state, action) => {
    const selectedStatus = state.statuses.find((status) => status.id === action.selectedStatusID);
    return ({
    ...state,
    selectedRefrralStatus: selectedStatus,
  })},
};

const initialState = {
  governmentCategories: [],
  sdohCategories: [],
  pipelines: [],
  sites: [],
  statuses: [],
  subStatuses: [],
  relationshipTypes: [],
  urgencyTypes: [],
  applicationTypes: [],
  careAdvisors: [],
  sexTypes: [],
  accounts: [],
  referralSources: [],
  referrerAccounts: [],
  billingCodes: [],
  appDataFetched: false,
  allAccounts: [],
  selectedRefrralStatus: {},
};

export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
