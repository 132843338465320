import { connect } from 'react-redux';
import Navbar from './Navbar';
import { openNewReferralWizard } from '../../reducers/newReferral';
import { setTriggerDrafts, setDraft } from '../../reducers/drafts';

const mapStateToProps = (state) => {
  const {
    newReferral: { isOpen },
  } = state;
  return {
    newReferralWizardIsOpen: isOpen,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openNewReferralWizard: () => dispatch(openNewReferralWizard()),
  setTriggerDrafts: (val) => dispatch(setTriggerDrafts(val)),
  setDraft: (draft) => dispatch(setDraft(draft)),
});

const NavbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Navbar);

export default NavbarContainer;
