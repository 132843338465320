import { connect } from 'react-redux';
import common from 'gateway-common';
import { openNeedStatusChangeLightbox } from '../../reducers/lightbox';
import AppDataService from '../../services/AppData';
import { setSelectedRefrralStatus } from '../../reducers/app';

const { components: { VerticalTimeline } } = common;

const mapStateToProps = (state) => {
  const {
    appData: {
      statuses,
      subStatuses,
      careAdvisors,
      urgencyTypes,
    },
    referral: {
      referral: {
        needs,
        urgencyTypeID,
      },
    },
    needTimeline: {
      needTimeline: {
        timelineStatuses,
        needStatuses,
      },
      currentNeedID,
    },
  } = state;

  const isUrgent = AppDataService.isUrgent(urgencyTypeID, urgencyTypes);
  const need = needs.find(({ needID }) => needID === currentNeedID);
  const isClosed = AppDataService.isNeedClosed(need?.statusID, statuses);

  const transformedTimelineStatuses = timelineStatuses?.map((timelineStatus) => {
    const { id, order, isInternal } = timelineStatus;

    const status = statuses?.find(({ id: statusID }) => id === statusID);
    const type = status?.type ? status.type : null;
    const label = status?.label ? status.label : null;

    return {
      id,
      type,
      label,
      order,
      isInternal,
    };
  });

  var buttondisble;
  var order;
  var button = needStatuses.map((rank, i, arr) => {
    var data;
    if (arr.length - 1 === i) {
      data = arr[i].statusID
    }
    for (var i = 0; i < transformedTimelineStatuses.length; i++) {
      if (data === transformedTimelineStatuses[i].id) {
        buttondisble = transformedTimelineStatuses[i].type
      }
    }

  })
  const transformedNeedStatuses = needStatuses.map(({
    needStatusID,
    statusID,
    createdAt,
    messages,
    isInternal,
  }) => {
    const status = statuses.find(({ id }) => id === statusID);
    const type = status?.type ? status.type : null;
    const label = status?.label ? status.label : null;

    const messagesAttributes = messages.map(({
      subStatusID,
      careAdvisorAccountID,
      messageID,
      messageCreatedAt,
      message,
      messageDeleted,
      messageEdited,
      messageUpdatedAt,
    }) => {
      const careAdvisor = careAdvisors.find(({ id }) => id === careAdvisorAccountID);
      const firstName = careAdvisor?.firstName ? careAdvisor.firstName : null;
      const lastName = careAdvisor?.lastName ? careAdvisor.lastName : null;

      let subStatus = null;

      if (subStatusID) {
        subStatus = subStatuses.find((s) => s.subStatusID === subStatusID);
        const subStatusType = subStatus?.subStatusType ? subStatus.subStatusType : null;
        const subStatusLabel = subStatus?.subStatusLabel ? subStatus.subStatusLabel : null;

        subStatus = {
          subStatusID,
          subStatusType,
          subStatusLabel,
        };
      }

      return {
        firstName,
        lastName,
        messageID,
        messageCreatedAt,
        message,
        messageDeleted,
        messageEdited,
        messageUpdatedAt,
        subStatus,
        statusID,
        disable: buttondisble === 'case_closed' ? true : false
      };
    });
    return {
      needStatusID,
      createdAt,
      type,
      label,
      messages: messagesAttributes,
      isInternal,
      statusID,
    };
  });

  let duplicatedata = []
  let externalNeedStatuses = transformedNeedStatuses.reduce((elements, obj, index) => {
    let existingData = elements.find(element =>
      element.statusID === obj.statusID
    );
    if (!existingData) {
      elements.push(obj);
    } else {
      duplicatedata.push(obj)
    }
    return elements;
  }, []);
  const externalTimelineStatuses1 = transformedTimelineStatuses.filter(
    (item) => {
      if (item.order < 6 && item.isInternal === false) {
        return { item }
      }
    }
  );
  let internaldata = [];

  let externalNeedStatuses3 = transformedNeedStatuses.reduce((elements, obj, index) => {
    if (obj.type === 'case_re_opened' || obj.type === 'follow_up_initiated' || obj.type === 'case_closed' && obj.isInternal) {
      internaldata.push(obj)
    }
    return elements;
  }, []);
  const children = internaldata.concat(duplicatedata);
  const data = transformedTimelineStatuses.filter((item) => {
    if (item.type === 'case_re_opened') {
      return item
    }
  })
  const internalTimelineStatuses = transformedTimelineStatuses.filter((item) => {
    if (item.order >= data[0]?.order) {
      return { item }
    }
  })

  const transformed = transformedNeedStatuses.filter((item) => {
    if (item.type === 'case_re_opened' || item.type === 'follow_up_initiated' || item.type === 'case_closed' && item.isInternal) {
      return { item }
    }
  })
  const datas = transformed.length
  let indexofstatus = transformedNeedStatuses?.findIndex(_data => _data?.type === 'case_re_opened');
  const externalstatus = transformedNeedStatuses?.slice(0, indexofstatus === -1 ? transformedNeedStatuses?.length : indexofstatus)
  const stemp = transformedNeedStatuses?.slice(indexofstatus, transformedNeedStatuses?.length)
  let indexofstatus1 = transformedTimelineStatuses?.findIndex(_data => _data?.type === 'case_re_opened');
  const externaltemp = transformedTimelineStatuses?.slice(0,indexofstatus1)
  const ttemp = transformedTimelineStatuses?.slice(indexofstatus1, transformedTimelineStatuses?.length)

  let newarray = [];
  externalstatus.forEach(data => {
    let indexofstatus = newarray?.findIndex(_data => _data?.statusID === data?.statusID);
    if (indexofstatus > -1) {
      newarray[indexofstatus] = {
        ...newarray[indexofstatus],
        messages: [...newarray[indexofstatus].messages, ...data.messages]
      }
    } else {
      newarray.push(data)
    }
  })
  
  externaltemp.forEach((data, index) => {
    newarray.forEach((data1,index1)=>{
      if(data1.statusID ===data?.id){
        newarray[index1].orderOf = data?.order
      }
    })
  })
  let casereopn = []
  let tempstatus = stemp.map(data => ({ ...data, took: false }))
  tempstatus.forEach((data, index) => {
    if (data.took === true) return
    let submessages = data
    let nextStepIndex = index + 1
    data.took = true
    if (!!tempstatus[nextStepIndex]?.statusID) {
      while (tempstatus[nextStepIndex]?.statusID === data?.statusID) {
        tempstatus[nextStepIndex].took = true
        submessages.messages = [...submessages.messages, ...tempstatus[nextStepIndex]?.messages]
        nextStepIndex += 1
      }
    }
    casereopn.push(submessages)
  });
  casereopn.forEach((data, index) => {
    delete data.took
    data.orderOf = ttemp[index]?.order
  })

  const externalNeedStatuses1 = transformedNeedStatuses.filter(
    ({ isInternal }) => !isInternal,
  );
  const externalTimelineStatuses = transformedTimelineStatuses.filter(
    ({ isInternal }) => !isInternal,
  );

  const internalNeedStatuses = transformedNeedStatuses.filter(
    ({ isInternal }) => isInternal,
  );
  const internalTimelineStatuses1 = transformedNeedStatuses.filter(
    ({ isInternal }) => isInternal,
  );
  return {
    timeline: newarray,
    statuses: datas > 0 ? externalTimelineStatuses1 : externalTimelineStatuses,
    internalTimeline: datas > 0 && casereopn,
    internalStatuses: datas > 0 ? internalTimelineStatuses : internalTimelineStatuses1,
    isUrgent,
    isClosed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeStatusHandler: () => dispatch(openNeedStatusChangeLightbox()),
  setSelectedRefrralStatus: (statusID) => dispatch(setSelectedRefrralStatus(statusID)),
});

const VerticalTimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerticalTimeline);

export default VerticalTimelineContainer;