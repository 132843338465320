const getDrafts = () => {
  const drafts = localStorage.getItem('drafts');
  return JSON.parse(drafts) || [];
};

const generateDraftID = () => {
  const drafts = getDrafts();
  if (!drafts.length) return 0;
  return Math.max(...drafts.map((d) => d.draftID)) + 1;
};

const storeDraft = (newCase) => {
  const { draftID = generateDraftID() } = newCase;
  const drafts = getDrafts().filter((d) => d.draftID !== draftID);
  const updatedDrafts = [
    ...drafts,
    {
      ...newCase,
      draftID,
      dateSaved: new Date(),
      attachments: [],
    },
  ];
  localStorage.setItem('drafts', JSON.stringify(updatedDrafts));
  return draftID;
};

const getDraft = (draftID) => {
  const drafts = getDrafts();
  return drafts.find((d) => d.draftID === draftID);
};

const removeDraft = (draftID) => {
  const drafts = getDrafts();
  const updatedDrafts = drafts.filter((d) => d.draftID !== draftID);
  localStorage.setItem('drafts', JSON.stringify(updatedDrafts));
  return updatedDrafts;
};

export {
  storeDraft,
  getDrafts,
  getDraft,
  removeDraft,
};
