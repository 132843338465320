import React, { useState } from 'react';
import common from 'gateway-common';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AssignAdvisorDropdown from '../AssignAdvisorDropdown';
import { getCancelIcon } from '../../svgs';
import { needStatusToSubStatusMapping } from '../../utils';
import { CLOSED_STATUS_TYPE } from '../../constants';
import './NeedStatusLightbox.scss';

const { components: { FormSelect, RadioButton } } = common;

const NeedStatusLightbox = ({
  statusOptions,
  needOptions,
  currentStatus,
  closeLightBox,
  assignedAdvisors,
  assignAdvisors,
  unassignAdvisors,
  subStatusOptions,
  addNeedStatus,
  currentNeed,
  referralUpdated,
}) => {
  const [message, setMessage] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);
  const [selectedSubStatus, setSelectedSubStatus] = useState(null);
  const [needsToUpdate, setNeedsToUpdate] = useState([currentNeed]);

  const subStatusTypesOfStatus = needStatusToSubStatusMapping(selectedStatus.type);
  const filteredSubStatusOptions = subStatusOptions
    .filter((option) => subStatusTypesOfStatus.includes(option.type));

  const handleSelectStatusChange = (status) => {
    if (selectedStatus.type !== status.type) {
      setSelectedSubStatus(null);
      setSelectedStatus(status);
      setNeedsToUpdate(needsToUpdate.filter(
        (needToUpdate) => (
          (status.needTypes.includes(needToUpdate.needTypeID)
          && needToUpdate.statusOrder < status.order)
          || needToUpdate.needID === currentNeed.needID
        ),
      ));
    }
  };

  const handleSubmitNeedStatusChange = async () => {
    const needIDs = needsToUpdate.map((need) => need.needID);
    await addNeedStatus({
      statusID: selectedStatus.id,
      subStatusID: selectedSubStatus?.value || null,
      message,
      needIDs,
      isInternal: selectedStatus.isInternal,
    });
    referralUpdated();
    closeLightBox();
    window.location.reload()
  };

  const messageChangeHandler = (event) => {
    const { target: { value } } = event;
    setMessage(value);
  };

  const needCheckBoxChangeHandler = (need) => {
    if (needsToUpdate.some((needToUpdate) => needToUpdate.needID === need.needID)) {
      setNeedsToUpdate(needsToUpdate.filter(
        (needToUpdate) => needToUpdate.needID !== need.needID,
      ));
    } else {
      setNeedsToUpdate([...needsToUpdate, need]);
    }
  };

  const isNeedChecked = (needID) => needsToUpdate.some((need) => need.needID === needID);

  const isNeedDisabled = (need) => (
    !selectedStatus.needTypes.includes(need.needTypeID)
    || currentNeed.needID === need.needID
    || selectedStatus.order < need.statusOrder
  );

  const isStatusClosedExternal = (currentStatus.type === CLOSED_STATUS_TYPE)
    && !currentStatus.isInternal;
  const isStatusReopenable = isStatusClosedExternal || currentStatus.isInternal;

  return (
    <Modal
      isOpen
      onRequestClose={closeLightBox}
      className="need-status-modal"
      overlayClassName="need-status-modal-overlay"
      parentSelector={() => document.querySelector('#gateway-platform')}
      ariaHideApp={false}
    >
      <button className="close-modal-btn" type="button" onClick={closeLightBox}>
        {getCancelIcon()}
      </button>
      <div
        id="need-status-modal-content"
        data-testid="need-status-modal-content"
        className="modal-inner"
      >
        <div className="left-section">
          <div
            className="need-status-list"
            data-testid="need-status-list-external"
          >
            <p className="section-label">External Need Status</p>
            {statusOptions.map((status) => !status.isInternal && (
              <RadioButton
                dataTestID={`status-${status.id}`}
                key={status.label}
                label={status.label}
                name="status"
                isChecked={status.id === selectedStatus.id}
                isDisabled={status.isDisabled}
                clickHandler={() => handleSelectStatusChange(status)}
              />
            ))}
          </div>
          {isStatusReopenable && (
            <div
              className="need-status-list"
              data-testid="need-status-list-internal"
            >
              <p className="section-label">Internal Need Status</p>
              {statusOptions.map((status) => status.isInternal && (
                <RadioButton
                  dataTestID={`status-${status.id}`}
                  key={status.label}
                  label={status.label}
                  name="status"
                  isChecked={status.id === selectedStatus.id}
                  isDisabled={
                    status.isDisabled
                    || (isStatusClosedExternal && status.type === CLOSED_STATUS_TYPE)
                  }
                  clickHandler={() => handleSelectStatusChange(status)}
                />
              ))}
            </div>
          )}
          {needOptions.length > 1 && (
          <div className="need-options">
            <p className="section-label">Related Needs</p>
            {needOptions.map((need) => (
              <label
                className={`need-checkbox${isNeedDisabled(need) ? ' disabled' : ''}`}
                htmlFor={`checkbox-${need.needID}`}
                key={`need-checkbox-${need.needID}`}
              >
                <input
                  id={`checkbox-${need.needID}`}
                  className="checkbox"
                  type="checkbox"
                  checked={isNeedChecked(need.needID)}
                  disabled={isNeedDisabled(need)}
                  onChange={() => needCheckBoxChangeHandler(need)}
                  data-testid={`checkbox-${need.needID}`}
                />
                {need.label}
              </label>
            ))}
          </div>
          )}
        </div>
        <div className="right-section">
          <div className="advisor-dropdown" data-testid="advisor-section">
            <p className="section-label">Assign Advisor</p>
            <div className="care-advisor-dropdown">
              <AssignAdvisorDropdown
                assignAdvisors={assignAdvisors}
                unassignAdvisors={unassignAdvisors}
                assignedAdvisors={assignedAdvisors}
              />
            </div>
          </div>
          <p className="section-label">Sub Status</p>
          <div className="status-dropdown">
            <FormSelect
              id="sub-status-select"
              options={filteredSubStatusOptions}
              selectedOption={selectedSubStatus}
              onChangeHandler={setSelectedSubStatus}
              placeholder="Select a Sub Status..."
            />
          </div>
          <p className="section-label">Public Status Message</p>
          <textarea
            placeholder="Add a message to this status change here..."
            onChange={messageChangeHandler}
            value={message}
          />
          <button
            type="button"
            className="action-button green submit-button"
            disabled={!(message.length && assignedAdvisors?.length)}
            onClick={handleSubmitNeedStatusChange}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

NeedStatusLightbox.propTypes = {
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      order: PropTypes.number.isRequired,
    }),
  ).isRequired,
  currentStatus: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    order: PropTypes.number.isRequired,
    isInternal: PropTypes.bool.isRequired,
  }).isRequired,
  closeLightBox: PropTypes.func.isRequired,
  assignedAdvisors: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  assignAdvisors: PropTypes.func.isRequired,
  unassignAdvisors: PropTypes.func.isRequired,
  subStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  addNeedStatus: PropTypes.func.isRequired,
  currentNeed: PropTypes.shape({
    needID: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  needOptions: PropTypes.arrayOf(
    PropTypes.shape({
      needID: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      needTypeID: PropTypes.number.isRequired,
      statusOrder: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

NeedStatusLightbox.defaultProps = {
  assignedAdvisors: [],
  subStatusOptions: [],
};

export default NeedStatusLightbox;
