import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import ErrorBoundary from '../../components/ErrorBoundary';
import ActionCard from '../../components/ActionCard';
import { getPipelineOverview } from '../../services/endpoints';
import { actionCardFields } from '../../constants';
import { getIconFromPipelineType } from '../../utils';
import iconUnassignedReferral from '../../assets/icon-unassigned-referral.png';
import iconOngoingReferral from '../../assets/icon-ongoing-referral.png';
import iconPendingReferral from '../../assets/icon-pending-referral.png';
import './PipelineHome.scss';

const {
  constants: {
    REFERRAL_STATUS_ID_UNASSIGNED,
    REFERRAL_STATUS_ID_ONGOING,
    REFERRAL_STATUS_ID_PENDING,
  },
} = common;

const PipelineHome = ({ setAllReferralsFilters }) => {
  const [pipelines, setPipelines] = useState([]);

  const getPipelinesData = async () => {
    const { pipelines: fetchedPipelines } = await getPipelineOverview();
    setPipelines(fetchedPipelines);
  };

  useEffect(() => {
    if (!pipelines.length) {
      getPipelinesData();
    }
  }, [pipelines]);

  const history = useHistory();

  const onClickHandler = (pipelineID, referralStatusID) => {
    const filterSettings = {
      pipelineID,
      referralStatusID,
    };
    setAllReferralsFilters(filterSettings);
    history.push('/referrals');
  };

  const mapPipelinesToDashboard = (dashboardPipelines) => {
    const summaryValueUnassigned = dashboardPipelines
      .reduce((total, pipeline) => total + pipeline.numberOfUnassignedReferrals, 0);
    const summaryValueAll = dashboardPipelines
      .reduce((total, pipeline) => total + pipeline.numberOfAllReferrals, 0);
    const summaryValuePending = dashboardPipelines
      .reduce((total, pipeline) => total + pipeline.numberOfPendingReferrals, 0);

    const isActiveUnassigned = summaryValueUnassigned > 0;
    const isActiveAll = summaryValueAll > 0;
    const isActivePending = summaryValuePending > 0;

    const dashboardCards = [
      // unassigned
      {
        onClickHandlerActionCardButton: () => onClickHandler(null, REFERRAL_STATUS_ID_UNASSIGNED),
        isActive: isActiveUnassigned,
        isPendingReferral: false,
        icon: {
          src: iconUnassignedReferral,
          width: '99px',
          height: '71px',
        },
        headingText: actionCardFields.unassigned.headingText,
        summaryValue: summaryValueUnassigned,
        summaryText: actionCardFields.unassigned.summaryText,
        buttonText: actionCardFields.unassigned.buttonText,
        dataArray: [],
      },
      // all
      {
        onClickHandlerActionCardButton: () => onClickHandler(null, null),
        isActive: isActiveAll,
        isPendingReferral: false,
        icon: {
          src: iconOngoingReferral,
          width: '96px',
          height: '77px',
        },
        headingText: actionCardFields.all.headingText,
        summaryValue: summaryValueAll,
        summaryText: actionCardFields.all.summaryText,
        buttonText: actionCardFields.all.buttonText,
        dataArray: [],
      },
      // pending
      {
        onClickHandlerActionCardButton: () => onClickHandler(null, REFERRAL_STATUS_ID_PENDING),
        isActive: isActivePending,
        isPendingReferral: true,
        icon: {
          src: iconPendingReferral,
          width: '90px',
          height: '77px',
        },
        headingText: actionCardFields.pending.headingText,
        summaryValue: summaryValuePending,
        summaryText: actionCardFields.pending.summaryText,
        buttonText: actionCardFields.pending.buttonText,
        dataArray: [],
      },
    ];

    pipelines.forEach((pipeline) => {
      const {
        pipelineID,
        pipelineLabel,
        numberOfUnassignedReferrals,
        numberOfPendingReferrals,
        numberOfAllReferrals,
      } = pipeline;

      // unassigned
      dashboardCards[0].dataArray.push({
        key: `${pipelineLabel + pipelineID}unassigned`,
        value: numberOfUnassignedReferrals,
        label: pipelineLabel,
        linkText: actionCardFields.unassigned.linkText,
        isActive: numberOfUnassignedReferrals > 0,
        isPendingReferral: false,
        onClickLinkHandler: () => onClickHandler(pipelineID, REFERRAL_STATUS_ID_UNASSIGNED),
      });

      // all
      dashboardCards[1].dataArray.push({
        key: `${pipelineLabel + pipelineID}all`,
        value: numberOfAllReferrals,
        label: pipelineLabel,
        linkText: actionCardFields.all.linkText,
        isActive: numberOfAllReferrals > 0,
        isPendingReferral: false,
        onClickLinkHandler: () => onClickHandler(pipelineID, null),
      });

      // pending
      dashboardCards[2].dataArray.push({
        key: `${pipelineLabel + pipelineID}pending`,
        value: numberOfPendingReferrals,
        label: pipelineLabel,
        linkText: actionCardFields.pending.linkText,
        isActive: numberOfPendingReferrals > 0,
        isPendingReferral: true,
        onClickLinkHandler: () => onClickHandler(pipelineID, REFERRAL_STATUS_ID_PENDING),
      });
    });

    return dashboardCards.map((dashboardCard) => {
      const {
        onClickHandlerActionCardButton,
        isActive,
        isPendingReferral,
        icon,
        headingText,
        summaryValue,
        summaryText,
        buttonText,
        dataArray,
      } = dashboardCard;

      return (
        <ActionCard
          key={`dashboard-card-${headingText}`}
          actionCardType="dashboard"
          onClickHandlerActionCardButton={onClickHandlerActionCardButton}
          isActive={isActive}
          isPendingReferral={isPendingReferral}
          icon={icon}
          headingText={headingText}
          summaryValue={summaryValue}
          summaryText={summaryText}
          buttonText={buttonText}
          dataArray={dataArray}
        />
      );
    });
  };

  const actionCardsDashboard = mapPipelinesToDashboard(pipelines);

  const actionCardsPipelines = pipelines.map((pipeline) => {
    const {
      pipelineID,
      pipelineType,
      pipelineLabel,
      numberOfUnassignedReferrals,
      numberOfOngoingReferrals,
      numberOfPendingReferrals,
    } = pipeline;

    const summaryValue = numberOfUnassignedReferrals
      + numberOfOngoingReferrals
      + numberOfPendingReferrals;

    const isActive = summaryValue > 0;

    const dataArray = [
      // unassigned
      {
        key: `${pipelineID + pipelineLabel}-unassigned`,
        value: numberOfUnassignedReferrals,
        label: actionCardFields.pipeline.referralStatuses.unassigned,
        linkText: actionCardFields.unassigned.linkText,
        isActive: numberOfUnassignedReferrals > 0,
        isPendingReferral: false,
        onClickLinkHandler: () => onClickHandler(pipelineID, REFERRAL_STATUS_ID_UNASSIGNED),
      },
      // ongoing
      {
        key: `${pipelineID + pipelineLabel}-ongoing`,
        value: numberOfOngoingReferrals,
        label: actionCardFields.pipeline.referralStatuses.ongoing,
        linkText: actionCardFields.all.linkText,
        isActive: numberOfOngoingReferrals > 0,
        isPendingReferral: false,
        onClickLinkHandler: () => onClickHandler(pipelineID, REFERRAL_STATUS_ID_ONGOING),
      },
      // pending
      {
        key: `${pipelineID + pipelineLabel}-pending`,
        value: numberOfPendingReferrals,
        label: actionCardFields.pipeline.referralStatuses.pending,
        linkText: actionCardFields.pending.linkText,
        isActive: numberOfPendingReferrals > 0,
        isPendingReferral: true,
        onClickLinkHandler: () => onClickHandler(pipelineID, REFERRAL_STATUS_ID_PENDING),
      },
    ];

    const icon = getIconFromPipelineType(pipelineType);

    return (
      <ActionCard
        key={`pipeline-card-${pipelineID}-${pipelineLabel}`}
        actionCardType="pipeline"
        onClickHandlerActionCardButton={() => onClickHandler(pipelineID, null)}
        isActive={isActive}
        isPendingReferral={false}
        icon={icon}
        headingText={pipelineLabel}
        summaryValue={summaryValue}
        summaryText={actionCardFields.pipeline.summaryText}
        buttonText={actionCardFields.pipeline.buttonText}
        dataArray={dataArray}
      />
    );
  });

  return (
    <div
      data-testid="pipeline-home"
      className="pipeline-home has-max-width"
    >
      <div className="pipeline-home-dashboard">
        <h1
          data-testid="pipeline-home-heading"
          className="title-1"
        >
          Referral Dashboard
        </h1>
        <div
          data-testid="action-cards-dashboard"
          className="action-cards-dashboard"
        >
          {actionCardsDashboard}
        </div>
      </div>
      <div className="pipeline-home-pipelines">
        <h1
          data-testid="pipeline-home-heading"
          className="title-1"
        >
          All Pipelines
        </h1>
        <div
          data-testid="action-cards-pipelines"
          className="action-cards-pipelines"
        >
          {(pipelines.length > 0) && actionCardsPipelines}
        </div>
      </div>
    </div>
  );
};

PipelineHome.propTypes = {
  setAllReferralsFilters: PropTypes.func.isRequired,
};

const wrapWithErrorBoundary = (props) => (
  <ErrorBoundary>
    <PipelineHome {...props} />
  </ErrorBoundary>
);

export default wrapWithErrorBoundary;
