import React from 'react';
import PropTypes from 'prop-types';
import './Tabs.scss';

const Tabs = ({ tabsContents }) => {
  const tabsContainer = tabsContents.map((tabContents, index) => {
    const { tabText, tabClickHandler, isSelected } = tabContents;

    return (
      <div
        key={tabText}
        className={`tab-contents ${isSelected ? 'tab-selected body-1-bold' : 'tab-unselected body-1'}`}
        data-testid={`tab-contents-${index}`}
        onClick={() => tabClickHandler()}
        onKeyPress={(e) => { if (e.code === 'Enter') tabClickHandler(); }}
        role="button"
        tabIndex={0}
      >
        {tabText}
      </div>
    );
  });

  return (
    <div className="tabs-container" data-testid="tabs-container">
      {tabsContainer}
    </div>
  );
};

Tabs.propTypes = {
  tabsContents: PropTypes.arrayOf(
    PropTypes.shape({
      tabText: PropTypes.string,
      tabClickHandler: PropTypes.func,
      isSelected: PropTypes.bool,
    }),
  ).isRequired,
};

export default Tabs;
