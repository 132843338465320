import {
  getAllReferrals,
  updatePriorityNeedID,
  assignAdvisorsToReferral,
  archiveReferral,
} from '../services/endpoints';
import { CLOSED_STATUS_TYPE } from '../constants';

// constants
export const SET_ALL_REFERRALS_FILTERS = 'SET_ALL_REFERRALS_FILTERS';
export const SET_REFERRALS = 'SET_REFERRALS';
export const RESET_ALL_REFERRALS = 'RESET_ALL_REFERRALS';
export const SET_TOTAL_CLOSED_REFERRALS_PAGES = 'SET_TOTAL_CLOSED_REFERRALS_PAGES';
export const FETCHING_REFERRALS = 'FETCHING_REFERRALS';

// actions
export const setAllReferralsFilters = (filterSettings) => ({
  type: SET_ALL_REFERRALS_FILTERS,
  filterSettings,
});

export const setReferrals = (referrals) => ({
  type: SET_REFERRALS,
  referrals,
});
export const resetAllReferrals = () => ({
  type: RESET_ALL_REFERRALS,
});

export const setTotalClosedReferralsPages = (totalClosedReferralsPages) => ({
  type: SET_TOTAL_CLOSED_REFERRALS_PAGES,
  totalClosedReferralsPages,
});

export const fetchingReferrals = () => ({
  type: FETCHING_REFERRALS,
});

export const fetchReferrals = (queryString) => (
  async (dispatch) => {
    dispatch(fetchingReferrals());
    const referrals = await getAllReferrals(queryString);
    const {
      unassignedReferrals,
      ongoingReferrals,
      pendingReferrals,
      closedReferrals,
      totalClosedReferralsPages,
      followupReferrals,
      status,
    } = referrals;
    if (status === 499) return;
    dispatch(setReferrals({
      unassigned: unassignedReferrals,
      ongoing: ongoingReferrals,
      pending: pendingReferrals,
      closed: closedReferrals,
      followup: followupReferrals,
    }));
    dispatch(setTotalClosedReferralsPages(totalClosedReferralsPages));
  }
);

export const updatePriorityNeed = (referralStatus, referralID, priorityNeedID) => (
  async (dispatch, getState) => {
    const { referrals: { referrals } } = getState();
    await updatePriorityNeedID(referralID, priorityNeedID);
    const referralsToUpdate = referrals[referralStatus];
    const referralIndex = referralsToUpdate
      .findIndex((referral) => referral.referralID === referralID);
    const referralToUpdate = referralsToUpdate[referralIndex];
    const updatedReferral = {
      ...referralToUpdate,
      priorityNeedID,
    };
    const updatedReferrals = referralsToUpdate.slice();
    updatedReferrals.splice(referralIndex, 1, updatedReferral);
    dispatch(
      setReferrals({
        ...referrals,
        [referralStatus]: updatedReferrals,
      }),
    );
  }
);

export const assignAdvisors = (referralID, accountIDsToAssign) => (
  async (dispatch, getState) => {
    await assignAdvisorsToReferral(accountIDsToAssign, referralID);
    const { referrals: { referrals } } = getState();
    const { unassigned } = referrals;
    const referralIndex = unassigned.findIndex((referral) => referral.referralID === referralID);
    const referralToUpdate = unassigned[referralIndex];
    const updatedReferral = {
      ...referralToUpdate,
      assignedToAccountIDs: accountIDsToAssign,
    };
    const isClosed = updatedReferral.needs.every((need) => {
      const currentStatus = need.needStatuses[need.needStatuses.length - 1];
      const { statusType } = currentStatus;
      return statusType === CLOSED_STATUS_TYPE;
    });
    const referralStatus = isClosed ? 'closed' : 'ongoing';
    const updatedUnassignedReferrals = unassigned.slice();
    updatedUnassignedReferrals.splice(referralIndex, 1);
    const referralsToAddTo = referrals[referralStatus].slice();
    referralsToAddTo.unshift(updatedReferral);
    dispatch(
      setReferrals({
        ...referrals,
        unassigned: updatedUnassignedReferrals,
        [referralStatus]: referralsToAddTo,
      }),
    );
  }
);

export const archive = (referralStatus, referralID) => (
  async (dispatch, getState) => {
    const { referrals: { referrals } } = getState();
    await archiveReferral(referralID);
    const referralsToUpdate = referrals[referralStatus];
    const updatedReferrals = referralsToUpdate.filter(
      (referral) => referral.referralID !== referralID,
    );
    dispatch(
      setReferrals({
        ...referrals,
        [referralStatus]: updatedReferrals,
      }),
    );
  }
);

// action handlers
const ACTION_HANDLERS = {
  [SET_ALL_REFERRALS_FILTERS]: (state, action) => ({
    ...state,
    filterSettings: {
      ...state.filterSettings,
      ...action.filterSettings,
    },
  }),
  [SET_REFERRALS]: (state, action) => ({
    ...state,
    referrals: action.referrals,
    fetchingReferrals: false,
  }),
  [RESET_ALL_REFERRALS]: (state, action) => ({
    ...state,
    referrals: {
      unassigned: [],
    ongoing: [],
    pending: [],
    closed: [],
    followup: []
    },
    fetchingReferrals: false,
  }),
  [SET_TOTAL_CLOSED_REFERRALS_PAGES]: (state, action) => ({
    ...state,
    totalClosedReferralsPages: action.totalClosedReferralsPages,
  }),
  [FETCHING_REFERRALS]: (state) => ({
    ...state,
    fetchingReferrals: true,
  }),
};

const initialState = {
  filterSettings: {
    pipelineID: null,
    referralStatusID: null,
    careAdvisorID: null,
    needStatusID: null,
    urgencyID: null,
    referrerID: null,
    sortBy: null,
    dateStart: '',
    dateEnd: '',
  },
  referrals: {
    unassigned: [],
    ongoing: [],
    pending: [],
    closed: [],
    followup: [],
  },
  totalClosedReferralsPages: 0,
  fetchingReferrals: false,
};

export default function referralsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
