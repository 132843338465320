import { connect } from 'react-redux';
import EditPersonalInfoLightbox from './EditPersonalInfoLightbox';
import { closeLightBox } from '../../reducers/lightbox';
import { editPersonalInfo } from '../../reducers/patientInfo';
import { updateReferral } from '../../reducers/referral';

const mapStateToProps = (state) => {
  const {
    patientInfo: { patient },
    referral: { referral: { referralID, language } },
    appData: { sexTypes },
  } = state;

  const sexOptions = sexTypes?.map(({ id, label }) => ({
    value: id,
    label,
  }));

  return {
    personalInfo: {
      ...patient,
      language,
    },
    referralID,
    sexOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeLightBox: () => dispatch(closeLightBox()),
  confirmButtonHandler: ({ personID, referralID, personalInfo }) => dispatch(
    editPersonalInfo({ personID, referralID, personalInfo }),
  ),
  updateLanguage: ({ language }) => dispatch(updateReferral({ language })),
});

const EditPersonalInfoLightboxContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditPersonalInfoLightbox);

export default EditPersonalInfoLightboxContainer;
