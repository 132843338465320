import React, { useState } from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import EditLightBox from '../EditLightbox';
import './EditAccountModal.scss';
import { DEPARTMENT_LIST } from './../../constants';
import { formatPhoneInput, sanitizeNumber } from '../../utils';

const {
  components: {
    Input,
    FormSelect,
  },
} = common;

const EditAccountModal = ({
  account,
  pipelines,
  sites,
  updateAccount,
  closeLightBox,
}) => {
  const [firstName, setFirstName] = useState(account.firstName);
  const [lastName, setLastName] = useState(account.lastName);
  const [email, setEmail] = useState(account.email);
  const [phone, setPhone] = useState(account.phone);
  const [departmentValue,setDepartment]=useState(account.department);
  const [pipelineID, setPipelineID] = useState(account.pipelineID);
  const [siteID, setSiteID] = useState(account.siteID);

  const pipelineOptions = pipelines.map(({ id, label }) => ({ value: id, label }));
  const selectedPipelineOption = pipelineOptions.find(({ value }) => value === pipelineID);

  const siteOptions = sites
    .filter((site) => site.pipeline_id === pipelineID)
    .map(({ id, label }) => ({ value: id, label }));
  const selectedSiteOption = siteOptions.find(({ value }) => value === siteID);

  const selectedDepartment= DEPARTMENT_LIST.find(({value,label})=>label === departmentValue)
  const department=selectedDepartment?.label
  const isConfirmDisabled = !(
    firstName
    && lastName
    && email
    && phone
    && (selectedSiteOption || !account.siteID)
  );
  const departmentField=localStorage.getItem('activeUserTab');
  const confirmButtonHandler = async () => {
    await updateAccount({
      accountID: account.accountID,
      firstName,
      lastName,
      email,
      department,
      phone,
      siteID,
    });
    closeLightBox();
  };

  return (
    <EditLightBox
      headerLabel="Account"
      isOpen
      confirmButtonHandler={confirmButtonHandler}
      closeButtonHandler={closeLightBox}
      isConfirmDisabled={isConfirmDisabled}
    >
      <div className="edit-account-modal">
        <div className="account-item">
          <Input
            type="text"
            value={firstName}
            label="First Name"
            handleOnChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="account-item">
          <Input
            type="text"
            value={lastName}
            handleOnChange={(e) => setLastName(e.target.value)}
            label="Last Name"
          />
        </div>
        <div className="account-item">
          <Input
            type="text"
            value={email}
            handleOnChange={(e) => setEmail(e.target.value)}
            label="Email"
          />
        </div>
        <div className="account-item">
          <Input
            type="text"
            value={formatPhoneInput(sanitizeNumber(phone))}
            handleOnChange={(e) => {
              setPhone(formatPhoneInput(e.target.value));
            }}
            label="Phone"
          />
        </div>
        {pipelineID && (
          <div className="account-item">
            <FormSelect
              options={pipelineOptions}
              label="Pipeline"
              selectedOption={selectedPipelineOption}
              onChangeHandler={(option) => setPipelineID(option.value)}
            />
          </div>
        )}
        {pipelineID && (
          <div className="account-item">
            <FormSelect
              options={siteOptions}
              label="Site"
              selectedOption={selectedSiteOption}
              onChangeHandler={(option) => setSiteID(option.value)}
            />
          </div>
        )}
        {departmentField ==='referrer' ? (
          <div className="account-item">
            <FormSelect
              options={DEPARTMENT_LIST}
              label="Department"
              selectedOption={selectedDepartment}
              onChangeHandler={(option) => setDepartment(option.label)}
            />
          </div>
        ):null}
      </div>
    </EditLightBox>
  );
};

EditAccountModal.propTypes = {
  account: PropTypes.shape({
    accountID: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    pipelineID: PropTypes.number.isRequired,
    siteID: PropTypes.string.isRequired,
  }).isRequired,
  pipelines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  updateAccount: PropTypes.func.isRequired,
  closeLightBox: PropTypes.func.isRequired,
};

export default EditAccountModal;
