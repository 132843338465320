import React from 'react';

const getCogIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 15 14">
    <path d="M12.268 5.291l-.36-.84s.836-1.86.762-1.931l-1.106-1.065c-.076-.073-1.962.775-1.962.775l-.87-.346S7.966 0 7.86 0H6.3c-.11 0-.82 1.888-.82 1.888l-.868.347s-1.925-.806-1.999-.734L1.508 2.567c-.076.073.802 1.894.802 1.894l-.359.84S0 6.042 0 6.143V7.65c0 .105 1.956.792 1.956.792l.359.839s-.835 1.857-.762 1.928l1.105 1.068c.075.072 1.961-.776 1.961-.776l.87.347s.769 1.883.875 1.883h1.56c.108 0 .82-1.888.82-1.888l.87-.348s1.922.807 1.996.736l1.106-1.066c.077-.074-.804-1.894-.804-1.894l.358-.84s1.952-.743 1.952-.844V6.081c0-.105-1.954-.79-1.954-.79zM9.39 6.866c0 1.212-1.023 2.2-2.28 2.2-1.255 0-2.28-.988-2.28-2.2 0-1.213 1.025-2.2 2.28-2.2 1.258.001 2.28.987 2.28 2.2z" />
  </svg>
);

const getHomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 15 15">
    <path d="M13.996 6.429L7.42.124c-.172-.165-.444-.165-.615 0L.219 6.435C.08 6.575 0 6.766 0 6.963c0 .408.332.74.74.74h1.038v5.63c0 .49.398.889.889.889h2.518c.246 0 .445-.2.445-.445V9.926c0-.082.066-.149.148-.149h2.666c.082 0 .149.067.149.149v3.851c0 .246.199.445.444.445h2.519c.49 0 .888-.398.888-.889v-5.63h1.037c.409 0 .741-.332.741-.74 0-.197-.08-.388-.226-.534z" />
  </svg>
);

const getSearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 14">
    <path d="M12.777 12.083L9.636 8.8c.807-.966 1.25-2.18 1.25-3.444C10.886 2.403 8.496 0 5.556 0 2.62 0 .229 2.403.229 5.356c0 2.954 2.39 5.356 5.329 5.356 1.103 0 2.154-.334 3.053-.969l3.165 3.309c.132.138.31.214.5.214.181 0 .353-.069.483-.195.276-.267.285-.71.019-.988zM5.557 1.397c2.172 0 3.939 1.776 3.939 3.96 0 2.182-1.767 3.958-3.94 3.958-2.171 0-3.938-1.776-3.938-3.959s1.767-3.959 3.939-3.959z" />
  </svg>
);

const getBellIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 15 17">
    <g>
      <path d="M7.34 16.975c1.014 0 1.836-.821 1.836-1.835h-3.67c0 1.014.82 1.835 1.834 1.835zM12.444 11.636v-4.31c0-2.65-1.453-4.86-4-5.447v-.586C8.444.577 7.85 0 7.111 0S5.778.577 5.778 1.293v.586c-2.547.586-4 2.797-4 5.448v4.31L0 13.36v.862h14.222v-.862l-1.778-1.724z" />
    </g>
  </svg>
);

const getPlusIcon = (fill) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 8 8">
    <path fill={fill || '#FFFFFF'} d="M7.444 3.268H4.72V.545C4.72.244 4.477 0 4.176 0h-.363c-.3 0-.545.244-.545.545v2.723H.545c-.301 0-.545.244-.545.545v.363c0 .3.244.545.545.545h2.723v2.723c0 .3.244.545.545.545h.363c.3 0 .545-.244.545-.545V4.72h2.723c.3 0 .545-.244.545-.545v-.363c0-.3-.244-.545-.545-.545z" />
  </svg>
);

const getPaperclipIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="12.5" viewBox="0 0 11 10">
    <path fill="#2730D3" d="M1.643 8.675c-1.33-1.33-1.33-3.492 0-4.821L5.018.479c.178-.178.465-.177.643 0 .177.178.177.465 0 .643L2.286 4.497c-.975.974-.975 2.56 0 3.535.975.975 2.56.975 3.535 0l3.536-3.535c.62-.62.62-1.63 0-2.25-.62-.62-1.63-.62-2.25 0L3.893 5.46c-.266.266-.266.698 0 .964s.698.266.964 0l3.054-3.053c.177-.178.465-.178.642 0 .178.177.178.465 0 .643L5.5 7.068c-.62.62-1.63.62-2.25 0-.62-.62-.62-1.63 0-2.25l3.214-3.214C7.44.63 9.024.629 10 1.604c.975.975.974 2.561 0 3.536L6.464 8.675c-1.33 1.33-3.492 1.33-4.82 0z" />
  </svg>
);

const getUploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="40" viewBox="0 0 44 32">
    <path fill="#E3E7F5" d="M35.39 11.028C34.802 4.854 29.595.018 23.259.018c-4.882 0-9.085 2.857-11.028 6.988-.651-.244-1.356-.371-2.08-.371-3.362 0-6.083 2.72-6.083 6.084 0 .732.126 1.437.37 2.088C1.79 16.353 0 19.237 0 22.527c0 4.772 3.751 9.175 8.461 9.437H18.603v-9.528h-4.357c-.886 0-1.338-1.067-.723-1.7l7.72-7.945c.398-.407 1.049-.407 1.437 0l7.72 7.946c.615.632.172 1.7-.723 1.7H25.32v9.545h9.012c5.36-.38 9.6-5.09 9.6-10.531.01-5.09-3.688-9.591-8.542-10.423z" />
  </svg>

);

const getTrashIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10">
    <g>
      <path d="M6.458 1.042h-1.25L5.022.669c-.141-.282-.43-.46-.745-.46H3.223c-.315 0-.604.178-.745.46l-.186.373h-1.25c-.46 0-.834.373-.834.833v.417h7.084v-.417c0-.46-.373-.833-.834-.833z" />
      <path d="M6.458.833h-1.12l-.13-.257C5.03.22 4.673 0 4.276 0H3.223c-.397 0-.753.22-.931.576l-.13.257h-1.12C.467.833 0 1.301 0 1.875v.417c0 .115.093.208.208.208h7.084c.115 0 .208-.093.208-.208v-.417c0-.574-.467-1.042-1.042-1.042zm.625 1.25H.417v-.208c0-.345.28-.625.625-.625h1.25c.078 0 .15-.045.186-.115l.187-.373c.106-.213.32-.345.559-.345h1.053c.238 0 .452.132.559.345l.186.373c.035.07.108.115.186.115h1.25c.345 0 .625.28.625.625v.208zM6.875 2.917H.625c-.115 0-.208.093-.208.208v5.417C.417 9.346 1.07 10 1.875 10h3.75c.804 0 1.458-.654 1.458-1.458V3.125c0-.115-.093-.208-.208-.208zM2.083 8.542c0 .115-.093.208-.208.208-.115 0-.208-.093-.208-.208v-5c0-.115.093-.209.208-.209.115 0 .208.094.208.209v5zm1.25 0c0 .115-.093.208-.208.208-.115 0-.208-.093-.208-.208v-5c0-.115.093-.209.208-.209.115 0 .208.094.208.209v5zm1.25 0c0 .115-.093.208-.208.208-.115 0-.208-.093-.208-.208v-5c0-.115.093-.209.208-.209.115 0 .208.094.208.209v5zm1.25 0c0 .115-.093.208-.208.208-.115 0-.208-.093-.208-.208v-5c0-.115.093-.209.208-.209.115 0 .208.094.208.209v5z" />
    </g>
  </svg>
);

const getEditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 8 8">
    <g>
      <path d="M4.974 1.328L6.601 2.955 2.482 7.074.856 5.447zM7.835.936L7.11.21c-.28-.28-.736-.28-1.017 0l-.695.695 1.627 1.627.81-.81c.218-.218.218-.569 0-.786zM.005 7.734c-.03.133.09.253.224.22l1.813-.44L.415 5.888l-.41 1.847z" />
    </g>
  </svg>
);

const getPencilIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
    <path d="M4.974 1.333L6.6 2.96 2.482 7.078.856 5.451l4.118-4.118zM7.835.94L7.11.215c-.28-.28-.736-.28-1.017 0L5.398.91l1.627 1.627.81-.81c.218-.218.218-.57 0-.787zM.005 7.738c-.03.133.09.253.224.22l1.813-.44L.415 5.893l-.41 1.846z" />
  </svg>
);

const getArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="8" viewBox="0 0 17 8">
    <path d="M15.871 3.355H2.208L4.47 1.102c.253-.25.254-.66.002-.912-.251-.253-.66-.253-.912-.002L.19 3.543c-.253.252-.253.661 0 .914l3.37 3.355c.253.251.662.25.913-.002.252-.253.25-.661-.002-.912L2.208 4.645H15.87c.356 0 .645-.289.645-.645 0-.356-.289-.645-.645-.645z" />
  </svg>
);

const getImageIcon = (fill) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
    <path fill={fill || '#FFF'} d="M7.111 0H.89C.398 0 0 .398 0 .889V7.11C0 7.602.398 8 .889 8H7.11C7.602 8 8 7.602 8 7.111V.89C8 .398 7.602 0 7.111 0zM.89 6.667l1.555-2 1.112 1.335L5.11 4l2 2.667H.89z" />
  </svg>
);

const getCancelIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
    <path d="M6.947 5.92L5.021 3.994 6.947 2.07c.213-.213.213-.558 0-.77l-.257-.257c-.212-.213-.557-.213-.77 0L3.994 2.967 2.07 1.042c-.213-.213-.558-.213-.77 0l-.257.256c-.213.213-.213.558 0 .77l1.925 1.926L1.042 5.92c-.213.213-.213.558 0 .77l.256.257c.213.213.558.213.77 0l1.926-1.926L5.92 6.947c.213.213.558.213.77 0l.257-.257c.213-.212.213-.557 0-.77z" />
  </svg>
);

export {
  getCogIcon,
  getHomeIcon,
  getSearchIcon,
  getBellIcon,
  getPlusIcon,
  getPaperclipIcon,
  getUploadIcon,
  getTrashIcon,
  getPencilIcon,
  getEditIcon,
  getArrowIcon,
  getImageIcon,
  getCancelIcon,
};
