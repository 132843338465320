/* eslint-disable brace-style */
import { getNeedTimeline, updateNeedStatus,deleteSubStatusMessage } from '../services/endpoints';
import { updateReferral } from './referral';

export const SET_NEED_TIMELINE = 'SET_NEED_TIMELINE';
export const UPDATE_NEED_TIMELINE = 'UPDATE_NEED_TIMELINE';
export const GET_NEED_TIMELINE = 'GET_NEED_TIMELINE';
export const DELETE_SUBSTATUS_MESSAGE= 'DELETE_SUBSTATUS_MESSAGE'
export const CLOSE_DELETE_POPUP='CLOSE_DELETE_POPUP'

const setTimeline = (data, currentNeedID) => ({
  type: SET_NEED_TIMELINE,
  data,
  currentNeedID,
});

const updateTimeline = (needStatuses) => ({
  type: UPDATE_NEED_TIMELINE,
  needStatuses,
});

export const getTimeline = () => ({
  type: GET_NEED_TIMELINE,
});

export const deleteSubStatusData =() =>({
  type: DELETE_SUBSTATUS_MESSAGE,
})

export const closeDeletePopup = (flag) =>({
  type:CLOSE_DELETE_POPUP,
  flag,
})

export const addNeedStatus = ({
  statusID,
  subStatusID,
  message,
  needIDs,
  isInternal,
}) => (
  async (dispatch, getState) => {
    const {
      referral: { referral: { needs, referralID } },
      needTimeline: {
        needTimeline: {
          needStatuses,
        },
        currentNeedID,
      },
      appData: { accountID },
    } = getState();

    const currentNeedStatus = needStatuses.slice().pop();
    const isStatusUpdated = currentNeedStatus.statusID !== statusID;
    const { needStatusID, messageID } = await updateNeedStatus({
      statusID,
      subStatusID: subStatusID || null,
      message,
      needIDs,
      referralID,
      isStatusUpdated,
      currentNeedID,
      isInternal,
    });

    const newMessage = {
      messageID,
      message,
      messageCreatedAt: new Date().toISOString(),
      subStatusID,
      careAdvisorAccountID: accountID,
    };

    if (!isStatusUpdated) {
      const updatedNeedStatus = {
        ...currentNeedStatus,
        messages: [
          newMessage,
          ...currentNeedStatus.messages,
        ],
      };
      const updatedNeedStatuses = [
        ...needStatuses.slice(0, -1),
        updatedNeedStatus,
      ];
      dispatch(updateTimeline(updatedNeedStatuses));
    } else {
      const newNeedStatus = {
        needStatusID,
        createdAt: new Date().toISOString(),
        statusID,
        messages: [newMessage],
        isInternal,
      };
      const updatedNeedStatuses = [
        ...needStatuses,
        newNeedStatus,
      ];
      dispatch(updateTimeline(updatedNeedStatuses));
    }

    const updatedNeeds = needs.map((need) => ({
      ...need,
      statusID: needIDs.includes(need.needID) ? statusID : need.statusID,
    }));
    dispatch(updateReferral({ needs: updatedNeeds }));
  }
);

export const getNeedTimelineByID = (needID) => (
  async (dispatch) => {
    dispatch(getTimeline());
    const timeline = await getNeedTimeline(needID);
    dispatch(setTimeline(timeline, needID));
  }
);

export const deleteSubStatus = (deletemessage,needID) => (
  async (dispatch) => {
    dispatch(deleteSubStatusData());
    await deleteSubStatusMessage(deletemessage);
    dispatch(getNeedTimelineByID(needID));
    dispatch(closeDeletePopup(false))
  }
);

const ACTION_HANDLERS = {
  [GET_NEED_TIMELINE]: (state) => ({
    ...state,
    gettingNeedTimeline: true,
  }),
  [SET_NEED_TIMELINE]: (state, action) => ({
    ...state,
    needTimeline: {
      needStatuses: action.data.needStatuses,
      timelineStatuses: action.data.timelineStatuses,
    },
    gettingNeedTimeline: false,
    currentNeedID: action.currentNeedID,
  }),
  [UPDATE_NEED_TIMELINE]: (state, action) => ({
    ...state,
    needTimeline: {
      ...state.needTimeline,
      needStatuses: action.needStatuses,
    },
  }),
  [CLOSE_DELETE_POPUP] : (state,action) =>({
    ...state,
    needTimeline:{
      ...state.needTimeline,
    },
    closeDeleteMessagePopup:action.flag
  })
};

const initialState = {
  needTimeline: {
    timelineStatuses: [],
    needStatuses: [],
  },
  gettingNeedTimeline: null,
  currentNeedID: null,
  closeDeleteMessagePopup:false,
};

export default function needTimelineReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
