import React from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import { format } from 'date-fns';
import { formatPhoneInput, getIconFromPipelineType, sanitizeNumber } from '../../utils';
import './ReferralDetailPanel.scss';
import Logo from '../../assets/logo-square.png';

const {
  svgs: {
    icons: {
      getReferralIcon, getPriorityNeedIcon, getCaseClosedIcon,
    },
  },
  components: {
    FormSelect,
    EditButton,
  },
} = common;

const ReferralDetailPanel = ({
  referral, urgencyType, assignedAdvisors,
  assignAdvisors, unassignAdvisors,
  isClosed, isPending, priorityNeedOptions,
  selectedPriorityNeed, updatePriorityNeed,
  openEditCareTeamLightBox, careAdvisors,
  closeLightBox, referrerAccounts, updateReferrers,
}) => {
  const {
    pipelineType,
    reason,
    additionalNote,
    referrers,
    createdAt,
    lastUpdated,
    referralID,
  } = referral;

  const getStatusTag = () => {
    if (assignedAdvisors.length === 0) {
      return (<div className="tag-unassigned" data-testid="tag-unassigned">Unassigned</div>);
    } if (isClosed) {
      return (<div className="tag-closed" data-testid="tag-closed">{`Closed ${format(new Date(lastUpdated), 'MM-dd-yyyy')}`}</div>);
    } if (isPending) {
      return (<div className="tag-pending" data-testid="tag-pending">Requires Update</div>);
    }
    return (<div className="tag-ongoing" data-testid="tag-ongoing">Ongoing</div>);
  };

  const selectPriorityNeed = async (option) => {
    const { value } = option;
    await updatePriorityNeed(value);
  };

  const { src } = getIconFromPipelineType(pipelineType);

  const getAssignedAdvisors = (_assignedAdvisors) => careAdvisors
    .filter((ca) => _assignedAdvisors.find((assigned) => assigned.value === ca.id));

  const getUpdatedReferrers = (selectedReferrers) => referrerAccounts
    .filter(
      (referrerAccount) => selectedReferrers.find((selected) => selected.id === referrerAccount.id),
    ).map((referrerAccount) => ({ ...referrerAccount, accountID: referrerAccount.id }));

  const getChangedAdvisors = (selected) => {
    // since email will be sent if we assign advisors multiple times,
    // we need to filter out the currently
    // assigned advisors from the list
    const previouslyAssigned = getAssignedAdvisors(assignedAdvisors);
    const assigned = careAdvisors.filter((ca) => (
      selected.find((selectedAdvisor) => selectedAdvisor.id === ca.id))
      && (!previouslyAssigned.find((selectedAdvisor) => selectedAdvisor.id === ca.id)));

    const unassigned = careAdvisors.filter(
      (ca) => !selected.find((selectedAdvisor) => selectedAdvisor.id === ca.id),
    );
    return [assigned, unassigned];
  };

  return (
    <div className="referral-detail-panel">
      <div className="left-section">
        <div className="top-section">
          <div className="icon-urgency-created-at">
            <div className="referral-icon">
              {getReferralIcon()}
              {isClosed && (
              <div className="case-closed" data-testid="case-closed-icon">
                {getCaseClosedIcon()}
              </div>
              )}
            </div>
            {urgencyType && (
            <div className={`urgency tag-${urgencyType?.toLowerCase()}`} data-testid={`tag-${urgencyType.toLowerCase()}`}>
              {urgencyType}
            </div>
            )}
            {createdAt && <span className="title-2">{`Created ${format(new Date(createdAt), 'MM-dd-yyyy')}`}</span>}
          </div>

          <div className="status-tag-container">
            {getStatusTag()}
          </div>
        </div>
        <div className="bottom-section">
          <h3 className="footnote">Reason</h3>
          <p className="body-1">{reason}</p>
          {additionalNote && (
          <>
            <h3 className="footnote additional-note">Additional Note</h3>
            <p className="body-1" data-testid="additional-note">
              {additionalNote}
            </p>
          </>
          )}
        </div>
      </div>
      <div className="right-section">
        <div className="heading">
          <h3 className="footnote">Care Team</h3>
          <EditButton clickHandler={() => openEditCareTeamLightBox({
            careAdvisors,
            assignedCareAdvisors: getAssignedAdvisors(assignedAdvisors),
            referrers: referrerAccounts,
            assignedReferrers: referral.referrers
              ? referral.referrers.map(({
                firstName, lastName, accountID,
              }) => ({ firstName, lastName, id: accountID }))
              : [],
            referrerIcon: src,
            careAdvisorIcon: Logo,
            updateCareTeam: async ({ selectedCareAdvisors, selectedReferrers }) => {
              await updateReferrers(getUpdatedReferrers(selectedReferrers), referralID);

              const [assigned, unassigned] = getChangedAdvisors(selectedCareAdvisors);
              if (assigned?.length) {
                const advisorValues = assigned.map(({ id }) => id);
                await assignAdvisors(advisorValues);
              }

              if (unassigned?.length) {
                const advisorValues = unassigned.map(({ id }) => id);
                await unassignAdvisors(advisorValues);
              }
            },
            closeLightBox,
          })}
          />
        </div>
        {referrers && referrers.map((referrer, index) => (
          <div className="referrer-section" key={`${referrer.referrerID}`}>
            <div className="pipeline-icon">
              <img src={src} alt="icon" />
            </div>
            <div className="referrer-info">
              <h2 className="full-name body-1-bold" data-testid={`referrer-${index}`}>
                {`${referrer.firstName} ${referrer.lastName}`}
              </h2>
              <a
                href={`tel:${referrer.phone}`}
                className="phone-link"
                data-testid={`tel-referrer-${index}`}
              >
                <p className="body-1">{formatPhoneInput(sanitizeNumber(referrer.phone))}</p>
              </a>
              <p className="body-1">{referrer.email}</p>
            </div>
          </div>
        ))}
        {assignedAdvisors && assignedAdvisors.map((advisor, index) => (
          <div className="referrer-section" key={`${advisor.value}`}>
            <div className="pipeline-icon">
              <img src={Logo} alt="icon" />
            </div>
            <div className="referrer-info">
              <h2 className="full-name body-1-bold" data-testid={`referrer-${index}`}>
                {advisor.label}
              </h2>
            </div>
          </div>
        ))}
        {priorityNeedOptions.length > 1 && (
        <div className="priority-need" data-testid="priority-need-section">
          <div className="priority-need-icon">
            {getPriorityNeedIcon()}
          </div>
          <div className="select-container">
            <FormSelect
              options={priorityNeedOptions}
              selectedOption={selectedPriorityNeed}
              onChangeHandler={selectPriorityNeed}
              placeholder="Select a priority need"
            />
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

ReferralDetailPanel.propTypes = {
  referral: PropTypes.shape({
    reason: PropTypes.string,
    additionalNote: PropTypes.string,
    pipelineType: PropTypes.string,
    referrers: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      }),
    ),
    createdAt: PropTypes.string,
    lastUpdated: PropTypes.string,
    referralID: PropTypes.number,
  }),
  assignedAdvisors: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  assignAdvisors: PropTypes.func.isRequired,
  unassignAdvisors: PropTypes.func.isRequired,
  urgencyType: PropTypes.string,
  isClosed: PropTypes.bool,
  isPending: PropTypes.bool,
  priorityNeedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  selectedPriorityNeed: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  updatePriorityNeed: PropTypes.func.isRequired,
  openEditCareTeamLightBox: PropTypes.func.isRequired,
  careAdvisors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  closeLightBox: PropTypes.func.isRequired,
  referrerAccounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  updateReferrers: PropTypes.func.isRequired,
};

ReferralDetailPanel.defaultProps = {
  referral: {},
  assignedAdvisors: [],
  urgencyType: null,
  isClosed: false,
  isPending: false,
  priorityNeedOptions: [],
  selectedPriorityNeed: null,
};

export default ReferralDetailPanel;
