import React from 'react';
import './LoadingSpinner.scss';

const LoadingSpinner = () => (
  <div className="loading-spinner">
    Loading...
  </div>
);

export default LoadingSpinner;
