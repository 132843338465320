import { connect } from 'react-redux';
import PipelineSiteTable from './PipelineSiteTable';
import {
  createPipeline,
  updatePipeline,
  createSite,
  updateSite,
} from '../../reducers/app';

const mapStateToProps = (state) => {
  const {
    appData: { pipelines, sites, allAccounts },
  } = state;
  const pipelinesWithSites = pipelines.map((pipeline) => {
    const pipelineSites = sites
      .filter((site) => site.pipeline_id === pipeline.id)
      .map((site) => {
        const siteAccounts = allAccounts.filter(({ siteID }) => siteID === site.id);
        return { ...site, numUsers: siteAccounts.length };
      });

    return {
      ...pipeline,
      sites: pipelineSites,
    };
  });
  return { pipelines: pipelinesWithSites };
};

const mapDispatchToProps = (dispatch) => ({
  createPipeline: (pipelineLabel) => dispatch(createPipeline(pipelineLabel)),
  updatePipeline: (pipelineID, pipelineLabel) => (
    dispatch(updatePipeline(pipelineID, pipelineLabel))),
  createSite: (pipelineID, siteLabel) => dispatch(createSite(pipelineID, siteLabel)),
  updateSite: (siteID, siteLabel) => dispatch(updateSite(siteID, siteLabel)),
});

const PipelineSiteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PipelineSiteTable);

export default PipelineSiteContainer;
