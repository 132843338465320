import { connect } from 'react-redux';
import ReferralRow from './ReferralRow';
import { updatePriorityNeed, assignAdvisors, archive } from '../../reducers/referrals';
import { openConfirmArchiveModal } from '../../reducers/lightbox';

const mapStateToProps = (state) => {
  const { appData: { statuses } } = state;
  return { statuses };
};

const mapDispatchToProps = (dispatch) => ({
  updatePriorityNeed: (referralStatus, referralID, priorityNeedID) => (
    dispatch(updatePriorityNeed(referralStatus, referralID, priorityNeedID))),
  assignAdvisors: (referralID, accountIDsToAssign) => (
    dispatch(assignAdvisors(referralID, accountIDsToAssign))),
  archiveReferral: (referralStatus, referralID) => dispatch(archive(referralStatus, referralID)),
  openConfirmArchiveModal: (props) => dispatch(openConfirmArchiveModal(props)),
});

const ReferralRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralRow);

export default ReferralRowContainer;
