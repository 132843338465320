/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import Navbar from '../Navbar';
import NewReferralWizard from '../../views/NewReferralWizard';
import CaseDrafts from '../../views/CaseDrafts';
import { getAppData, isLoggedIn } from '../../services/endpoints';
import { setUser } from '../../services/ErrorHandling';
import LightBoxService from '../../services/LightBoxService';
import './ParentPage.scss';

const { constants: { appTypes: { APP_GP } } } = common;

class ParentPage extends React.Component {
  componentDidMount = async () => {
    const { setAppData } = this.props;
    const authenticated = await isLoggedIn();
    if (authenticated) {
      const {
        accountID,
        governmentCategories,
        sdohCategories,
        pipelines,
        sites,
        statuses,
        subStatuses,
        relationshipTypes,
        urgencyTypes,
        applicationTypes,
        careAdvisors,
        sexTypes,
        accounts,
        referralSources,
        referrerAccounts,
        billingCodes,
        allAccounts,
      } = await getAppData();
      if (accountID) {
        setUser(accountID);
      }
      setAppData({
        accountID,
        governmentCategories,
        sdohCategories,
        pipelines,
        sites,
        statuses,
        subStatuses,
        relationshipTypes,
        urgencyTypes,
        applicationTypes,
        careAdvisors,
        sexTypes,
        accounts,
        referralSources,
        referrerAccounts,
        billingCodes,
        allAccounts,
      });
    }
  }

  render() {
    const endpoint = process.env.REACT_APP_ENDPOINT;
    const placesAPIKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
    const {
      children,
      newReferralWizardIsOpen,
      triggerDrafts,
      lightBox,
      lightBoxProps,
    } = this.props;
    return (
      <div className="parent-page-container">
        <div className="nav-container">
          <Navbar />
        </div>
        <div className="children-container">
          {children}
        </div>
        {newReferralWizardIsOpen && (
          <NewReferralWizard
            withReferrerForm
            endpoint={endpoint}
            placesAPIKey={placesAPIKey}
            appType={APP_GP}
          />
        )}
        {triggerDrafts && <CaseDrafts />}
        {lightBox !== null && LightBoxService(lightBox, lightBoxProps)}
      </div>
    );
  }
}

ParentPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)]).isRequired,
  newReferralWizardIsOpen: PropTypes.bool.isRequired,
  triggerDrafts: PropTypes.bool.isRequired,
  setAppData: PropTypes.func.isRequired,
  lightBox: PropTypes.string,
  lightBoxProps: PropTypes.shape({}),
};

ParentPage.defaultProps = {
  lightBox: null,
  lightBoxProps: {},
};

export default ParentPage;
