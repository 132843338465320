import React from 'react';
import PropTypes from 'prop-types';
import LightBox from '../Lightbox';
import './ConfirmArchiveModal.scss';

const ConfirmArchiveModal = ({ archiveReferral, close }) => {
  const confirmButtonHandler = async () => {
    await archiveReferral();
    close();
  };
  return (
    <LightBox
      isOpen
      closeButtonHandler={close}
    >
      <div className="confirm-archive-modal">
        <p className="message body-1-bold">Are you sure you want to archive this referral?</p>
        <div className="action-buttons">
          <button
            className="confirm-btn button-primary-small"
            type="button"
            onClick={confirmButtonHandler}
          >
            Confirm
          </button>
          <button
            className="cancel-btn button-secondary-small-1"
            type="button"
            onClick={() => close()}
          >
            Cancel
          </button>
        </div>
      </div>
    </LightBox>
  );
};

ConfirmArchiveModal.propTypes = {
  archiveReferral: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default ConfirmArchiveModal;
