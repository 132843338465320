import React, { useState } from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import EditLightbox from '../EditLightbox';
import './NoteLightBox.scss';

const { components: { Input, TextArea } } = common;

const NoteLightBox = ({
  noteID,
  noteLabel: noteLabelProp,
  noteText: noteTextProp,
  referralNeeds,
  relatedNeeds: relatedNeedsProp,
  addNote,
  editNote,
  closeLightBox,
}) => {
  const [noteLabel, setNoteLabel] = useState(noteLabelProp);
  const [noteText, setNoteText] = useState(noteTextProp);
  const [relatedNeeds, setRelatedNeeds] = useState(relatedNeedsProp);

  const changeHandler = (event, setterFunc) => {
    const { target: { value } } = event;
    setterFunc(value);
  };

  const isChecked = (needID) => relatedNeeds.includes(needID);

  const relatedNeedsChangeHandler = (needID) => {
    const checked = isChecked(needID);
    if (checked) {
      setRelatedNeeds(relatedNeeds.filter((relatedNeedID) => relatedNeedID !== needID));
    } else {
      setRelatedNeeds([
        ...relatedNeeds,
        needID,
      ]);
    }
  };

  const allNeedsSelected = () => referralNeeds.every((need) => relatedNeeds.includes(need.needID));

  const selectAllNeeds = () => {
    if (allNeedsSelected()) {
      setRelatedNeeds([]);
    } else {
      setRelatedNeeds(referralNeeds.map((need) => need.needID));
    }
  };

  const isConfirmDisabled = !(noteLabel && noteText && relatedNeeds.length);

  const confirmButtonHandler = async () => {
    if (isConfirmDisabled) return;
    if (noteID) {
      await editNote({
        noteID, noteLabel, noteText, needIDs: relatedNeeds,
      });
    } else {
      await addNote({ noteLabel, noteText, needIDs: relatedNeeds });
    }
    closeLightBox();
  };

  return (
    <EditLightbox
      isOpen
      headerLabel={noteID ? 'Edit Note' : 'New Note'}
      confirmButtonHandler={confirmButtonHandler}
      closeButtonHandler={closeLightBox}
      isConfirmDisabled={isConfirmDisabled}
    >
      <div className="note-light-box" data-testid="note-light-box">
        <div className="text-input-container">
          <Input
            dataTestID="note-label"
            type="text"
            value={noteLabel}
            label="Note Label"
            handleOnChange={(event) => changeHandler(event, setNoteLabel)}
          />
        </div>
        <div className="text-input-container">
          <TextArea
            className="note-text body-1"
            handleOnChange={(event) => changeHandler(event, setNoteText)}
            dataTestID="note-text"
            value={noteText}
            label="Note Text"
          />
        </div>
        <div className="related-needs">
          <span>Related Needs</span>
          <label
            htmlFor="all-needs"
            className="related-need-label text-link"
          >
            <input
              id="all-needs"
              data-testid="all-needs"
              className="related-need"
              type="checkbox"
              onChange={selectAllNeeds}
              checked={allNeedsSelected()}
            />
            All Needs
          </label>
          {referralNeeds.map((need) => (
            <label
              key={need.needID}
              htmlFor={`need-${need.needID}`}
              className="related-need-label text-link"
            >
              <input
                id={`need-${need.needID}`}
                data-testid={`need-${need.needID}`}
                className="related-need"
                type="checkbox"
                onChange={() => relatedNeedsChangeHandler(need.needID)}
                checked={isChecked(need.needID)}
              />
              {need.label}
            </label>
          ))}
        </div>
      </div>
    </EditLightbox>
  );
};

NoteLightBox.propTypes = {
  noteID: PropTypes.number,
  noteLabel: PropTypes.string,
  noteText: PropTypes.string,
  referralNeeds: PropTypes.arrayOf(
    PropTypes.shape({
      needID: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  relatedNeeds: PropTypes.arrayOf(
    PropTypes.number,
  ),
  addNote: PropTypes.func,
  editNote: PropTypes.func,
  closeLightBox: PropTypes.func.isRequired,
};

NoteLightBox.defaultProps = {
  noteID: null,
  noteLabel: '',
  noteText: '',
  relatedNeeds: [],
  addNote: () => {},
  editNote: () => {},
};

export default NoteLightBox;
