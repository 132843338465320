import { connect } from 'react-redux';
import AccountCreationForm from './AccountCreationForm';
import { closeLightBox, searchAdminData} from '../../reducers/lightbox';
import { createAccount, setAppDataAllAccounts } from '../../reducers/app';

const mapStateToProps = (state) => {
  const { appData: { sites, pipelines } } = state;
  return { sites, pipelines };
};

const mapDispatchToProps = (dispatch) => ({
  createAccount: (account) => dispatch(createAccount(account)),
  searchAdminData:(inputData) => dispatch(searchAdminData(inputData)),
  setAppDataAllAccounts: (data)=> dispatch(setAppDataAllAccounts(data)),
  closeLightBox: () => dispatch(closeLightBox()),
});

const AccountCreationFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountCreationForm);

export default AccountCreationFormContainer;
