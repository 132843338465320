import { connect } from 'react-redux';
import common from 'gateway-common';
import { closeNewReferralWizard, setDuplicateReferral } from '../../reducers/newReferral';

const mapStateToProps = (state) => {
  const {
    newReferral: { isOpen, patient, headOfCase, duplicateRefData },
    drafts: { draft },
    appData: {
      governmentCategories,
      sdohCategories,
      pipelines,
      sites,
      relationshipTypes,
      urgencyTypes,
      applicationTypes,
      sexTypes,
      referrerAccounts,
      referralSources,
    },
  } = state;
  return {
    isOpen,
    draft,
    governmentCategories,
    sdohCategories,
    relationshipTypes,
    urgencyTypes,
    applicationTypes,
    sexTypes,
    patient,
    headOfCase,
    duplicateRefData,
    appData: {
      pipelines,
      sites,
      referrerAccounts,
      referralSources,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeNewReferralWizard: () => dispatch(closeNewReferralWizard()),
  setDuplicateReferral: (data) => dispatch(setDuplicateReferral(data)),
});

const { components: { NewReferralWizard } } = common;

const NewReferralWizardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewReferralWizard);

export default NewReferralWizardContainer;
