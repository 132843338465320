import { connect } from 'react-redux';
import { closeLightBox } from '../../reducers/lightbox';
import { updateAccount } from '../../reducers/app';
import EditAccountModal from './EditAccountModal';

const mapStateToProps = (state) => {
  const { appData: { pipelines, sites } } = state;
  return { pipelines, sites };
};

const mapDispatchToProps = (dispatch) => ({
  updateAccount: (account) => dispatch(updateAccount(account)),
  closeLightBox: () => dispatch(closeLightBox()),
});

const EditAccountModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAccountModal);

export default EditAccountModalContainer;
