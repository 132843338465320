import React, { useState } from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import EditLightbox from '../EditLightbox';
import './AccountCreationForm.scss';
import { DEPARTMENT_LIST } from '../../constants';
import { formatPhoneInput } from '../../utils';

const { components: { Input, FormSelect } } = common;

const AccountCreationForm = ({
  isReferrer,
  pipelines,
  sites,
  createAccount,
  searchAdminData,
  closeLightBox,
  setAppDataAllAccounts
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [departmentValue,setDepartment]=useState('');
  const [phone, setPhone] = useState('');
  const [occupation, setOccupation] = useState('');
  const [siteID, setSiteID] = useState(null);
  const [pipelineID, setPipelineID] = useState(null);

  const pipelineOptions = pipelines.map(({ id, label }) => ({ value: id, label }));
  const selectedPipelineOption = pipelineOptions.find(({ value }) => value === pipelineID);

  const selectedDepartment= DEPARTMENT_LIST.find(({value})=>value ===departmentValue)
  const siteOptions = sites
    .filter((site) => site.pipeline_id === pipelineID)
    .map(({ id, label }) => ({ value: id, label }));
  const selectedSiteOption = siteOptions.find(({ value }) => value === siteID);

  const department=selectedDepartment?.label
  const confirmButtonHandler = async () => {
    const account = {
      firstName,
      lastName,
      email,
      phone,
      occupation,
      siteID,
      department,
      isReferrer,
    };
    await createAccount(account);
    await searchAdminData("");
    const response = await searchAdminData("");
    setAppDataAllAccounts(response)
    closeLightBox();
  };

  const isConfirmDisabled = isReferrer
    ? !(
      firstName
    && lastName
    && email
    && phone
    && departmentValue
    && occupation
    && siteID
    )
    : !(
      firstName
    && lastName
    && email
    && phone
    && !isReferrer
    );

  return (
    <EditLightbox
      headerLabel="New Account"
      isOpen
      confirmButtonHandler={confirmButtonHandler}
      closeButtonHandler={closeLightBox}
      isConfirmDisabled={isConfirmDisabled}
    >
      <div className="account-creation-form" id="account-creation">
        <div className="account-item">
          <Input
            value={firstName}
            handleOnChange={(event) => setFirstName(event.target.value)}
            label="First Name"
            type="text"
            placeholderText="First Name"
            dataTestID='first-name-input'
          />
        </div>
        <div className="account-item">
          <Input
            value={lastName}
            handleOnChange={(event) => setLastName(event.target.value)}
            label="Last Name"
            type="text"
            placeholderText="Last Name"
            dataTestID='last-name-input'
          />
        </div>
        <div className="account-item">
          <Input
            value={phone}
            handleOnChange={(event) => setPhone(formatPhoneInput(event.target.value))}
            label="Phone"
            type="text"
            placeholderText="Phone"
            dataTestID='phone-number-input'
          />
        </div>
        <div className="account-item">
          <Input
            value={email}
            handleOnChange={(event) => setEmail(event.target.value)}
            label="Email for Login"
            type="email"
            placeholderText="Email for Login"
            dataTestID='email-input'
          />
        </div>
        { isReferrer && (
        <>
          <div className="account-item">
            <Input
              value={occupation}
              handleOnChange={(event) => setOccupation(event.target.value)}
              label="Occupation"
              type="text"
              placeholderText="Occupation"
              dataTestID='occupation-input'
            />
          </div>
          <div className="account-item">
            <FormSelect
              options={pipelineOptions}
              label="Pipeline"
              selectedOption={selectedPipelineOption}
              onChangeHandler={({ value }) => setPipelineID(value)}
              dataTestID='pipeline-select'
            />
          </div>
          <div className="account-item">
            <FormSelect
              options={siteOptions}
              label="Site"
              isDisabled={!selectedPipelineOption}
              selectedOption={selectedSiteOption}
              onChangeHandler={({ value }) => setSiteID(value)}
              dataTestID='site-select'
            />
          </div>
          <div className="account-item">
            <FormSelect
              options={DEPARTMENT_LIST}
              label="Department"
              selectedOption={selectedDepartment}
              onChangeHandler={({ value }) => setDepartment(value)}
              dataTestID='department-select'
            />
          </div>
        </>
        )}
      </div>
    </EditLightbox>
  );
};

export default AccountCreationForm;

AccountCreationForm.propTypes = {
  isReferrer: PropTypes.bool.isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    description: PropTypes.string,
    pipeline_id: PropTypes.number,
  })).isRequired,
  pipelines: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
  createAccount: PropTypes.func.isRequired,
  closeLightBox: PropTypes.func.isRequired,
};
AccountCreationForm.defaultProps = {};