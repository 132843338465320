import { connect } from 'react-redux';
import common from 'gateway-common';
import { setFirstLogin, completePasswordChange } from '../../reducers/account';

const { components: { LoginStrategiesContainer } } = common;

const mapDispatchToProps = (dispatch) => ({
  setFirstLogin: () => dispatch(setFirstLogin()),
  completePasswordChange: () => dispatch(completePasswordChange()),
});

const LoginContainer = connect(
  null,
  mapDispatchToProps,
)(LoginStrategiesContainer);

export default LoginContainer;
