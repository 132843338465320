// constants
export const SET_REFERRER_INFO = 'SET_REFERRER_INFO';
export const EDIT_REFERRER_INFO = 'EDIT_REFERRER_INFO';
export const UPDATE_REFERRER_INFO = 'UPDATE_REFERRER_INFO';

// actions
export const setReferrerInfo = (referrerInfo) => ({
  type: SET_REFERRER_INFO,
  referrerInfo,
});

export const editReferrerInfo = (key, val) => ({
  type: EDIT_REFERRER_INFO,
  key,
  val,
});

export const updateReferrerInfo = (updatedAttributes) => ({
  type: UPDATE_REFERRER_INFO,
  updatedAttributes,
});

// action handlers
const ACTION_HANDLERS = {
  [SET_REFERRER_INFO]: (state, action) => ({
    ...state,
    referrerInfo: action.referrerInfo,
  }),
  [EDIT_REFERRER_INFO]: (state, action) => ({
    ...state,
    referrerInfo: {
      ...state.referrerInfo,
      [action.key]: action.val,
    },
  }),
  [UPDATE_REFERRER_INFO]: (state, action) => ({
    ...state,
    referrerInfo: {
      ...state.referrerInfo,
      ...action.updatedAttributes,
    },
  }),
};

const initialState = {
  referrerInfo: {},
};

export default function referrerReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
