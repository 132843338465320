import React, { useState } from 'react';
import PropTypes from 'prop-types';
import common from 'gateway-common';
import EditLightbox from '../EditLightbox';
import {
  formatPhoneInput,
  formatPhoneToNumsOnly,
  formatSSNInput,
} from '../../utils';
import { TOTAL_CHARS_FORMATTED_PHONE_INPUT } from '../../constants';
import './EditPersonalInfoLightbox.scss';

const {
  components: {
    Input,
    FormSelect,
    LanguageDropdown,
    AddressFields,
  },
} = common;

const EditPersonalInfoLightbox = ({
  personalInfo,
  referralID,
  sexOptions,
  closeLightBox,
  confirmButtonHandler,
  updateLanguage,
}) => {
  const [tempPersonalInfo, setTempPersonalInfo] = useState(personalInfo);
  const handleInputChange = (key, value) => {
    if(key === 'dob' && value.length <= 10) {
      setTempPersonalInfo((prevTempPersonalInfo) => ({
        ...prevTempPersonalInfo,
        [key]: value,
      }));
    } else if(key !== 'dob') {
      setTempPersonalInfo((prevTempPersonalInfo) => ({
        ...prevTempPersonalInfo,
        [key]: value,
      }));
    }
  };
  const handleConfirmation = () => {
    const phone = tempPersonalInfo.phone
      ? formatPhoneToNumsOnly(tempPersonalInfo.phone)
      : null;
    confirmButtonHandler({
      referralID,
      personID: personalInfo.personID,
      personalInfo: { ...tempPersonalInfo, phone },
    });
    updateLanguage({ language: tempPersonalInfo.language });
    closeLightBox();
  };
  const selectedSex = sexOptions.find((sex) => sex.value === tempPersonalInfo.sexID);
  return (
    <EditLightbox
      confirmButtonHandler={handleConfirmation}
      closeButtonHandler={closeLightBox}
      headerLabel="Personal Information"
      isOpen
    >
      <div className="personal-info-edit">
        <div className="edit-form">
          {/* Section 1 */}
          <div>
            <div>
              <Input
                type="text"
                value={tempPersonalInfo.firstName}
                label="First Name"
                handleOnChange={(e) => handleInputChange('firstName', e.target.value)}
                isRequiredField
              />
            </div>
            <div>
              <Input
                type="text"
                value={tempPersonalInfo.lastName}
                handleOnChange={(e) => handleInputChange('lastName', e.target.value)}
                label="Last Name"
                isRequiredField
              />
            </div>
            <div>
              <Input
                type="date"
                value={tempPersonalInfo.dob}
                handleOnChange={(e) => handleInputChange('dob', e.target.value)}
                label="Date of Birth"
              />
            </div>
            <div>
              <Input
                type="text"
                value={`${tempPersonalInfo.ssn ? formatSSNInput(tempPersonalInfo.ssn) : ''}`}
                handleOnChange={(e) => handleInputChange('ssn', e.target.value)}
                label="SSN"
              />
            </div>
            <div>
              <FormSelect
                options={sexOptions}
                label="Gender"
                selectedOption={selectedSex}
                onChangeHandler={(option) => handleInputChange('sexID', option.value)}
              />
            </div>
            <div>
              <LanguageDropdown
                changeHandler={handleInputChange}
                currentLanguage={tempPersonalInfo.language}
              />
            </div>
          </div>
          {/* Section 2 */}
          <div>
            <div>
              <Input
                type="text"
                value={`${tempPersonalInfo.phone ? formatPhoneInput(tempPersonalInfo.phone) : ''}`}
                handleOnChange={(e) => handleInputChange('phone', e.target.value)}
                label="Phone Number"
                maxLength={TOTAL_CHARS_FORMATTED_PHONE_INPUT}
              />
            </div>
            <div>
              <Input
                type="email"
                value={tempPersonalInfo.email}
                handleOnChange={(e) => handleInputChange('email', e.target.value)}
                label="Email"
              />
            </div>
            <div>
              <AddressFields
                placesAPIKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                changeHandler={handleInputChange}
                address={tempPersonalInfo.address}
                addressLine2={tempPersonalInfo.addressLine2}
                city={tempPersonalInfo.city}
                county={tempPersonalInfo.county}
                state={tempPersonalInfo.state}
                zip={tempPersonalInfo.zip}
              />
            </div>
          </div>
        </div>
      </div>
    </EditLightbox>
  );
};

EditPersonalInfoLightbox.propTypes = {
  personalInfo: PropTypes.shape({
    personID: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dob: PropTypes.string,
    gender: PropTypes.string,
    language: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    addressLine2: PropTypes.string,
    county: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    ssn: PropTypes.string,
  }),
  referralID: PropTypes.number.isRequired,
  sexOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  closeLightBox: PropTypes.func.isRequired,
  confirmButtonHandler: PropTypes.func.isRequired,
  updateLanguage: PropTypes.func.isRequired,
};

EditPersonalInfoLightbox.defaultProps = {
  personalInfo: {
    dob: '',
    gender: '',
    language: '',
    phone: '',
    email: '',
    address: '',
    addressLine2: '',
    city: '',
    county: '',
    state: '',
    country: '',
    zip: '',
    ssn: '',
  },
};

export default EditPersonalInfoLightbox;
